@use "sass:math";
@import "mixin";

.pg_top {
    .bl_mv {
        position: relative;

        .swiper{
            position: absolute;
            width: 100%;
            z-index: -1;
            &-wrapper .swiper-slide {
                @include mediaQ(PC) {
                    // top: -114px;
                    min-height: 453px;
                    height: 720px;
                    margin-top: $heightHeader;
                    background-repeat: no-repeat;
                    // background-position: right top;
                    background-position: center top;
                    // background-size: cover;
                    background-size: contain;
                    padding-top: 37.395833%;
                }

                @include mediaQ(SP) {
                    top: -60px;
                    height: 91vh;
                    // padding: math.div(65px, 375px)*100+npx guvw 0 7px;
                    // min-height: 485px;
                    margin-top: $heightHeaderSP;
                    background-repeat: no-repeat;
                    background-position: center top;
                    background-size: 100% auto;
                }
            }
        }



        &_inner {

            @include mediaQ(PC_O) {
                background-size: cover;
            }
            @include mediaQ(PC) {
                min-height: 386px;
                // height: 718px;
                // margin-top: $heightHeader;
                // background-image: url("/assets/img/top/shape_mv_pc.png");
                // background-repeat: no-repeat;
                // background-position: left top;
                // background-size: auto 100%;
                padding-top: 37.3958333%;
            }

            @include mediaQ2(950, 1300) {
                min-height: 450px;
            }

            @include mediaQ2(769, 1400) {
                background-position: 100% top;
            }

            @include mediaQ(SP) {
                // padding: math.div(65px, 375px)*100+npx guvw 0 7px;
                min-height: math.div(500px, 385px) * 100 + vw;
                margin-top: $heightHeaderSP;
                // background-image: url("/assets/img/top/shape_mv_sp.png");
                // background-repeat: no-repeat;
                // background-position: center bottom;
                // background-size: 100% auto;
            }
        }
        &_contents {
            display: inline-block;
            position: absolute;
            top: 34.3%;
            left: 3.4%;

            @include mediaQ(PC) {
                width: 41%;
                top: 29%;
            }

            @include mediaQ(SP) {
                top: 47.5%;
                left: 5%;
            }

            &_ttl {
                // margin-bottom: 41px;
                margin-bottom: per(40px, $widthContent);

                @include mediaQ(PC) {
                    width: 66%;
                    img {
                        width: 100%;
                    }
                }
                @include mediaQ(SP) {
                    margin-bottom: 14px;
                    width: 74%;
                    padding-left: 2px;
                }
            }

            &_txt {
                font-size: 18px;
                @include pcFS(18px, $widthContent, 16px);
                width: 89%;
                line-height: 1.8;
                @include mediaQ(SP) {
                    width: 92%;
                    font-size: 13px;
                }
            }
        }
    }

    .bl_calc {
        background-color: $blue;
        @include mediaQ(PC) {
            min-height: 191px;
        }

        &_inner {
            @include mediaQ(PC) {
                width: 100%;
                max-width: $maxWidthContent;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.7% 1%;

            }

            @include mediaQ2(852, 1047) {
                max-width: 63%;
            }
            @include mediaQ2(769, 851) {
                max-width: 60%;
            }

            @include mediaQ(SP) {
                width: per(335px, 375px);
                margin: 0 auto;
                padding: 3.8% 0;
            }
        }

        &_txtArea {
            @include mediaQ(PC) {
                width: 58%;
            }
            @include mediaQ(SP) {
                text-align: center;
            }
            &_date {
                background-color: $white;
                color: $black;
                @include pcFS(15px, $widthContent, 12px);
                font-weight: bold;
                padding: 5px 0;
                width: 277px;
                margin-bottom: 8px;
                text-align: center;
                letter-spacing: 0.04rem;

                span {
                    font-size: 25px;
                    @include pcFS(25px, $widthContent, 15px);
                    color: $blue;
                    @include mediaQ(SP) {
                        font-size: 18px;
                    }
                }

                @include mediaQ(SP) {
                    font-size: 12px;
                    margin: 0 auto 5px;
                    width: 206px;
                    padding: 1px 0 1px 0.6%;
                }
            }

            &_st {
                font-size: 32px;
                color: $white;
                line-height: 1.3;
                margin-bottom: 10px;
                @include mediaQ(SP) {
                    font-size: 24px;
                }
            }

            &_txt {
                font-size: 14px;
                color: $white;
                text-align: left;
                // margin-bottom: 13px;
            }
        }

        &_linkWrap {
            @include mediaQ(PC) {
                width: 39%;
            }
            &_link {
                position: relative;
                @include pcFS(22px, 1800px, 12px);
                color: $orange;
                border: 1px solid $orange;
                @include mediaQ(PC) {
                    @include btn_part02 (100%, math.div(90px, 1920px) * 100 + vw, $white, $orange);
                }

                @include mediaQ(SP) {
                    font-size: 18px;
                    @include btn_part02 (100%, math.div(55px, 375px) * 100 + vw, $white, $orange);
                }
                text-decoration: none;

                &::before {
                    height: 3vw;
                    left: -5%;
                    margin-right: 0%;
                    width: 11%;
                    @include mediaQ(SP) {
                        height: 9vw;
                        width: 10%;
                    }
                }

                &::after {
                    right: 5%;
                }
            }
        }

    }

    .bl_banner {
        background: $ft_gray ;
        .ly_inner {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 28px 0;
            @include mediaQ(SP) {
                flex-direction: column;
            }
            @include mediaQ(PC_1160) {
                padding: 28px 20px;
            }
        }
        &_item {
            display: block;
            @include mediaQ(PC) {
                width: 340px;
                &:nth-child(2) {
                    margin: 0 3.5%;
                }
            }
            @include mediaQ(SP) {
                &:not(:last-child) {
                    margin-bottom: 22px;
                }
            }
        }
    }

    // .bl_top_bg {
    //     position: relative;
    //     &::before {
    //     // &::after {
    //         content: "";
    //         display: block;
    //         position: absolute;
    //         left: 0;
    //         z-index: -1;
    //         width: 100%;
    //         height: 0;
    //     }
    //     &::before {
    //         @include mediaQ(PC) {
    //             $bgSize: 1920px;
    //             $top: -800px;
    //             top: $top;
    //             top: clamp($top, convVW($top, $maxWidthContent), 0px);
    //             padding-top: 523px;
    //             padding-top: clamp(0px, convVW(523px, $maxWidthContent), 523px);
    //             background: url("/assets/img/bg_top_bottom_pc.png") no-repeat center bottom;
    //             background-size: per($bgSize, $maxWidthContent) auto;
    //             background-size: clamp(751px, per($bgSize, $maxWidthContent), $bgSize) auto;
    //         }
    //         @include mediaQ(SP) {
    //             top: convVW(-300px, 375px);
    //             background: url("/assets/img/bg_top_bottom_sp.png") no-repeat center top / contain;
    //             padding-top: per(584px, 375px);
    //         }
    //     }
    //     // &::after {
    //     //     bottom: 0;
    //     //     background-color: #F2F7FA;
    //     //     @include mediaQ(PC) {
    //     //         height: calc(100% - 70px);
    //     //         height: calc(100% - clamp(0px, convVW(70px, $maxWidthContent), 70px));
    //     //     }
    //     //     @include mediaQ(SP) {
    //     //         height: calc(100% - clamp(0px, convVW(130px, 375px), 130px));
    //     //     }
    //     // }
    // }

    .bl_feature {
        @include mediaQ(PC) {
            width: 100%;
         max-width: $maxWidthContent;
            padding: per(65px, $widthContent) 20px 0;
            margin: 0 auto per(15px, $widthContent);
        }
        @include mediaQ(SP) {
            padding: 36px 5% 0;
            margin: 0 auto 20px;
        }

        &_ttl {
            text-align: center;
            font-weight: bold;
            font-size: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include mediaQ(PC) {
                margin-bottom: per(50px, $widthContent);
            }
            @include mediaQ(SP) {
                margin-bottom: 26px;
                font-size: 32px;
            }

            &_en {
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                @include pcFS(16px, $widthContent, 13px);
                color: $white;
                font-weight: 500;
                background-color: $hv_blue;
                border-radius: 15px;
                text-align: center;
                width: 8.5%;
                padding: 3px 0;
                margin-bottom: 10px;
                @include mediaQ(SP) {
                    width: 72px;
                    margin-bottom: 3px;
                }
            }
        }

        &_list {

            &_item {
                $rev : '.bl_feature_list_item__rev';
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include mediaQ(PC) {
                    margin-bottom: per(80px, $widthContent);
                }
                @include mediaQ(SP) {
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 50px;
                }

                &#{$rev} {
                    @include mediaQ(PC) {
                        flex-direction: row-reverse;
                    }
                }

                &_img {
                    @include mediaQ(PC) {
                        width: 50%;
                        flex-shrink: 0;
                    }
                    @include mediaQ(SP) {
                        margin-bottom: -3px;
                    }

                    &__second {
                        @include mediaQ(SP) {
                            order: 0;
                        }
                    }

                }

                &_txtWrap {
                    @include mediaQ(PC) {
                        padding-left: 4%;
                    }


                    &__second {
                        padding-right: 4%;
                        padding-left: 0;

                        @include mediaQ(SP) {
                            order: 1;
                            padding-right: 0;
                        }
                        .bl_feature_list_item_txtWrap_ttl {
                            @include mediaQ(PC) {
                                margin-top: -2%;
                                margin-bottom: -2%;
                            }
                            @include mediaQ(SP) {
                                margin-bottom: -11px;
                            }
                        }
                        .bl_feature_list_item_txtWrap_ttl_txt {
                            @include mediaQ(PC) {
                              margin-left: 3%;
                            }
                            @include mediaQ(SP) {
                                padding-left: 2%;
                            }
                        }
                    }

                    &__third {

                        .bl_feature_list_item_txtWrap_ttl {
                            @include mediaQ(PC) {
                                margin-top: -2%;
                                margin-bottom: -2%;
                            }
                            @include mediaQ(SP) {
                                margin-bottom: -3px;
                            }
                        }
                        .bl_feature_list_item_txtWrap_ttl_txt {
                            @include mediaQ(PC) {
                              margin-left: 2.5%;
                            }
                            @include mediaQ(SP) {
                                padding-left: 5%;
                              }
                        }
                    }

                    &_ttl {
                        @include pcFS(28px, $widthContent, 20px);
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        @include mediaQ(PC) {
                            margin-top: -8%;
                            margin-bottom: -2%;
                        }
                        @include mediaQ(SP) {
                            margin-bottom: -17px;
                        }


                        &_num {
                            font-family: 'Roboto', sans-serif;
                            @include pcFS(130px, $widthContent, 75px);
                            color: #D3EBFF;
                            @include mediaQ(SP) {
                                font-size: 106px;
                            }
                        }
                        &_txt {
                            $num01 : '.bl_feature_list_item_txtWrap_ttl_txt__01';
                            &#{$num01} {
                                @include mediaQ(SP) {
                                    margin-left: 2%;
                                }
                            }
                        }

                    }

                    &_txt {
                        font-size: 16px;
                        padding-left: 6px;
                        line-height: 2;
                        @include mediaQ(SP) {
                            padding-left: 0;
                        }
                    }
                }

            }
        }
    }

    .un_topPageBg {
        position: relative;
        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 0;
        }
        &::before {
            @include mediaQ(PC) {
                $bgSize: 1920px;
                $top: -670px;
                top: $top;
                top: clamp($top, convVW($top, $maxWidthContent), 0px);
                padding-top: 1060px;
                padding-top: clamp(0px, convVW(1060px, $maxWidthContent), 1060px);
                background: url("/assets/img/top/bg_topPage_bottom_pc.png") no-repeat center bottom;
                background-size: per($bgSize, $maxWidthContent) auto;
                background-size: clamp(751px, per($bgSize, $maxWidthContent), $bgSize) auto;
            }
            @include mediaQ(SP) {
                top: convVW(-815px, 375px);
                background: url("/assets/img/top/bg_topPage_bottom_sp.png") no-repeat center top / contain;
                padding-top: per(1050px, 375px);
            }
        }
        &::after {
            bottom: 0;
            background-color: #F2F7FA;
            @include mediaQ(PC) {
                height: calc(100% - 371px);
                height: calc(100% - clamp(0px, convVW(371px, $maxWidthContent), 371px));
            }
            @include mediaQ(SP) {
                height: calc(100% - convVW(234px, 375px));
            }
        }
    }

    .bl_scene {

        @include mediaQ(PC) {
            width: 100%;
         max-width: $maxWidthContent;
            padding: per(18px, $widthContent) 20px per(45px, $widthContent);
            margin: 0 auto;
        }
        @include mediaQ(SP) {
            padding: 0 5% 20px;
            margin: 0 auto 13px;
        }

        &_ttl {
            text-align: center;
            font-weight: bold;
            font-size: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include mediaQ(PC) {
                margin-bottom: per(30px, $widthContent);
            }
            @include mediaQ(SP) {
                margin-bottom: 21px;
            }

            &_en {
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                @include pcFS(16px, $widthContent, 13px);
                color: $white;
                font-weight: 500;
                background-color: $hv_blue;
                border-radius: 15px;
                text-align: center;
                width: 7.5%;
                padding: 3px 0;
                margin-bottom: 10px;
                @include mediaQ(SP) {
                    width: 59px;
                    padding: 3px 0;
                    margin-bottom: 0px;
                    line-height: 1;
                }
            }
        }

        &_list {
            display: flex;
            justify-content: space-between;
            @include mediaQ(SP) {
                display: block;
            }

            &_item {
                width: 31%;
                @include mediaQ(SP) {
                    width: 100%;
                    &:not(:last-child) {
                        margin-bottom: 31px;
                    }
                }

                &_ttl {

                    display: flex;
                    align-items: center;
                    @include mediaQ(PC) {
                        margin-bottom: per(50px, $widthContent);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 18px;
                    }

                    &_circle {
                        margin-right: 5%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        color: $white;
                        width: 65px;
                        height: 65px;
                        border-radius: 50%;
                        background: linear-gradient(180deg, $hv_blue 0%, $hv_blue 40%, $blue  40%, $blue  100%);

                        @include mediaQ(SP) {
                            width: 50px;
                            height: 50px;
                        }
                    }
                    &_case {
                        font-family: 'Roboto', sans-serif;
                        font-weight: bold;
                        font-size: 13px;
                        margin-bottom: -1px;
                        @include mediaQ(SP) {
                            font-size: 10px;
                        }
                    }

                    &_num {
                        font-weight: 500;
                        @include pcFS(30px, $widthContent, 26px);
                        @include mediaQ(SP) {
                            font-size: 22px;
                        }
                    }

                    &_txt {
                        font-weight: bold;
                        @include pcFS(30px, $widthContent, 26px);
                        @include mediaQ(SP) {
                            font-size: 28px;
                        }
                    }
                }

                &_img {
                    @include mediaQ(PC) {
                        margin-bottom: per(60px, $widthContent);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 21px;
                    }
                    img {
                        width: 100%;
                    }
                }

                &_plan {
                    @include mediaQ(PC) {
                        margin-bottom: per(35px, $widthContent);
                    }
                    @include mediaQ2(769, 1060) {
                        min-height: 59px;
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 5px;
                    }
                    &_list {
                        color: $white;
                        font-size: 14px;
                        font-weight: bold;
                        background-color: $blue;
                        border-radius: 20px;
                        display: inline-block;
                        padding: 5px 14px;

                        &:first-child {
                            margin-right: 2%;
                            margin-bottom: 2%;
                            @include mediaQ(SP) {
                                margin-right: 3%;
                            }
                        }
                    }
                }

                &_txt {
                    font-size: 16px;
                    line-height: 1.9;
                    letter-spacing: -0.01rem;
                    @include mediaQ(SP) {
                        line-height: 2;
                    }
                }
            }
        }
    }

    .bl_service {
        $service: '.bl_service';
        $china: '.bl_service__second';

        @include mediaQ(PC) {
            // height: 720px;
            // margin-top: $heightHeader;
            background-image: url("/assets/img/top/bg_service01_pc.jpg");
            background-repeat: no-repeat;
            background-position: center top;
            background-size: cover;
            @include pcPAD_P((100px 0 100px));

            &#{$china} {
                background-image: url("/assets/img/top/bg_service02_pc.jpg");
            }

        }

        @include mediaQ(SP) {
            padding: 50px 0 180px;
            // min-height: 485px;
            // margin-top: $heightHeaderSP;
            background-image: url("/assets/img/top/bg_service01_sp.jpg");
            background-repeat: no-repeat;
            background-position: center top;
            background-size: 100% 100%;
            &#{$china} {
                padding-bottom: 237px;
                background-image: url("/assets/img/top/bg_service02_sp.jpg");
            }
        }

        &_inner {
            @include mediaQ(PC) {
                width: 100%;
                max-width: $maxWidthContent;
                padding: 0 20px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            @include mediaQ(SP) {
                width: per(335px, 375px);
                margin: 0 auto;
            }
        }

        &_lead {
            @include mediaQ(PC) {
                width: 32%;
                min-height: 183px;
            }

            @include mediaQ2(769, 1000) {
                width: 39%;
            }
            @include mediaQ(SP) {
                text-align: center;
                margin: 0 auto 20px;
            }
            &_ttl {
                $leadTtl: #{$service}_lead_ttl__second;
                background-color: $hv_blue;
                color: $white;
                @include pcFS(18px, $widthContent, 14px);
                font-weight: bold;
                display: inline-block;
                padding: 8px 16px;
                margin-bottom: per(25px, $widthContent);
                @include mediaQ(SP) {
                    font-size: 16px;
                    padding: 7px 17px;
                    margin-bottom: 12px;
                }
                &#{$leadTtl} {
                    background-color: $blue;
                }


                &_num {
                    $leadNum: #{$service}_lead_ttl_num__second;
                    background-color: $white;
                    color: $hv_blue;
                    border-radius: 50%;
                    font-size: 15px;
                    display: inline-block;
                    text-align: center;
                    width: 20px;
                    margin-left: 6px;
                    @include mediaQ(SP) {
                        font-size: 12px;
                        width: 19px;
                        position: relative;
                        height: 19px;
                        top: -1px;
                    }

                    &#{$leadNum} {
                        color: $blue;
                    }
                }
            }

            &_type {
                font-weight: bold;
                font-size: 38px;
                margin-bottom: per(50px, $widthContent);

                @include mediaQ(SP) {
                   font-size: 36px;
                   margin-bottom: 11px;
                }
            }
            &_txt {
                font-size: 16px;
                line-height: 1.9;
                @include mediaQ(SP) {
                    text-align: left;
                }
            }
        }

        &_plan {
            $second : '.bl_service_plan_list__second';
            &_list {
                position: relative;
                background-color: $white;

                &:first-child .bl_service_plan_list_link {
                    margin-bottom: per(65px, $widthContent);
                }

                &_link {

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0% 7.5%;
                    transition: .3s;
                    @include mediaQ(PC) {
                        max-width: 600px;
                        min-height: 240px;
                    }

                    @include mediaQ2(769, 1400) {
                        padding: 0% 2%;
                    }
                    @include mediaQ(SP) {
                        min-height: 380px;
                        align-items: flex-start;
                        padding: 9.5% 7.5% 27%;

                        &__home {
                            padding-bottom: 14%;
                        }
                    }

                    &:hover {
                        opacity: 0.6;
                    }
                    &_icon {
                        $home: #{$service}_plan_list_link_icon__home;
                        $mobile: #{$service}_plan_list_link_icon__mobile;
                        width: 23%;
                        flex-shrink: 0;

                        &#{$home} {
                            width: 17%;
                            margin-left: 5.5%;
                            @include mediaQ(SP) {
                                width: 33%;
                                margin: 0 auto 26px;
                            }
                        }
                        &#{$mobile} {
                            width: 23.3%;
                            @include mediaQ(SP) {
                                width: 46.4%;
                                margin: 0 auto 23px;
                            }
                        }
                        img {
                            width: 100%;
                        }

                        @include mediaQ(SP) {
                            width: 51.6%;
                            margin-left: 25.5%;
                            margin-bottom: 22px;
                        }

                    }


                    &_wrap {
                        $home: #{$service}_plan_list_link_wrap__home;
                        $mobile: #{$service}_plan_list_link_wrap__mobile;
                        @include mediaQ(PC) {
                            margin-top: -10%;
                            width: 65%;
                            margin-left: 13%;
                           &#{$home} {
                                margin-top: -2%;
                            }
                            &#{$mobile} {
                                margin-left: 7.5%;
                                margin-top: -2%;
                                width: 65%;
                            }

                        }

                        @include mediaQ2(769, 1400) {
                            margin-left: 2%;
                        }
                        @include mediaQ(SP) {
                           text-align: center;
                        }
                        &_ttl {
                            $home: #{$service}_plan_list_link_wrap_ttl__home;
                            @include pcFS(32px, $widthContent, 26px);
                            font-weight: bold;
                            @include mediaQ(SP) {
                                font-size: 28px;
                                margin-bottom: 20px;

                                &#{$home} {
                                    margin-bottom: 24px;
                                }
                            }
                        }
                        &_txt {
                            // @include pcFS(14px, $widthContent, 14px);
                            font-size: 14px;
                            line-height: 1.9;
                            letter-spacing: 0.04rem;
                            @include mediaQ(SP) {
                                font-size: 16px;
                                text-align: left;
                            }
                        }


                    }

                    &_triangle {
                        padding-bottom: calc(3vw + 10px); /* 高さに合わせて調節してください。*/
                        overflow: hidden;

                        @include mediaQ(SP) {
                            padding-bottom: calc(16vw + 10px);
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            border-bottom: 58px solid #333;
                            border-left: 58px solid transparent;

                            @include mediaQ(SP) {
                                border-bottom: 16vw solid #333;
                                border-left: 16vw solid transparent;
                            }
                        }

                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: 12px;
                            right: 14px;
                            width: 11px;
                            height: 10px;
                            border-top: solid 2px $white;
                            border-right: solid 2px $white;
                            transform: translateY(calc(-50% + 1px)) rotate(45deg);
                            @include mediaQ(SP) {
                                bottom: 9px;
                            }
                        }
                    }
                }

                &_button {
                    position: absolute;
                    bottom: 10%;
                    left: 38.5%;
                    width: 200px;
                    padding-bottom: 35px;
                    padding-bottom: clamp(0px, 4.3859649123vw, 48px);

                    @include mediaQ2(769, 1400) {
                        left: 31.5%;
                    }
                    @include mediaQ(SP) {
                        bottom: 3%;
                        left: 50%;
                        height: 40px;
                        transform: translate(-50%, -50%);
                        padding-bottom:40px;
                        padding-bottom: clamp(0px, 4.3859649123vw, 40px);
                    }

                    &_link {
                        color: $white;
                        background: $blue;
                      border: solid 1px $blue;
                        font-size: 14px;

                        &::after {
                            right: 11px;
                            width: 8px;
                            height: 8px;
                            border-color: $white;
                        }
                        &:hover {
                            color: $blue;
                            background: $white;
                            &::after {
                                border-color: $blue;
                            }
                        }
                    }
                }
            }

            &_list#{$second} {
                .bl_service_plan_list_link_icon {
                    width: 10.5%;
                    margin-left: 9%;
                    @include mediaQ(SP) {
                        margin: auto;
                        width: 22%;
                        margin-bottom: 32px;

                    }
                }
                .bl_service_plan_list_link_wrap {
                    margin-left: 16%;
                    margin-top: -9%;

                    @include mediaQ2(769, 1400) {
                        margin-left: 4%;
                    }

                    @include mediaQ(SP) {
                        margin: 7% auto 0;
                    }
                    &_ttl {
                        margin-bottom: 5px;
                        @include mediaQ(SP) {
                            margin-bottom: 33px;
                        }
                    }
                }
            }
        }
    }

    .bl_qa {
        background-color: $_bg_blue ;
        padding-bottom: per(30px, $widthContent);
        @include mediaQ(SP) {
            padding-bottom: 16px;
        }
        &_inner {
            @include mediaQ(PC) {
                width: 100%;
                max-width: $maxWidthContent;
                padding: per(45px, $widthContent) 20px 0;
                margin: 0 auto per(15px, $widthContent);
            }
            @include mediaQ(SP) {
                padding: 36px 5% 0;
                margin: 0 auto 20px;
            }

        }

        &_ttl {
            text-align: center;
            font-weight: bold;
            font-size: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include mediaQ(PC) {
                margin-bottom: per(40px, $widthContent);
            }
            @include mediaQ(SP) {
                margin-bottom: 26px;
            }

            &_en {
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                @include pcFS(16px, $widthContent, 13px);
                color: $white;
                font-weight: 500;
                background-color: $hv_blue;
                border-radius: 15px;
                text-align: center;
                width: 9.5%;
                padding: 3px 0;
                margin-bottom: 10px;
                @include mediaQ(SP) {
                    width: 81px;
                    padding: 1px 0;
                    margin-bottom: 6px;
                }
            }
        }

        &_contents {
            margin: 24px 0 52px;

            @include mediaQ(SP) {
                margin: 24px 0 32px;
            }

            &_title {
                $two: '.bl_qa_contents_title__two';
                position:relative;
                padding: 19px 2% 19px 8.3%;
                // margin-top:10px;
                margin-bottom: 8px;
                @include pcFS(18px, $widthContent, 15px);
                font-weight: 500;
                background-color:$white;
                cursor:pointer;
                transition:0.7s ease;

                @include mediaQ(SP) {
                    padding: 14px 12% 10px 16%;
                    min-height: 70px;
                    margin-bottom: 6px;
                }

                &#{$two} {
                    @include mediaQ(SP) {
                        padding-bottom: 15px;
                    }

                }
                &:last-child {
                    margin-bottom: 0;
                }

                &_h4 {
                    $one: '.bl_qa_contents_title_h4__one';
                    position:relative;
                    // font-size:18px;
                    // font-weight:600;
                    letter-spacing: 0.02rem;
                    display:flex;
                    align-items:center;
                    line-height:1.4;

                    @include mediaQ(SP) {
                        line-height:1.8;
                    }

                    &#{$one} {
                        @include mediaQ(SP) {
                            top: 6px;
                        }

                        &::before {
                            @include mediaQ(SP) {
                                top: -17px;
                            }
                        }
                    }

                    &::before {
                        display:block;
                        position:absolute;
                        left: -1.7em;
                        content:"Q.";
                        color: $hv_blue;
                        font-family: 'Roboto', sans-serif;
                        @include pcFS(36px, $widthContent, 32px);
                        transition:0.7s ease;

                        @include mediaQ(SP) {
                            left:-1.4em;
                            top: -10px;
                        }

                    }
                }
            }

            &_title.is_open {
                .bl_qa_contents_icon_span {
                    background-color: $blue;
                    &:last-child{
                        right: -20px;
                    }
                }
            }

            &_icon {
                overflow:hidden;
                display:block;
                position:absolute;
                top:50%;
                right:22px;
                width:25px;
                height:25px;
                padding-top:11px;
                transform:translateY(-50%);

                @include mediaQ(SP) {
                    height: 22px;
                    // overflow: hidden;
                    padding-top: 11px;
                    right: 12px;
                    top: 48%;
                    width: 22px;
                }

                &_span {
                    display:block;
                    width:25px;
                    height:2px;
                    background-color:$blue;
                    transition:0.7s ease;

                    @include mediaQ(SP) {
                        width:22px;
                        height:1px;
                    }

                    &:last-child {
                        position:relative;
                        top:0;
                        right:auto;
                        transform:rotate(90deg);
                    }
                }
            }

            &_answer {
                display:none;
                padding: 20px 6% 20px 8%;
                background-color:$white;
                margin-bottom: 8px;

                @include mediaQ(SP) {
                    padding: 20px 10% 20px 16%;
                    margin-bottom: 6px;
                }

                &_txt {
                    position:relative;
                    @include pcFS(18px, $widthContent, 15px);
                    font-weight:500;
                    @include mediaQ(SP) {
                       line-height: 1.8;
                    }

                    &::before {
                        display:block;
                        position:absolute;
                        left:-1.7em;
                        top:-10px; content:"A.";
                        color: $orange;
                        font-family: 'Roboto', sans-serif;
                        @include pcFS(36px, $widthContent, 32px);
                        transition:0.7s ease;
                        @include mediaQ(SP) {
                            left:-1.3em;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &_button {
            max-width: 320px;
            padding-bottom: 60px;
            padding-bottom: clamp(0px, 4.3859649123vw, 60px);

            &_link {
                color: $white;
                background-color: $blue;
                @include mediaQ(PC) {
                    &:hover {
                        background-color: $_bg_blue;
                        color: $blue;

                        &::after {
                            border-color: $blue;
                        }
                    }
                }
                &::after {
                    border-color: $white;
                }
            }
            @include mediaQ(SP) {
                max-width: 333px;
                height: 60px;
            }

        }
    }

    .bl_topics {
        @include mediaQ(PC) {
            width: 100%;
         max-width: $maxWidthContent;
            padding: per(45px, $widthContent) 20px per(30px, $widthContent);
            margin: 0 auto per(15px, $widthContent);
        }
        @include mediaQ(SP) {
            padding: 43px 5% 20px;
            margin: 0 auto 20px;
        }

        &_ttl {
            text-align: center;
            font-weight: bold;
            font-size: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include mediaQ(PC) {
                margin-bottom: per(40px, $widthContent);
            }
            @include mediaQ(SP) {
                margin-bottom: 22px;
            }

            &_en {
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                @include pcFS(16px, $widthContent, 13px);
                color: $white;
                font-weight: 500;
                background-color: $hv_blue;
                border-radius: 15px;
                text-align: center;
                width: 8.5%;
                padding: 4px 0;
                margin-bottom: 10px;
                @include mediaQ(SP) {
                    width: 60px;
                    padding: 3px 0;
                    margin-bottom: 7px;
                    line-height: 1;
                }
            }
        }

        &_txt {
            font-size: 16px;
            text-align: center;
            line-height: 1.8;
            @include mediaQ(PC) {
                margin-bottom: per(25px, $widthContent);
            }
            @include mediaQ(SP) {
                margin-bottom: 20px;
            }
        }

        &_list {
            display: flex;
            justify-content: flex-start;

            @include mediaQ(PC) {
                margin-bottom: per(50px, $widthContent);
            }
            @include mediaQ(SP) {
                display: block;
                margin-bottom: 27px;
            }

            &_item {
                width: 31%;

                @include mediaQ(PC) {
               &:not(:nth-of-type(3n)) {
                  margin-right: 3.5%;
               }
            }
                @include mediaQ(SP) {
                    width: 100%;
                    &:not(:last-child) {
                        margin-bottom: 23px;
                    }
                }

                &_link {
                    // transition: .3s;
                    &:hover {
                        opacity: .7;
                    }
                }

                &_img {
                    @include mediaQ(PC) {
                        margin-bottom: per(40px, $widthContent);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 5px;
                    }
                    img {
                        width: 100%;
                        border: 1px solid #ccc;
                        min-height: 227px;
                        object-fit: cover;
                        max-height: 24vh;

                        @include mediaQ(SP) {
                            min-height: 222px;
                        }
                    }
                }

                &_date {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 4px;
                    display: block;
                    @include mediaQ(SP) {
                        font-size: 15px;
                        margin-bottom: 7px;
                    }
                }

                &_plan {

                    color: $white;
                    font-size: 12px;
                    font-weight: bold;
                    // background-color: $blue;
                    border-radius: 20px;
                    display: inline-block;
                    padding: 4px 14px;
                    line-height: 1;

                    @include mediaQ(PC) {
                        margin-bottom: per(35px, $widthContent);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 15px;
                        padding: 4px 12px;
                        font-size: 11px;
                    }

                    &.travel-china {
                        background-color: $hv_blue;
                    }
                    &.it-china {
                        background-color: $green;
                    }
                    &.joytel-service {
                        background-color: $orange;
                    }

                }

                &_txt {
                    font-size: 16px;
                    line-height: 1.6;
                    letter-spacing: -0.01rem;
                }
            }
        }

        &_button {
            max-width: 320px;
            padding-bottom: 60px;
            padding-bottom: clamp(0px, 4.3859649123vw, 60px);
            @include mediaQ(SP) {
                max-width: 333px;
                height: 60px;
            }

        }
    }

    .bl_news {
        background-color: $_bg_blue ;
        padding-bottom: per(30px, $widthContent);
        @include mediaQ(SP) {
            padding-bottom: 17px;
        }
        &_inner {
            @include mediaQ(PC) {
                width: 100%;
                max-width: $maxWidthContent;
                padding: per(45px, $widthContent) 20px 0;
                margin: 0 auto per(15px, $widthContent);
            }
            @include mediaQ(SP) {
                padding: 39px 5% 0;
                margin: 0 auto 20px;
            }

        }

        &_ttl {
            text-align: center;
            font-weight: bold;
            font-size: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include mediaQ(PC) {
                margin-bottom: per(45px, $widthContent);
            }
            @include mediaQ(SP) {
                margin-bottom: 14px;
            }

            &_en {
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                @include pcFS(16px, $widthContent, 13px);
                color: $white;
                font-weight: 500;
                background-color: $hv_blue;
                border-radius: 15px;
                text-align: center;
                width: 7.5%;
                padding: 5px 0;
                margin-bottom: 10px;
                @include mediaQ(SP) {
                    width: 55px;
                    padding: 1px 0;
                    margin-bottom: 6px;
                }
            }
        }

        &_contents {
            // margin: 24px 0 60px;
            @include mediaQ(PC) {
                margin-bottom: per(45px, $widthContent);
            }

            @include mediaQ(SP) {
                margin-bottom: 39px;
            }

           &_list {
               border-bottom: 1px solid #ccc;
               margin-bottom: 13px;

               &_date {
                   font-size: 16px;
                   font-weight: 600;
                   color: $blue;
                   margin-bottom: 9px;
               }

               &_ttl {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 17px;
                    line-height: 1.6;
                    display: block;
                    transition: .3s;
                    color: $black;
                    text-decoration: none;
                    @include mediaQ(SP) {
                        margin-bottom: 15px;
                    }

                    @include mediaQ(PC) {
                        &:hover {
                            color: $hv_blue;
                        }
                    }

               }
           }
        }

        &_button {
            max-width: 320px;
            padding-bottom: 60px;
            padding-bottom: clamp(0px, 4.3859649123vw, 60px);
            @include mediaQ(SP) {
                max-width: 333px;
                height: 60px;
            }
            &_link {
                color: $white;
                background-color: $blue;
                @include mediaQ(PC) {
                    &:hover {
                        color: $blue;
                        background-color: $_bg_blue;

                        &::after {
                            border-color: $blue;
                        }
                    }
                }

                &::after {
                    border-color: $white;
                }
            }

        }
    }

    .bl_contact {
        @include mediaQ(SP){
            padding: 48px 0 50px;
        }

        &_ttl {
            @include mediaQ(PC) {
                margin-bottom: per(37px, $widthContent);
            }
            margin-bottom: 20px;

            &_en {
                margin-bottom: 6px;
                 padding: 4px 17px;
                 @include mediaQ(SP) {
                    padding: 2px 12px;
                }
            }
        }

        &_links {

            &_item {
                &:not(:last-of-type) {
               @include mediaQ(SP) {
                  margin-bottom: 102px;
               }
            }
            }
            &_phone {
                color: $white;
                @include mediaQ(PC){
                    &:hover {
                        opacity: .6;
                    }
                }

                &_txt {
                    @include pcFS(21px, $widthContent, 16px);
                    font-weight: 500;
                    padding-left: 0.5%;
                    margin-top: -1%;
                    @include mediaQ(SP){
                        padding-left: 0;
                        text-align: center;
                        margin-bottom: 17px;
                        line-height: 1.6;
                    }
                }

                &_num {
                    font-family: 'Roboto', sans-serif;
                    font-weight: bold;
                    @include pcFS(50px, 1400px, 25px);
                    // position: relative;
                    padding-left: 0.8em;
                    letter-spacing: -0.04rem;
                    line-height: 1;
                    margin-top: 1%;
                    @include mediaQ(SP){
                        font-size: 41px;
                        padding-left: 0.3em;
                        text-align: center;
                        margin-top: 0;
                    }

                    &_span {
                        position: relative;
                        &::before {
                            content: "";
                            background: url("/assets/img/icon_phone.svg") no-repeat 0 0 / contain;
                            position: absolute;

                            @include mediaQ(PC){
                                bottom: -2px;
                                height: 80%;
                                left: -11%;
                                width: 9%;
                            }
                            @include mediaQ(SP){
                                width: 25px;
                                height: 39px;
                                left: -11%;
                                top: 6px;
                            }
                        }
                    }



                    &_time {
                        font-family: 'Noto Sans JP', sans-serif;
                        font-weight: 400;
                        @include pcFS(18px, $widthContent, 16px);
                        @include mediaQ(PC){
                           margin-left: -5px;
                         }
                        @include mediaQ(SP){
                           display: block;
                           margin-top: 5px;
                           letter-spacing: 0.01rem;
                        }
                    }
                }
            }
        }
    }

}
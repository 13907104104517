@use "sass:math";
@import "mixin";

.pg_shop {
    $body: &;
    &_bottomBg {
        @include mediaQ(PC) {
            @include pcPAD_P((0 0 80px 0));
        }
        @include mediaQ(SP) {
            padding-bottom: 40px;
        }
		&::before {
			@include mediaQ(PC) {
                $top: -365px;
				top: $top;
				top: clamp($top, convVW($top, $maxWidthContent), 0px);
			}
			@include mediaQ(SP) {
                top: convVW(-260px, 375px);
			}
		}
    }
    &_contents {
        .products {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-weight: bold;
            @include mediaQ(PC) {
                @include pcFS(16px);
                margin-bottom: per(60px, $widthContent);
            }
            @include mediaQ(SP) {
                font-size: 14px;
                margin-bottom: 40px;
            }
            &::after {
                @include mediaQ(PC) {
                    $w: 340px;
                    content: "";
                    display: block;
                    width: per($w, $widthContent);
                    max-width: $w;    
                }
            }
            .product {
                $pcW: 340px;
                $spW: 160px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include mediaQ(PC) {
                    width: per($pcW, $widthContent);
                    max-width: $pcW;
                }
                @include mediaQ(SP) {
                    width: per($spW, 335px);
                }
                &:not(:nth-last-of-type(-n + 3)) {
                    @include mediaQ(PC) {
                        margin-bottom: 60px;
                    }
                }
                &:not(:nth-last-of-type(-n + 2)) {
                    @include mediaQ(SP) {
                        margin-bottom: 30px;
                    }
                }
                .attachment-woocommerce_thumbnail {
                    width: 100%;
                    @include mediaQ(PC) {
                        margin-bottom: per(10px, $pcW);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 5px;
                    }
                }
                .woocommerce-loop-product__title {
                    font-weight: bold;
                    @include mediaQ(PC) {
                        margin-bottom: per(5px, $pcW);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 3px;
                    }
                }
                .price {
                    display: inline-block;
                    @include mediaQ(PC) {
                        margin-bottom: per(10px, $pcW);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 5px;
                    }
                }
                .pg_shop_toDetail {
                    width: 100%;
                    max-width: none;
                    a {
                        @include mediaQ(SP) {
                            font-size: 12px;
                            text-align: center;
                            padding: 0 per(40px, 160px);
                        }
                    }
                }
            }
        }
        .woocommerce-pagination {
            text-align: center;
            @include mediaQ(PC) {
                margin-bottom: per(80px, $widthContent);
            }
            @include mediaQ(SP) {
                margin-bottom: 40px;
            }
            .page-numbers {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                li {
                    font-weight: bold;
                    font-size: 16px;
                    &:not(:last-of-type) {
                        @include mediaQ(PC) {
                            margin-right: 15px;
                        }
                        @include mediaQ(SP) {
                            margin-right: conv(11px, 375px);
                        }
                    }
                    .page-numbers {
                        @include mediaQ(PC) {
                            width: 33px;
                            height: 29px;
                        }
                        @include mediaQ(SP) {
                            width: 31px;
                            height: 29px;
                        }
                        &.current {
                            color: $white;
                            background: #005598;
                        }
                        &.prev,
                        &.next{
                            overflow: hidden;
                            position: relative;
                            white-space: nowrap;
                            text-indent: 100%;
                            &::before,
                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                            }
                            &::before {
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: #B4B4B4;
                            }
                            &::after {
                                top: 50%;
                                left: 50%;
                                width: 8px;
                                height: 8px;
                                transform: translate(-50%, -50%) rotate(45deg);
                            }
                        }
                        &.prev {
                            &::after {
                                border-bottom: solid 1px $white;
                                border-left: solid 1px $white;
                            }
                        }
                        &.next {
                            &::after {
                                border-top: solid 1px $white;
                                border-right: solid 1px $white;
                            }
                        }
                    }
                }
            }
        }
    }
}

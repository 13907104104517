@use "sass:math";
@import "mixin";

.bl_form {
    margin-bottom: 80px;
    @include mediaQ(SP){
        margin-bottom: 40px;
    }


    &_box {
        font-size: 14px;
        line-height: 1.8;
        padding: 20px 25px;
        background: #E7EFF4;
        margin-bottom: 33px;
        @include mediaQ(SP){
            padding: 7px 14px;
            margin-bottom: 16px;
        }
    }
	
    // &_flow {
    //     display: flex;
    //     justify-content: space-between;
    //     margin: 43px auto 30px;
    //     width: 711px;
    //     position: relative;
    //     @include mediaQ(SP) {
    //         width: 100%;
    //         margin: 25px auto 18px;
    //     }

    //     &::before {
    //         content:'';
    //         display: inline-block;
    //         position: absolute;
    //         top: 44%;
    //         left: 5%;
    //         width: 85%;
    //         height: 2px;
    //         background:linear-gradient(90deg,$hv_blue  0%,$hv_blue  50%,#999 50%,#999 100%);
    //         z-index: -1;
    //         @include mediaQ(SP) {
    //             top: 33%;
    //         }
    //     }
    //     &_confirm::before {
    //         background:linear-gradient(90deg,#999 0%,#999 50%,$hv_blue  50%,$hv_blue  100%);

    //     }
       
    //     &_item {
    //         flex-shrink: 0;
    //         width: 176px;
    //         height: 64px;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         border-radius: 32px;
    //         position: relative;
    //         top: -2px;
    //         font-size: 20px;
    //         font-weight: bold;
    //         line-height: 1;
    //         color: #999;
    //         border: solid 2px #999;
    //         background: $white;
    //     }
    //     &_item.current {
    //         background-color: $hv_blue ;
    //         color: $white ;
    //         border-color: $hv_blue;
    //     }
       
    // }
    &_flow {
        display: flex;
        justify-content: space-between;
        margin: 43px auto 30px;
        width: 631px;
        position: relative;
        
        @include mediaQ(SP) {
            width: 100%;
            margin: 25px auto 30px;
        }

        &::before {
            content:'';
            display: inline-block;
            position: absolute;
            top: -22px;
            left: 5%;
            width: 91%;
            height: 2px;
            background:#999;
            z-index: -1;
            
            @include mediaQ(SP) {
                width: 90%;
            }
        }
        .mw_wp_form_confirm &::before {
            background:linear-gradient(90deg,$hv_blue  0%,$hv_blue  50%,#999 50%,#999 100%);

        }
        .mw_wp_form_complete &::before {
            background:$hv_blue;
        }

        &:after {
            background: $hv_blue;
            content: "";
            display: inline-block;
            height: 2px;
            left: 5%;
            position: absolute;
            top: -22px;
        }
    
        &.un_point3_02 {
            &:after {
                width: 45%;
            }
        }
        &.un_point3_03 {
            &:after {
                width: 91%;
                
                @include mediaQ(SP){
                    width: 88%;
                }
            }
        }
        
        &.un_point4_02 {
            &:after {
                width: 30%;
                
                @include mediaQ(SP){
                    left: 10%;
                }
            }
        }
        &.un_point4_03 {
            &:after {
                width: 65%;
                
                @include mediaQ(SP){
                    width: 60%;
                    left: 10%;
                }
            }
        }
        &.un_point4_04 {
            &:after {
                width: 94%;
    
                @include mediaQ(SP){
                    width: 86%;
                    left: 10%;
                }
            }
        }
        
        
        &_item {
            flex-shrink: 0;
            width: 50px;
            // height: 27px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            top: -2px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            color: #999;
            // border: solid 2px #999;
            // background: $white;

            &::before {
                content:'';
                display: inline-block;
                position: absolute;
                width: 27px;
                height: 27px;
                border-radius: 50%;
                background-color: #999;
                top: -34px;
                @include mediaQ(SP) {
                    width: 22px;
                    height: 22px;
                    top: -30px;
                }
                
            }
        }
        &_item.current {
            &::before {
                background-color: $hv_blue;
            }
            color: $hv_blue ;
        }
       
    }

    .mw_wp_form .error {
        font-size: 14px;
        margin-bottom: 12px;
        color: #FF6565;
        letter-spacing: 0.06rem;
    }

    &_wrap {
        background: $_bg_blue;
        padding: 25px 38px 35px;
        margin-bottom: 25px;
        @include mediaQ(SP) {
            padding: 25px 5% 20px;
            margin-bottom: 20px;
        }

        &__contact {
            padding: 35px 38px;
        }

        dl {

            display: flex;
            flex-wrap: wrap;
            width: 100%;
    
            @include mediaQ(SP) {
                display: block;
                padding-top: 0;
                padding-bottom: 0;
                width: 100%;
            }
    
            dt {
                width: 26.6%;
                font-size: 18px;
                font-weight: bold;
                // padding-top: 1.2%;
                padding-top: 2.2%;
                &.bl_form_item_name {
                    padding-top: 0.2%;
                }
                &.bl_form_item_mail {
                    padding-top: 1.2%;
                }
                &.bl_form_item_dt {
                    padding-top: 1.5%;
                }

                @include mediaQ(SP) {
                    display: block;
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 5px;
                }
    
                & .bl_form_item_reqruire {
                    display: inline-block;
                    margin-left: 6px;
                    padding: 5px 7px;
                    font-size: 10px;
                    font-weight: bold;
                    line-height: 1;
                    text-align: center;
                    border-radius: 2px;
                    border: 1px solid #FF6565;
                    color: #FF6565;
                    width: 37px;
                    @include mediaQ(PC) {
                        display: block;
                        margin-left: 0px;
                        margin-top: 8px;
                    }
                }
            }
        
            dd {
                display: table-cell;
                vertical-align: middle;
                font-size: 16px;
                width: 73.4%;
                margin-bottom: 46px;
    
                &:last-child {
                    margin-bottom: 0;
                }
                @include mediaQ(SP) {
                    display: block;
                    width: 100%;
                    padding-right: 10px;
                    // padding-bottom: 15px;
                    margin-bottom: 21px;
                }
                & span {
                    font-size: 14px;
                    // display: block;
                    line-height: 1.8;
                    &.bl_form_item_notice {
                        font-size:13px;
                        color: #767676;
                    }

                    &.bl_form_item_notice.bl_form_item_notice__contact {
                        font-size:14px;
                        color: $black;
                        // display: block;
                    }
                       
                }

                select, textarea {
                    width:100%;
                    height:50px;
                    font-family: 'Noto Sans JP', sans-serif;
                    font-size: 16px;
                    line-height:1.42857143;
                    padding:6px 12px;
                    margin:0.2em;
                    // border: 0;
                    border-color: #ccc;
                    background-color:$white;
                }
                select {
                    border:1px solid #ccc;
                }
                textarea {
                    height: 164px;
                }
                input[type="text"], input[type="email"] {
                    width:100%;
                    height:50px;
                    line-height:1.42857143;
                    padding:6px 12px;
                    margin:0.2em;
                    font-family: 'Noto Sans JP', sans-serif;
                    font-size: 16px;
                    border: 1px solid#ccc;
                }
                input[name="form1_captcha"], input[name="mw-wp-form-captcha"]{
                    max-width: 327px;
                    @include mediaQ(SP) {
                        max-width: 100%;
                    }
                }
                input[type="file"]{
                   display: none;
                }
       
                .inp {
    
                    &_327 {
                        max-width: 327px;
                        @include mediaQ(SP) {
                            max-width: 100%;
                        }
                    }
                    &_742 {
                        max-width: 742px;
                        @include mediaQ(SP) {
                            max-width: 100%;
                        }
                    }
                }

                .bl_form_item {
                    &_checkKind {
                        font-size: 16px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        margin-bottom: 5px;
                        &::before {
                            border-top: 2px solid #005598;
                            content: "";
                            margin-right: 0.6em;
                            width: 0.6em;
                        }
                    }
                    &_checkWrap {
                        // display: flex;
                        display: block;
                        .mwform-checkbox-field {
                            display: inline-block;
                            margin-top: 0;
                        }
                        .vertical-item + .vertical-item {
                            margin-left: 5px;
                        }
                        @include mediaQ(SP) {
                            // flex-direction: column;
                            // display: block;
                            padding-left: 1.2em;
                            .mwform-checkbox-field {
                                display: block;
                            }
                            .vertical-item + .vertical-item {
                                margin-left: 0px;
                                margin-top: 5px;
                            }
                            .horizontal-item + .horizontal-item  {
                                margin-left: 0;
                            }
                        
                        }
                    }
                    &_radioWrap {
                        // display: flex;
                        display: block;
                        .mwform-checkbox-field {
                            display: inline-block;
                            margin-top: 0;
                        }
                        @include mediaQ(SP) {
                            padding-left: 1.2em;
                            .mwform-checkbox-field {
                                display: block;
                            }
                            .vertical-item + .vertical-item {
                                margin-left: 0px;
                                margin-top: 5px;
                            }
                            .horizontal-item + .horizontal-item  {
                                margin-left: 0;
                            }
                        
                        }
                    }
                }
                .bl_form_file_label {
                    padding-left: 1em;
                    position: relative;
                }
        
            }
        }   
    }    

    &_textBox {
        
        margin-bottom: 40px;
        @include mediaQ(SP) {
            margin-bottom: 20px;
        }
        & p {
            line-height: 1.8;
            font-size: 16px;
            text-align: center;
            letter-spacing: 0.05rem;
            @include mediaQ(SP) {
                font-size: 13px;
                text-align: left;
            }
        }
        & a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    &_flex {
        display: flex;
        align-items:flex-start;
        @include mediaQ(SP) {
            flex-direction: column-reverse;
            max-width: 530px;
            margin: auto;
        }

    }
    
    & a.btn_linkBtn  {
        color: $white;
        text-decoration: none;
        &:hover {
            color: $blue;
        }
    }

    .btn_linkBtnWrap  {
        min-width: 250px;
        max-width: 530px;
        padding-bottom: 85px;
		padding-bottom: clamp(0px, convVW(60px, $maxWidthContent), 85px);

        @include mediaQ(SP) {
           height: 80px;
           width: 100%;

        }

        .btn_linkBtn {
            font-size:24px;
            @include pcFS(24px);
            @include mediaQ(SP) {
                font-size:20px;
             }
        }

        &__freeApply {
            min-width: 325px;
            max-width: 520px;
            padding-bottom: 120px;
            padding-bottom: clamp(0px, convVW(120px, $maxWidthContent), 120px);
            @include mediaQ(PC) {
                margin-top: -20px;
            }

            @include mediaQ(SP) {
                height: 110px;
                width: 100%;
            }

            & .btn_linkBtn {
                flex-direction: column;
                font-size:28px;
                padding-bottom: 30px;
                @include pcFS(28px);
                @include mediaQ(SP) {
                    font-size:22px;
                }
                &:hover {
                    .btn_linkBtnWrap__freeApply_bubble {
                        border-color: $blue;
                    }
                }
            }

            &_bubble {
                padding: 3px 15px;
                margin-top: 11px;
                margin-bottom: 3px;
                font-weight: 500;
                font-size:14px;
                @include pcFS(14px);
                border : 1px solid $white;
                @include mediaQ(SP) {
                    font-size:11px;
                }
            }
        }
        

    }

    .btn_linkBtn__file {
        display: block;
        width: 170px;
        font-size:14px;
        font-weight: bold;
        color: $blue;
        border: 1px solid $blue;
        background-color: $white;
        border-radius: 4px;
        padding: 12px 25px;
        text-align: center;
        @include mediaQ(SP) {
            height: 41.5px;
            width: 164.25px;
            padding: 9px 21px;
        }

        &:hover {
            color: $white;
            background-color: $blue;
        }
    }
    .mwform-file-delete {
        left: -11px;
        position: absolute;
    }

    // リンク風にする
    button.link-style-btn{
        cursor: pointer;
        border: none;
        background: none;
        font-size: 16px;
        text-decoration: underline;
        position: relative;
        padding-left: 18px;

        &::before{

              display: block;
              content: "";
              position: absolute;
              top: 50%;
              left: 4px;
              width: 7px;
              height: 7px;
              margin: -3px 0 0 0;
              border-top: solid 1px $black;
              border-right: solid 1px $black;
              transform: rotate(225deg);
            }
            
    
        &:hover {
            color: $hv_blue;
        }
    }

    //完了画面
    &_complete {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
        @include mediaQ(SP) {
            font-size: 22px;
            margin-bottom: 10px;
        }
    }
}
.pg_freeApply .mw_wp_form.mw_wp_form_input .bl_form, .pg_freeApply .mw_wp_form.mw_wp_form_confirm .bl_form{
    margin-bottom: 130px;
    @include mediaQ(SP){
        margin-bottom: 80px;
    }
}



// 入力画面時は確認用コンテンツ非表示
.mw_wp_form.mw_wp_form_input {

    .confirm_contents {
        display: none;
    }
 } 

 // お問い合わせ　入力画面時は確認用コンテンツ非表示
 .pg_contact .mw_wp_form.mw_wp_form_input {
    dd.bl_form_item_dd_content__contact {
        margin-bottom: 10px;
    }

 } 

 // 確認画面時は入力画面コンテンツ非表示
 .mw_wp_form.mw_wp_form_confirm {

    .bl_form {
        @include mediaQ(SP) {
            margin-bottom: 30px;
        }
        &_flow {
            margin-top: 90px;
            margin-bottom: 40px;
            @include mediaQ(SP) {
                margin-top: 80px;
                margin-bottom: 30px;
            }
            p:nth-child(2) {
                &::before {
                    background-color: $hv_blue;
                }
                color: $hv_blue ;
            }
        }
        &_wrap {
            padding-top: 18px;
            margin-bottom: 50px;
            @include mediaQ(SP) {
                margin-bottom: 30px;
            }

            &__contact {
                padding-top: 35px;
            }
        }

        .bl_form_flex .btn_linkBtnWrap {
            @include mediaQ(SP) {
                margin-bottom: 35px;
            }
        }

    }
   

    dt {
        padding-top: 2.2%!important;
    }
    .bl_form_wrap__contact dt {
        padding-top: 0.2%!important;
    }

    .bl_form_item_mail_dd {
        padding-top: 2.2%;
    }

    .bl_form_file_label {
        padding-left: 0;
    }
    dd {
        margin-bottom: 32px;
        @include mediaQ(PC) {
            min-height: 40px;
        }
        
        // &:nth-of-type(5) {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    // dd span {
    .input_contents {
        display: none;
    }
    .confirm_contents {
        display: block;
    }
    .confirm_contents.bl_form_flex {
        display: flex;
    }

    .bl_form_item.bl_form_item__brdn span.input_contents + br  {
        display: none;
    }

    .bl_form_item.bl_form_item__brdn .bl_form_item_mail_dd {
        padding-top: 0;
    }
 }

 // お問い合わせ　確認画面時は入力画面コンテンツ非表示
 .pg_contact .mw_wp_form.mw_wp_form_confirm {
    @include mediaQ(PC) {

        dt.bl_form_item_name__contact {
            height: 90px;
        }
        dt.bl_form_item_address__contact {
                height: 74px;
        }
        dt.bl_form_item_tel__contact {
            height: 70px;
    }
        dt.bl_form_item_mail__contact {
                height: 90px;
        }

        dd.bl_form_item_dd_address__contact, dd.bl_form_item_tel_dd, dd.bl_form_item_mail_dd, dd.bl_form_item_company_dd, .bl_form_file_label {
            margin-top: -24px;
        }
    }
 }
 // 確認画面時は入力画面コンテンツ非表示
 .mw_wp_form.mw_wp_form_complete {

    .bl_form {
        &_flow {
            margin-top: 90px;
            margin-bottom: 43px;
            @include mediaQ(SP) {
                margin-top: 80px;
                margin-bottom: 30px;
            }
        }
        
    }

    .el_linkBtnWrap  {
        min-width: 250px;
        max-width: 315px;
        padding-bottom: 60px;
		padding-bottom: clamp(0px, convVW(60px, $maxWidthContent), 60px);

        @include mediaQ(SP) {
           height: 60px;

        }

        .el_linkBtn {
            font-size:18px;
            background: $white;
            color: $blue;
            &::after {
                border-right: solid 2px $blue;
                border-top: solid 2px $blue;
            }

            @include mediaQ(PC) {
                &:hover {
                    background: $blue;
                    color: $white;

                    &::after {
                        border-right: solid 2px $white;
                        border-top: solid 2px $white;
                    }
                }
            }
            @include pcFS(18px);
            @include mediaQ(SP) {
                font-size:18px;
             }
        }        

    }
 } 

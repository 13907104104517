@use "sass:math";
@import "mixin";

.pg_applyPlan.pg_applyPlan__confirm.pg_under {
	
	.pg_cart {
		.bl_flow {
			.bl_form_flow {
				margin: 75px auto 40px;
				width: 777px;
				
				@include mediaQ(SP) {
					margin: 60px auto 30px;
					width: 100%;
				}
				
				&:before {
					@include mediaQ(SP) {
						left: 10%;
						width: 88%;
					}
				}
				
				&_item {
					font-size: 14px;
					
					&.un_flow_item_01 {
						width: percentage(math.div(80, 777));
						
						@include mediaQ(SP) {
							width: percentage(math.div(70, 330));
						}
					}
					&.un_flow_item_02 {
						width: percentage(math.div(96, 777));
						
						@include mediaQ(SP) {
							width: percentage(math.div(56, 330));
						}
					}
					&.un_flow_item_03 {
						width: percentage(math.div(112, 777));
						
						@include mediaQ(SP) {
							width: percentage(math.div(56, 330));
						}
					}
					&.un_flow_item_04 {
						width: percentage(math.div(32, 777));
						
						@include mediaQ(SP) {
							width: percentage(math.div(28, 330));
						}
					}
				}
			}
		}
		
		.product-remove {
			a.remove {
				font-size: 12px;
				color: #222222 !important;
				text-decoration: underline;
				width: auto;
				
				&:hover {
					text-decoration: none;
					background: none;
				}
			}
		}
		
		.cart-collaterals {
			.shop_table_responsive {
				display: none;
			}
		}
		
		.woocommerce {
			
			.cart_wrap {
				@include mediaQ(SP) {
					overflow-x: auto;
				}
			}
			
			.cart {
				margin-bottom: 30px;
				
				@include mediaQ(SP) {
					width: 500px;
				}

				.product-remove {
					font-weight: normal;

					&:hover {
						background: none;
					}
				}
				
				.product-price, .product-quantity, .product-subtotal {
					font-weight: normal;
					
					@include mediaQ(SP) {
						padding: 9px 12px;
					}
				}
				
				.product-subtotal {
					@include mediaQ(SP) {
						border-right: solid 1px #CCC;
					}
				}
				
				thead {
					@include mediaQ(SP) {
						display: table-header-group;
					}
				}
				
				th {
					text-align: center;
					background: #f2f7fa;
					border-right: solid 1px #CCC;
					font-weight: normal;
					padding: 12px;
					font-size: 14px;
					color: #222222;
					
					&:last-child {
						border-right: 0;
					}
					
					@include mediaQ(SP) {
						text-align: left;
					}
				}
				
				.product-name-txt {
					font-size: 16px;
					font-weight: bold;
					line-height: 2;
					padding-bottom: 5px;
				}
				
				.variation {
					border-top: dashed 1px #ccc;
				}
				
				.woocommerce-cart-form__cart-item {
					.product-name {
						padding: 6px 18px;
						border-right: solid 1px #CCC;
						
						.variation {
							.variation- {
								table {
									width: 100%;
								}
								tr:first-of-type {
									td {
										padding-top: 13px;
									}
								}
								td {
									font-size: 14px;
									color: #222222;
									border: none;
									padding-top: 4px;
									padding-bottom: 6px;
									
									&:first-child {
										width: 50% !important;
									}
									
									&:last-child {
										width: 50% !important;
									}
									
									strong,
									span {
										font-weight: normal !important;
										color: #222222 !important;
									}
								}

								p {
									font-size: 14px;
									color: #222222;
									border: none;
									font-weight: normal;
									padding-left: 35px;
									padding-top: 10px;
									
									@include mediaQ(SP) {
										padding-left: 0;
										padding-top: 5px;
									}
								}
							}
						}
					}
					
					.product-price,
					.product-quantity {
						border-right: solid 1px #CCC;
					}
				}
				
				.order-total {
					display: none;
				}
				
				.variation {
					background-color: #fff;
					
					.dopbsp-wc-cart {
						border-top: none;
					}
					
					.variation- {
						table {
							width: 100%;
						}
						td {
							font-size: 14px;
							color: #222222;
							border: none;
							font-weight: normal;
							
							&:first-child {
								width: 50% !important;
							}
							
							&:last-child {
								width: 50% !important;
							}
							
							strong,
							span {
								font-weight: normal !important;
								color: #222222 !important;
							}
						}
					}
				}
				
				
				.actions {
					.coupon {
						padding: 11px 18px;

						@include mediaQ(SP) {
							float: left;
						}

						& + button {
							margin: 10px 10px 10px;
							font-size: 12px;
							color: #005598;
							font-weight: bold;
							background-color: #fff;
							border: solid 1px #005598;
							padding: 11px 43px;
							background: url("../img/icon_reload.svg") no-repeat 93% center;
							background-size: 12px 12px;
							
							@include mediaQ(SP) {
								float: right;
								width: auto;
							}
						}

						#coupon_code {
							width: 192px;
							line-height: 20px;
							margin: 0 10px 0 0;
							padding: 6px 11px 5px;
							color: #999999;
							font-size: 14px;
							
							& + button {
								font-size: 12px;
								padding: 11px 32px;
								color: #005598;
								font-weight: bold;
								background-color: #fff;
								border: solid 1px #005598;
								
								@include mediaQ(SP) {
									float: left;
									width: auto;
								}
							}
						}
					}
				}
				
				
			}
			
		}

		.un_cart_total_wrap {
			display: flex;
			justify-content: flex-end;
			
			.un_cart_total {
				display: flex;
				justify-content: space-between;
				width: 570px;
				border-bottom: solid 1px #CCC;
				padding-bottom: 8px;
				
				@include mediaQ(SP) {
					padding-bottom: 3px;
				}
				
				.un_cart_total_title {
					font-size: 16px;
					line-height: 2;
				}
				.un_cart_total_body {
					font-size: 20px;
					font-weight: bold;
					line-height: 1.6;
				}
			}
		}
		
		.cart_totals {
			@include mediaQ(PC) {
				width: 567px;
			}

			@include mediaQ(SP) {
				width: 100%;
			}
		}
		
		.wc-proceed-to-checkout {
			width: 380px;
			margin: 0 auto;
			display: block;
			font-size: 20px;
			font-weight: bold;
			position: relative;
			line-height: 2.75;
			margin-top: 30px;
			max-width: 100%;
			
			@include mediaQ(PC) {
				margin-right: 0;
				width: 366px;
			}
			
			@include mediaQ(SP) {
				width: 100%;
				margin-top: 20px;
				margin-bottom: 20px;
				line-height: 2.25;
				font-size: 20px;
			}
			
			a {
				font-size: 20px;
				padding: 35px 30px;
			}
		}

	}
}

@use "sass:math";

@mixin bl_ttl_lv2__02(){
    font-weight: bold; position: relative;
    @include mediaQ(PC) {
        font-size: 26px; font-size: clamp(1rem, 2.2807017544vw, 26px); padding-left: 46px;
    }
    @include mediaQ(SP) {
        font-size: 22px; padding: 0 0 2px 30px;
    }
    
    &::before, &::after {
        content: "";display: block;left: 0;position: absolute;top: 50%;top: calc(50% + 1px);
        @include mediaQ(PC) {
            width: 28px;
        }
        @include mediaQ(SP) {
            width: 20px;
        }
    }
    
    &::before {
        -webkit-transform: translateY(-50%);background: #005598;transform: translateY(-50%);
        @include mediaQ(PC) {
            padding-bottom: 28px;
        }
        @include mediaQ(SP) {
            height: 20px;
        }
    }
    
    &::after {
        background: #2D8EDB;top: calc(50% - 13px);
        @include mediaQ(PC) {
            padding-bottom: 14px;
        }
        @include mediaQ(SP) {
            height: 10px;
        }
    }
}

@mixin my_form_css(){
    input[type=number], input[type=text], input[type=tel], input[type=email], input[type="password"] {
        border-radius: 0.25rem;display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: 0.375rem 0.8rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    
    textarea {
        border-radius: 0.25rem;display: block;width: 100%;padding: 0.375rem 0.8rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}

.pg_mypage {
    .woocommerce {
    
        .bl_ttl_lv2 {
            @include bl_ttl_lv2__02;
        }
        
        .woocommerce-form-login {

            .woocommerce-form-login__rememberme {
                display: block;
            }
        
            .woocommerce-form-login__submit {
                margin-top: 18px;
            }
        }
        
        form {
            &.login {
                margin-top: 14px;
                padding: 19px 20px 29px 22px;
                border: none;
                border-radius: unset;
                background: #f8f8f8;
            
                @include mediaQ(SP) {
                    margin-bottom: 43px;
                }
            }
        
            &.register {
                margin-top: 14px;
                padding: 19px 20px 7px 18px;
                border-radius: unset;
                border: solid 1px #CCCCCC;
                background: #fff;
            
                @include mediaQ(SP) {
                    margin-bottom: 43px;
                }
            }
        
            .form-row {
                margin: 0 0 15px;
            
                & label {
                    font-size: 16px;
                    margin-bottom: 4px;
                }
            
                & input.input-text {
                    height: 50px;
                    border: solid 1px #A3A3A3;
                }
                
                .woocommerce-form-login__rememberme {
                    margin-top: -4px;
                    
                    .woocommerce-form__input-checkbox {
                        margin-right: 5px;
                    }
                }
            
            }
        
            .password-input {
                margin-bottom: -6px;
            }
        }
    
        button.button {
            font-size: 16px;
            padding: 12px 40px;
            background: $blue;
            color: #fff;
            transition: 0.3s;
            border: solid 1px #005598;
    
            &:hover {
                background-color: #ffffff;
                color: #005598;
            }
        }
        
    }

    & .woocommerce-LostPassword.lost_password a {
        font-size: 14px;
        color: #222222;
        text-decoration: underline;
    
        &:hover {
            text-decoration: none;
        }
    }
    
    & .woocommerce-privacy-policy-link {
        font-size: 14px;
        text-decoration: solid;
        color: #222222;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    & .woocommerce-privacy-policy-text {
        font-size: 14px;
        line-height: 1.7;
        margin-top: 20px;
        margin-bottom: 13px;

        @include mediaQ(SP) {
            margin-bottom: 16px;
        }
    }
    
    .woocommerce form .show-password-input,
    .woocommerce-page form .show-password-input {
        right: 14px;
        top: 56%;
        transform: translate(0, -50%);
        
        &::after {
            display: inline-block;
            width: 24px;
            height: 24px;
            content: "";
            background: url("/assets/img/icon_eye.svg");
            background-size: 24px 24px;
        }
    }

}

.pg_mypage_login {
    & .woocommerce {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @include mediaQ(SP) {
            flex-direction: column;
        }
    
        & form .form-row em {
            font-size: 13px;
        }
        
        & form .form-row input.input-text {
            font-size: 16px;
        }

        & form .form-row label {
            font-size: 16px;
        }
    
        .button {
            background: #005598 !important;
            color: #fff !important;
            font-size: 16px;
            margin-right: 10px;
            padding: 8px 20px;
            
            &:hover {
                opacity: 0.8;
            }
        }
    }

    & .woocommerce button.button {
        font-size: 16px;
        padding: 12px 40px;
        background: $blue;
        color: #fff;
    }


    & .woocommerce-MyAccount-navigation {
        width: 15%;
        min-width: 260px;
        padding: 26px 48px 0px 55px;
        background: #f8f8f8;

        @include mediaQ(SP) {
            width: 100%;
            padding: 24px 48px 0px 45px;
            margin-bottom: 20px;
        }

        &-link {
            margin-bottom: 24px;
            font-size: 16px;
            position: relative;

            @include mediaQ(SP) {
                margin-bottom: 20px;
            }

            &::marker {
                display: none !important;
            }

            & a {
                text-decoration: none;
                color: #000;
            }

            &--dashboard::before {
                content: "";
                display: block;
                background: url(/assets/img/mypage/icon_mytop.svg) no-repeat center center / contain;
                width: 21px;
                height: 17px;
                position: absolute;
                top: 3px;
                left: -25px;
            }

            &--orders::before {
                content: "";
                display: block;
                background: url(/assets/img/mypage/icon_cart.svg) no-repeat center center / contain;
                width: 21px;
                height: 21px;
                position: absolute;
                top: 0;
                left: -25px;
            }

            &--edit-address::before {
                content: "";
                display: block;
                background: url(/assets/img/mypage/icon_adress.svg) no-repeat center center / contain;
                width: 12px;
                height: 17px;
                position: absolute;
                top: 2px;
                left: -25px;
            }

            &--payment-methods::before {
                content: "";
                display: block;
                background: url(/assets/img/mypage/icon_credit.svg) no-repeat center center / contain;
                width: 22px;
                height: 22px;
                position: absolute;
                top: 0;
                left: -25px;
            }

            &--edit-account::before {
                content: "";
                display: block;
                background: url(/assets/img/mypage/icon_account.svg) no-repeat center center / contain;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0;
                left: -29px;
            }

            &--customer-logout::before {
                content: "";
                display: block;
                background: url(/assets/img/mypage/icon_logout.svg) no-repeat center center / contain;
                width: 22px;
                height: 22px;
                position: absolute;
                top: 0;
                left: -25px;
            }
        }

    }

    & .woocommerce-MyAccount-content {
        margin-left: 60px;
        font-size: 16px;

        @include mediaQ(SP) {
            margin: 0 auto;
        }

        .nobr {
            font-weight: normal;
        }
        
        & .woocommerce-notices-wrapper+p {
            margin-left: -27px;
            margin-bottom: 12px;
            padding-bottom: 25px;
            border-bottom: 1px solid #ccc;
            font-size: 16px;

            @include mediaQ(SP) {
                margin-bottom: 24px;
                margin-left: auto;
            }
        }

        & .woocommerce-notices-wrapper+p+strong+a {
            color: #000;
            text-decoration: none;
        }

        & .woocommerce-MyAccount-navigation {
            &-link+p {
                margin-left: -27px;
                margin-bottom: 24px;
                font-size: 16px;
                
                @include mediaQ(SP) {
                    margin-left: auto;
                }
                
                &:last-of-type {
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 10px;
                }
            }
        }

        & .woocommerce-MyAccount-navigation {
            width: 15%;
            min-width: 260px;
            padding: 26px 48px 0px 55px;
            background: #f8f8f8;

            &-link {
                margin-bottom: 0;
                margin-top: 0;
                font-size: 16px;
                position: relative;

                @include mediaQ(SP) {
                    margin-bottom: 6px;
                    margin-left: 25px;
                }

                &::marker {
                    display: none !important;
                }

                & a {
                    text-decoration: none;
                    color: #000;
                }

                &--dashboard::before {
                    content: "";
                    display: block;
                    background: url(/assets/img/mypage/icon_mytop.svg) no-repeat center center / contain;
                    width: 21px;
                    height: 17px;
                    position: absolute;
                    top: 2px;
                    left: -25px;
                }

                &--orders::before {
                    content: "";
                    display: block;
                    background: url(/assets/img/mypage/icon_cart.svg) no-repeat center center / contain;
                    width: 21px;
                    height: 21px;
                    position: absolute;
                    top: 0;
                    left: -25px;
                }

                &--edit-address::before {
                    content: "";
                    display: block;
                    background: url(/assets/img/mypage/icon_adress.svg) no-repeat center center / contain;
                    width: 12px;
                    height: 17px;
                    position: absolute;
                    top: 3px;
                    left: -25px;
                }

                &--payment-methods::before {
                    content: "";
                    display: block;
                    background: url(/assets/img/mypage/icon_credit.svg) no-repeat center center / contain;
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    top: 0;
                    left: -25px;
                }

                &--edit-account::before {
                    content: "";
                    display: block;
                    background: url(/assets/img/mypage/icon_account.svg) no-repeat center center / contain;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    left: -29px;
                }

                &--customer-logout::before {
                    content: "";
                    display: block;
                    background: url(/assets/img/mypage/icon_logout.svg) no-repeat center center / contain;
                    width: 22px;
                    height: 22px;
                    position: absolute;
                    top: 0;
                    left: -25px;
                }
            }

        }
    }
    
    .woocommerce-MyAccount-navigation-link {
        
        &:before {
            width: 21px !important;
            background-position: center center !important;
        }
        
        a {
            font-weight: bold;
        }
    }
    .woocommerce-MyAccount-navigation-link--edit-account {
        &:before {
            left: -24px !important;
        }
    }

    input.input-text,
    textarea {
        @include mediaQ(PC) {
            height: 50px !important;
        }
    }
    
    input.input-text[type="email"] {
        @include mediaQ(PC) {
            margin-bottom: 20px !important;
        }
    }
    
    .select2-container--default .select2-selection--single {
        @include mediaQ(PC) {
            height: 50px !important;
        }
        
        .select2-selection__rendered {
            @include mediaQ(PC) {
                line-height: 50px !important;
                height: 50px !important;
            }
        }
        
        .select2-selection__arrow {
            @include mediaQ(PC) {
                height: 50px !important;
            }
        }
    }
    
    .form-row {
        margin-bottom: 10px;
    }
    
    fieldset {
        background: #f2f7fa;
        border: none;
    }
}

.pg_mypage_edit_address {
    & .woocommerce-MyAccount-content {
        width: 100%;

        @include mediaQ(SP) {
            width: 90%;
            margin: 0 auto;
        }

        .un_myAccountMyAddressDescription {
            margin-bottom: 50px !important;
            
            @include mediaQ(SP) {
                width: 100% !important;
                margin: 0 auto 25px !important;
            }
        }
    
        .un_address_wrap {
            @include mediaQ(SP) {
                margin-bottom: 25px;
            }
        }
        
        & .woocommerce-notices-wrapper+p {
            padding-bottom: 0;
            border-bottom: none;
        }
    
        .addresses {
            .title {
                .edit {
                    color: #337ab7;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    & .u-columns {
        font-size: 16px;
    }
    
    & .woocommerce-Address-title {
        .bl_ttl_lv3 {
            border-bottom: 1px solid rgba(0,0,0,.1);

            &:after {
                content: ".";
                line-height: 0;
                display: block;
                overflow: hidden;
                position: absolute;
                bottom: -1px;
                width: 30%;
                border-bottom: 1px solid #337ab7;
            }
        }

        &+address {
            font-style: unset;
        }
    }
    
    .col2-set .col-1,
    .col2-set .col-2 {
        @include mediaQ(PC) {
            width: 45% !important;
        }
    }
}

.pg_mypage_edit_address_billing {
    & .woocommerce {
        & .woocommerce-MyAccount-content {
            & form {
                & .form_row {
                    margin-bottom: 10px;
                    
                    & label {
                        font-size: 16px;
                    }
                }
            }

            @include mediaQ(SP) {
                width: 100%;
                margin-left: 0;
            }

            .bl_ttl_lv2 {
                @include bl_ttl_lv2__02;
            }
    
            input.input-text,
            textarea {
                @include mediaQ(PC) {
                    height: 50px !important;
                }
            }
    
            .select2-container--default .select2-selection--single {
                @include mediaQ(PC) {
                    height: 50px !important;
                }
        
                .select2-selection__rendered {
                    @include mediaQ(PC) {
                        line-height: 50px !important;
                        height: 50px !important;
                    }
                }
        
                .select2-selection__arrow {
                    @include mediaQ(PC) {
                        height: 50px !important;
                    }
                }
            }
        }
    
        @include my_form_css;
        
        .select2-selection__rendered {
            font-size: 16px;
        }
    }
}

.pg_mypage_edit_address_shipping {
    & .woocommerce {
        & .woocommerce-MyAccount-content {

            & form {
                & .form_row {
                    & label {
                        font-size: 16px;
                    }
                }
            }

            @include mediaQ(SP) {
                width: 100%;
                margin-left: 0;
            }
    
            .bl_ttl_lv2 {
                @include bl_ttl_lv2__02;
            }
        }
        
        @include my_form_css;
    }
    
    
    input.input-text,
    textarea {
        @include mediaQ(PC) {
            height: 50px !important;
        }
    }
    
    .select2-container--default .select2-selection--single {
        @include mediaQ(PC) {
            height: 50px !important;
        }
        
        .select2-selection__rendered {
            @include mediaQ(PC) {
                line-height: 50px !important;
                height: 50px !important;
            }
        }
        
        .select2-selection__arrow {
            @include mediaQ(PC) {
                height: 50px !important;
            }
        }
    }
    
    .woocommerce form .form-row {
        margin-bottom: 10px;
    }
}

.pg_mypage_orders {
    & .woocommerce {
        & form {
            & .form_row {
                & label {
                    font-size: 16px;
                }
            }
        }

        & table {
            &.my_account_orders td {
                font-size: 16px;
                padding: 9px 12px;
                vertical-align: middle;
                line-height: 1.5em;
            }
        }

        & .woocommerce-orders-table__header {
            font-size: 16px;
            font-weight: 700;
            padding: 9px 12px;
            line-height: 1.5em;
        }

        & .woocommerce-orders-table__cell {
            &-order-number a {
                color: $hv_blue;
            }

            &-order-total span {
                color: #ee6d70;
                font-weight: bold;
            }

            &-order-actions {
                & .woocommerce-button button view {
                    display: inline-block;
                    margin-right: 10px;
                }
            }

            & a.woocommerce-button.button.print{
                font-size: 16px;
                padding: 8px 20px;
                background: $blue;
                color: #fff;
                
                &:hover {
                    opacity: 0.8;
                }
            }

            & a.woocommerce-button.button.view{
                font-size: 16px;
                padding: 8px 20px;
                background: $blue;
                color: #fff;
                margin-right: 10px;
    
                &:hover {
                    opacity: 0.8;
                }
            }
        }

        & .woocommerce-MyAccount-content {
            width: 80%;
            margin-left: 60px;

            @include mediaQ(SP) {
                width: 100%;
                margin: 0 auto;
            }
        }
    }


}

.pg_mypage_view_order {
    & .woocommerce-MyAccount-content {
        font-size: 16px;
        width: 100%;
        @include mediaQ(SP) {
            margin: 0 auto;
        }

        & .woocommerce-notices-wrapper+p {
            border-bottom: 2px solid #005598;
        }

        & .woocommerce-order-details {
            & table.woocommerce-table--order-details {
                display: block;
                overflow-x: scroll;
                white-space: nowrap;
                -webkit-overflow-scrolling: touch;
            }
        }

        & .woocommerce-customer-details {
            & address .woocommerce_colum__cont__text {
                display: block;
                margin-bottom: 8px;
            }
        }
    
        .bl_ttl_lv2 {
            @include bl_ttl_lv2__02;
        }

        .woocommerce-Price-amount {
            color: #c00;
            font-weight: 700;
        }
    
        .un_lead {
            @include mediaQ(SP) {
                width: 100% !important;
                margin: 0 auto 24px !important;
            }
        }
    }

    & .woocommerce a.button {
        font-size: 16px;
        padding: 8px 20px;
        background: $blue;
        color: #fff;
    }

    & .woocommerce-table {

        &.shop_table {
            th.woocommerce-table__product-name, th.woocommerce-table__product-table {
                font-weight: normal;
            }
        }

        & .woocommerce-table__line-item {
            & .woocommerce-table__product-name {
                & .wc-item-meta {
                    & li .dopbsp-wc-cart {
                        width: 100%;

                        & tbody tr td strong {
                            display: block;
                            width: 200px;
                            color: #252525;
                        }
                    }
                }
            }
        }
    }

}

.pg_add_payment_method {
    .woocommerce-PaymentMethods {
        label[for="payment_method_stripe"] {
            display: inline-block;
            margin-bottom: 0.5rem;
            font-weight: 400;
            
            img {
                display: inline-block;
            }
        }
    }
}

.pg_edit_account {
    
    &.woocommerce-MyAccount-content {
        @include my_form_css;
    }
    
    fieldset {
        padding: 10px;
        margin-bottom: 30px;
    }
    
    .show-password-input {
        right: 14px !important;
        top: 56% !important;
        transform: translate(0, -50%) !important;
        
        &:after {
            display: inline-block;
            width: 24px;
            height: 24px;
            content: "" !important;
            background: url("/assets/img/icon_eye.svg");
            background-size: 24px 24px;
        }
    }

}



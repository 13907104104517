@use "sass:math";
@import "mixin";

.pg_plan {
    $body: &;

	.bl_txt_notice {
		color: $black;
		display: block;
		@include mediaQ(SP) {
			margin-bottom: 18px;
			line-height: 1.84;
		}
	}
    .un_content {
        &_ttl {
			@include mediaQ(PC) {
                margin-bottom: per(20px, $widthContent);
            }
			@include mediaQ(SP) {
				margin-bottom: 14px;
			}
			img {
				@include mediaQ(SP) {
					width: 100%;
				}
			}

			&_bnr {
				margin-bottom: per(20px, $widthContent);
			}
        }
		&_txt {
			font-weight: 400;
			line-height: math.div(32px, 16px);
			color: $black;
			font-size: 16px;
			@include mediaQ(PC) {
				// @include pcFS(16px);
				margin-bottom: per(30px, $widthContent);
			}
			@include mediaQ(SP) {
				// font-size: 16px;
				margin-bottom: 22px;
			}
		}
		&_pageNav {
			$cloud: '.un_content_pageNav__cloud';
			display: flex;
			justify-content: space-between;
			@include mediaQ(PC){
				margin-bottom: per(60px, $widthContent);
				&#{$cloud} {
					justify-content: center;
					.un_content_pageNav_item:nth-child(2) {
						margin: 0px 15px;
					}
				}
			}
			@include mediaQ(SP){
				margin-bottom: 33px;
				flex-wrap: wrap;
			}


			&_item {
				position: relative;
				height: 0;
				@include mediaQ(PC){
					// width: per(245px, $widthContent);
					width: per(263px, $widthContent);
					padding-bottom: per(73px, $widthContent);
				}
				@include mediaQ(SP){
					width: per(164px, 335px);
					padding-bottom: 50px;
					margin-bottom: 8px;
				}
			}
			&_link {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				width: 100%;
				height: 100%;
				color: $blue;
				font-weight: bold;
				background: $white;
				border: solid 1px $blue;
				border-radius: 4px;
				@include mediaQ(PC){
					font-size: 18px;
					// @include pcFS(18px);
					transition: all .3s;
				}
				@include mediaQ(SP){
					font-size: 13px;
					font-size: clamp(10px, convVW(13px, 400px), 13px);
				}
				&::after {
					content: "";
					display: block;
					position: absolute;
					left: 50%;
					border-right: solid 2px $blue;
					border-bottom: solid 2px $blue;
					transform: translateX(-50%) rotate(45deg);
					@include mediaQ(PC){
						bottom: per(9px, 73px);
						width: per(11px, 338px);
						padding-bottom: per(9px, 338px);
						transition: all .3s;
					}
					@include mediaQ(SP){
						bottom: 8px;
						width: 7px;
						height: 7px;
					}
				}
				&_txt {
					@include mediaQ(PC){
						margin-top: per(-7px, 340px);
					}
					@include mediaQ(SP){
						margin-top: -3px;
					}
				}
				@include mediaQ(PC){
					&:hover {
						color: $white;
						background: $blue;
						&::after {
							border-color: $white;
						}
					}
				}
			}
		}
		&_discount {
			display: flex;
			justify-content: center;
			// background: $white;
			@include mediaQ(PC){
				// padding: 0 20px 81px;
				// padding: 0 20px clamp(0px, per(81px, $widthContent), 81px);
				margin-bottom: 50px;
				margin-bottom: clamp(0px, per(81px, $widthContent), 50px);
			}
			@include mediaQ(SP){
				padding-bottom: 30px;
			}
			&_link {
				@include mediaQ(PC){
					transition: opacity .3s;
					&:hover {
						opacity: 0.6;
					}
				}
				@include mediaQ(SP){
					// width: per(335px, 375px);
					margin: 0 auto;
				}
				img {
					@include mediaQ(SP){
						width: 100%;
					}
				}
			}
		}
    }
	.un_toSubscription {
		$toSubsc: ".un_toSubscription";
		display: flex;
		flex-direction: column;
		align-items: center;
		font-weight: bold;
		background: #f8f8f8;
		@include mediaQ(PC) {
			// width: per(954px, $widthContent);
			font-size: 20px;
			// @include pcFS(20px);
			@include pcPAD_P((30px 0));
			margin: 0 auto;
		}
		@include mediaQ(SP) {
			font-size: 16px;
			line-height: math.div(25px, 16px);
			padding: 30px per(15px, 335px);
		}
		&#{$toSubsc}__content {
			@include mediaQ(PC) {
				margin-bottom: per(40px, $widthContent);
			}
			@include mediaQ(SP) {
				margin-bottom: 30px;
			}
		}
		&#{$toSubsc}__price {
			background: #F8F8F8;
		}
		&_txt {
			display: block;
			@include mediaQ(PC) {
				padding-left: per(18px, $widthContent );
			}
			@include mediaQ(SP) {
				text-align: center;
			}
		}
		&_txtNotice {
			font-size: 13px;
			font-weight: 400;
			margin-top: 25px;
			a {
				color: $hv_blue;
				text-decoration: underline;
			}
			// @include mediaQ(PC) {
			// 	padding-left: per(18px, $widthContent );
			// }
			// @include mediaQ(SP) {
			// 	text-align: center;
			// }
		}
		&_link {
			&Wrap {
				@include mediaQ(PC) {
					margin-top: per(20px, 954px);
				}
				@include mediaQ(SP) {
					width: 	100%;
					height: 55px;
					margin-top: 20px;
				}
			}
			font-weight: bold;
			@include mediaQ(PC) {
				font-size: 18px;
				// @include pcFS(18px);
			}
			@include mediaQ(SP) {
				font-size: 15px;
				padding-right: 10px;
			}

			&.un_amazon {
				&:after {
					border: none;

					@include mediaQ(PC) {
						background-image: url("/assets/img/icon_external.svg");
						background-size: 22px 22px;
						transform: translateY(-50%);
						width: 22px;
						height: 22px;
					}

					@include mediaQ(SP) {
						background-image: url("/assets/img/icon_external.svg");
						background-size: 18px 18px;
						transform: translateY(-50%);
						width: 18px;
						height: 18px;
					}
				}

				&:hover {
					&:after {
						border: none;
	
						@include mediaQ(PC) {
							background-image: url("/assets/img/icon_external_orange.svg");
							background-size: 22px 22px;
						}
	
						@include mediaQ(SP) {
							background-image: url("/assets/img/icon_external_orange.svg");
							background-size: 18px 18px;
						}
					}
				}
			}
		}
	}
	.un_feature {
		$feature: ".un_feature";
		@include mediaQ(PC) {
			margin-bottom: per(97px, $widthContent);
			margin-bottom: clamp(0px, per(97px, $widthContent), 97px)
		}
		@include mediaQ(SP) {
			margin-bottom: 95px;
		}
		&_list {
			$featureList: #{$feature}_list;
			&_item {
				$featureItem: #{$featureList}_item;
				$rental: #{$featureList}__rental;
				$chinaSim: #{$featureList}__sim;
				$cloud: #{$featureList}__cloud;
				&_ttl {
					display: flex;
					font-weight: bold;
					&_num {
						color: #D3EBFF;
						font-family: 'Roboto', sans-serif;
						line-height: 1;
					}
				}
				&_txt_span {
					display: block;
					font-size: 13px;
					padding-left: 1em;
					text-indent: -1em;
				}
				@include mediaQ(PC) {
					$txtW: 536px;
					$imgW: 530px;
					display: grid;
					display: -ms-grid;
					-ms-grid-rows: per(110px, 310px) 1fr;
					grid-template-rows: per(110px, 310px) 1fr;
					&:not(:last-of-type) {
						margin-bottom: per(84px, $widthContent);
					}
					&_ttl {
						-ms-grid-row-align: flex-start;
						align-self: flex-start;
						align-items: center;
						font-size: 25px;
						// @include pcFS(25px);
						line-height: math.div(38px, 25px);
						&_num {
							font-size: 130px;
							// @include pcFS(130px);
							line-height: per(96px, 130px);
							margin-right: per(8px, $txtW);
						}
					}
					&:nth-of-type(2n+1) {
						-ms-grid-columns: per($imgW, $widthContent) 1fr per($txtW, $widthContent);
						grid-template-columns: per($imgW, $widthContent) 1fr per($txtW, $widthContent);
						#{$featureItem}_ttl {
							grid-column: 3/4;
							-ms-grid-column: 3/4;
							grid-row: 1/2;
							-ms-grid-row: 1/2;
						}
						#{$featureItem}_txt {
							grid-column: 3/4;
							-ms-grid-column: 3/4;
							grid-row: 2/3;
							-ms-grid-row: 2/3;
							margin-top: per(-20px, $txtW);
							padding: per(37px, $txtW) 0 0 per(8px, $txtW);
						}
						#{$featureItem}_img {
							grid-column: 1/2;
							-ms-grid-column: 1/2;
							grid-row: 1/3;
							-ms-grid-row: 1/3;
						}
					}
					&:nth-of-type(2n) {
						grid-template-columns: per($txtW, $widthContent) 1fr per($imgW, $widthContent);
						-ms-grid-columns: per($txtW, $widthContent) 1fr per($imgW, $widthContent);
						#{$featureItem}_ttl {
							grid-column: 1/2;
							-ms-grid-column: 1/2;
							grid-row: 1/2;
							-ms-grid-row: 1/2;
						}
						#{$featureItem}_txt {
							grid-column: 1/2;
							-ms-grid-column: 1/2;
							grid-row: 2/3;
							-ms-grid-row: 2/3;
							padding: per(19px, $txtW) 0 0 per(8px, $txtW);

						}
						#{$featureItem}_img {
							grid-column: 3/4;
							-ms-grid-column: 3/4;
							grid-row: 1/3;
							-ms-grid-row: 1/3;
						}
					}
				}
				@include mediaQ(SP) {
					display: flex;
					flex-direction: column;
					&:not(:last-of-type) {
						margin-bottom: 50px;
					}
					&_ttl {
						order: 2;
						align-items: center;
						font-size: 20px;
						line-height: math.div(31px, 20px);
						&_num {
							display: flex;
							align-self: safe flex-start;
							font-size: 120px;
							line-height: 105px;
							margin-right: 13px;
							margin-left: per(-7px, 335px);
						}
						&_txt {
							display: flex;
							align-items: center;
							align-items: flex-start;
						}
						&#{$featureItem}_ttl__spaceNarrow {
							width: calc(100% + 2px);
							#{$featureItem}_ttl_num {
								margin-right: -2px;
							}
						}
					}
					&_txt {
						order: 3;
					}
					&_img {
						order: 1;
						margin-bottom: 22px;
						img {
							max-width: none;
							width: 100%;
						}
					}
				}

				@at-root {
					.pg_plan #{$featureList}#{$rental} #{$featureItem} {
						@include mediaQ(PC) {
							$txtW: 536px;
							&:nth-of-type(3) {
								#{$featureItem}_txt {
									padding: per(21px, $txtW) 0 0 per(8px, $txtW);
								}
							}
							&:nth-of-type(2n) {
								#{$featureItem}_txt {		
									padding: per(12px, $txtW) 0 0 per(8px, $txtW);
		
								}
								
							}
						}
					}
					.pg_plan #{$featureList}#{$chinaSim} #{$featureItem} {
						@include mediaQ(PC) {
							$txtW: 536px;
							&:nth-of-type(1) {
								#{$featureItem}_txt {
									padding: per(21px, $txtW) 0 0 per(8px, $txtW);
								}
							}
							&:nth-of-type(3) {
								#{$featureItem}_txt {
									padding: per(30px, $txtW) 0 0 per(8px, $txtW);
								}
							}
						}
					}
					.pg_plan #{$featureList}#{$cloud} #{$featureItem} {
						@include mediaQ(PC) {
							$txtW: 536px;
							&:nth-of-type(2n) {
								#{$featureItem}_txt {
									padding: per(13px, $txtW) 0 0 per(8px, $txtW);
								}
							}
						}
					}
				}
				

			}
		}
	}
	.un_price {
		$price: ".un_price";
		@include mediaQ(PC) {
			@include pcPAD_P((0 0 84px));
		}
		// @include mediaQ(SP) {
		// 	padding-bottom: 0px;
		// }
		&_lead {
			// background: $hv_blue;
			// border: solid 1px $hv_blue;
			// border-radius: 3px;
			@include mediaQ(PC) {
				position: relative;
				width: per(900px, $widthContent);
				height: 0;
				padding-bottom: per(188px, $widthContent);
				margin: 0 auto per(20px, $widthContent);
			}
			@include mediaQ(SP) {
				text-align: center;
				margin-bottom: 12px;
				display:flex;
				justify-content: center;
			}
			&_txt {
				display: flex;
				justify-content: center;
				align-items: center;
				color: $hv_blue;
				font-weight: bold;
				text-align: center;
				background: $white;
				@include mediaQ(PC) {
					position: absolute;
					top: 0;
					left: 0;
					width: per(447px, 898px);
					height: 100%;
					font-size: 28px;
					// @include pcFS(28px);
					line-height: math.div(40px, 28px);
					padding-left: per(12px, 898px);
				}
				@include mediaQ(SP) {
					padding: 18px 0;
					font-size: 22px;
				}
			}
			&_monthly {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				color: $white;
				@include mediaQ(PC) {
					position: absolute;
					top: 0;
					right: 0;
					width: per(900px - 447px, 898px);
					height: 100%;
					padding-left: per(17px, 898px);
				}
				@include mediaQ(SP) {
					padding: 10px 0 15px;
				}
				&_inner {
					display: flex;
					justify-content: center;
					align-items: flex-end;
					font-weight: bold;
					@include mediaQ(PC) {
						font-size: 40px;
						// @include pcFS(40px);
					}
					@include mediaQ(SP) {
						font-size: 30px;
					}
				}
				&_price {
					line-height: 1;
					font-family: 'Roboto', sans-serif;
					@include mediaQ(PC) {
						// @include pcFS(100px);
						font-size: 120px;
						// @include pcFS(120px);
						margin: 0 per(-14px, 370px) 0 per(7px, 370px);
					}
					@include mediaQ(SP) {
						// font-size: 80px;
						font-size: 96px;
						margin-left: 5px;
					}
				}
				&_unit {
					display: flex;
					flex-direction: column-reverse;
					align-items: center;
					@include mediaQ(SP) {
						padding-bottom: 5px;
					}
					&_rmb {
						@include mediaQ(PC) {
							line-height: 1.3;
							font-size: 36px;
							// @include pcFS(36px);
						}
						@include mediaQ(SP) {
							line-height: 1;
							font-size: 28px;
						}
					}
					&_tax {
						@include mediaQ(PC) {
							font-size: 18px;
							// @include pcFS(18px);
							// @include pcFS(30px);
						}
						@include mediaQ(SP) {
							// font-size: 20px;
							font-size: 13px;
							margin-left: -5px;
						}
					}
				}
				&_note {
					@include mediaQ(PC) {
						// @include pcFS(16px);
						margin-left: auto;
						font-size: 14px;
						// @include pcFS(14px);
						margin-right: per(32px, 900px - 447px);
					}
					@include mediaQ(SP) {
						font-size: 10px;
						margin-left: -16px;
					}
				}
			}
		}
		&_list {
			padding-left: 0;
			&_item {
				text-indent: -1em;
				padding-left: 1em;
			}
		}
		&_note {
			@include mediaQ(PC) {
				margin-top: per(5px, $widthContent);
			}
			@include mediaQ(SP) {
				margin-top: 13px;
			}
		}
		&_table {
			$priceTable: #{$price}_table;
			$priceTableWrap: #{$priceTable}Wrap;
			&Wrap {
				@include mediaQ(SP) {
					width: 100%;
					overflow: auto;
					white-space: nowrap;
				}
			}
			width: 100%;
			background: $white;
			@include mediaQ(PC) {
				font-size: 16px;
				// @include pcFS(16px);
				line-height: math.div(30px, 16px);
				margin-top: per(26px, $widthContent);
				border-top: 1px solid #ccc;
				border-left: 1px solid #ccc;
				@at-root {
					#{$body} {
						#{$priceTableWrap} + #{$priceTableWrap},
						#{$priceTableWrap} + #{$priceTable},
						#{$priceTable} + #{$priceTableWrap},
						#{$priceTable} + #{$priceTable} {
							margin-bottom: per(45px, $widthContent);
						}
					}
				}
			}
			@include mediaQ(SP) {
				display: block;
				font-size: 16px;
				margin-top: 20px;
				border-left: 1px solid #ccc;
				border-right: 1px solid #ccc;
				border-bottom: 1px solid #ccc;
				@at-root {
					#{$body} {
						#{$priceTableWrap} {
							#{$priceTable} {
								display: table;
							}
						}
						#{$priceTableWrap} + #{$priceTableWrap},
						#{$priceTableWrap} + #{$priceTable},
						#{$priceTable} + #{$priceTableWrap},
						#{$priceTable} + #{$priceTable} {
							margin-bottom: 17px;
						}
					}
				}
			}
			// &#{$priceTable}__border {
			// 	#{$priceTable}_ttl {
			// 		border-top: solid 1px #000;
			// 		border-right: solid 1px #000;
			// 		border-bottom: solid 1px #000;
			// 	}
			// 	#{$priceTable}_cont {
			// 		border: solid 1px #000;
			// 	}
			// }
			@at-root {
				#{$body} {
					#{$price}_txt {
						+ #{$priceTable} {
							@include mediaQ(PC) {
								margin-top: per(26px, $widthContent);;
							}
							@include mediaQ(SP) {
								margin-top: 17px;
							}
						}
					}
				}
				.pg_plan__chinaMobile .un_price_table {
					@include mediaQ(PC) {  
						margin-bottom: -28px;
					}
					
				}
				.un_price_table.un_price_table__border {
					@include mediaQ(SP) {
						.un_price_table_ttl {
							border-right: solid 1px #ccc;
						}
						.un_price_table_cont {
							border-right: solid 1px #ccc;
							border-bottom: solid 1px #ccc;
						}
					}
				}
			}
			tbody {
				@include mediaQ(SP) {  
					display: block;
					@at-root {
						#{$body} {
							#{$priceTableWrap} {
								#{$priceTable} {
									tbody {
										display: table-row-group;
									}
								}
							}
						}
					}
				}
			}
			&_row {
				$priceTableRow: #{$priceTable}_row;
				@include mediaQ(SP) {
					display: block;
					&:not(:last-of-type) {
						margin-bottom: 2px;
					}
					
					@at-root {
						#{$body} {
							#{$priceTableWrap} {
								#{$priceTableRow} {
									display: table-row;
								}
							}
						}
					}
				}
			}
			&_ttl {
				$priceTableTtl: #{$priceTable}_ttl;
				color: $black;
				vertical-align: top;
				background: $_bg_blue;
				font-weight: 400;
				@include mediaQ(PC) {
					width: per(342px, $widthContent);
					@include pcPAD_P((9px 28px 9px 36px));
					border-bottom: solid 1px #ccc;
					border-right: solid 1px #ccc;
				}
				@include mediaQ(SP) {
					display: block;
					font-weight: 400;
					padding: 13px 11px 14px;
					margin-bottom: 2px;
					border-bottom: solid 1px #ccc;
					border-top: solid 1px #ccc;
					@at-root {
						#{$body} {
							#{$priceTableWrap} {
								#{$priceTableTtl} {
									display: table-cell;
								}
							}
						}
					}
				}
				&#{$priceTableTtl}__BGgray {
					width: auto;
					color: $black;
					font-weight: normal;
					padding-left: 20px;
					background: #F8F8F8;
				}
			}
			&_cont {
				$priceTableCont: #{$priceTable}_cont;
				color: $black;
				@include mediaQ(PC) {
					@include pcPAD_P((9px 20px 10px));
					border-right: solid 1px #ccc;
					border-bottom: solid 1px #ccc;
				}
				@include mediaQ(SP) {
					display: block;
					line-height: math.div(25px, 16px);
					padding: 15px 15px 11px 11px;
					@at-root {
						#{$body} {
							#{$priceTableWrap} {
								#{$priceTableCont} {
									display: table-cell;
								}
							}
						}
					}
				}

				&.un_ta-r {
					text-align: right;
				}
			}
			&Note {
				@include mediaQ(PC) {
					margin-top: per(15px, $widthContent);
				}
				@include mediaQ(SP) {
					margin-top: 30px;
				}
			}
		}

		&_txt {
			a {
				color: $hv_blue;
				text-decoration: underline;
			}
		}
		.un_corporate {
			@include mediaQ(PC) {
				margin-top: per(40px, $widthContent);
			}
			@include mediaQ(SP) {
				margin-top: 30px;
			}
			&_ttl {
				@include mediaQ(PC){
					margin-bottom: per(18px, $widthContent);
				}
				@include mediaQ(SP){
					margin-bottom: 8px;
				}
			}

			&_txt {
				a {
					color: $hv_blue;
					text-decoration: underline;
				}
			}
		}
		.un_pay {
			$pay: ".un_pay";
			@include mediaQ(PC) {
				margin-top: per(40px, $widthContent);
				margin-bottom: 0;
				// margin-bottom: per(86px, $widthContent);
			}
			@include mediaQ(SP) {
				margin-top: 27px;
				margin-bottom: 30px;
			}
			&_ttl {
				@include mediaQ(PC){
					margin-bottom: per(18px, $widthContent);
				}
				@include mediaQ(SP){
					margin-bottom: 8px;
				}
			}
			&_list {
				$payList: #{$pay}_list;
				&_cont {
					$payListCont: #{$payList}_cont;
					&_ttl {
						font-weight: bold;
					}
					@include mediaQ(PC) {
						display: grid;
						display: -ms-grid;
						$txtW: 531px;
						$imgW: 529px;	
						grid-template-columns: per($txtW, $widthContent) 1fr per($imgW, $widthContent);
						-ms-grid-columns: per($txtW, $widthContent) 1fr per($imgW, $widthContent);
						&:not(:last-of-type) {
							margin-bottom: per(30px, $widthContent);
						}
						&_ttl {
							font-size: 21px;
							// @include pcFS(21px);
							grid-column: 1/2;
							-ms-grid-column: 1/1;
							grid-row: 1/2;
							-ms-grid-row: 1/2;
							margin-bottom: per(8px, $widthContent);
						}
						&_txt {
							grid-column: 1/2;
							-ms-grid-column: 1/2;
							grid-row: 2/4;
							-ms-grid-row: 2/4;
							font-size: 16px;
							// @include pcFS(16px);
							line-height: math.div(30px, 16px);
						}
						&_img {
							display: flex;
							align-items: flex-end;
							grid-column: 3/4;
							-ms-grid-column: 3/4;
							grid-row: 1/3;
							-ms-grid-row: 1/3;
							$payListContImg: #{$payListCont}_img;
							&_list {
								$payListContImgList: #{$payListContImg}_list;
								justify-content: space-between;
								&#{$payListContImgList}__credit {
									@include pcPAD_P((48px 120px), $imgW);
									transform: translateY(calc(100% - calc(100% + 4px)));
								}
								&#{$payListContImgList}__bank {
									@include pcPAD_P((55px 32px), $imgW);
								}
							}
						}
					}
					@include mediaQ(SP) {
						&:not(:last-of-type) {
							margin-bottom: 26px;
						}
						&_ttl {
							font-size: 19px;
							line-height: math.div(30px, 19px);
							margin-bottom: 5px;
						}
						&_txt {
							font-size: 16px;
							line-height: math.div(30px, 16px);
							margin-bottom: 14px;
						}
						&_img {
							&_list {
								justify-content: space-between;
							}
						}
						&#{$payListCont}__credit {
							#{$payListCont}_img {
								&_list {
									padding: 28px per(34px, 335px) 23px per(34px, 335px);
								}
							}
						}
						&#{$payListCont}__bank {
							#{$payListCont}_ttl {
								margin-bottom: 16px;
							}
							#{$payListCont}_txt {
								margin-bottom: 15px;
							}
							#{$payListCont}_img {
								&_list {
									padding: 18px per(18px, 335px) 18px per(15px, 335px);
									&_item {
										width: 29%;
									}
								}
							}
						}
					}
					&_img {
						&_list {
							display: flex;
							align-items: center;
							width: 100%;
							background: $white;
							border: 1px solid #ccc;
						}
					}
				}
			}
		}
	}
	.un_flow {
		$flow: ".un_flow";
		background: $white;

		@include mediaQ(PC){
			@include pcPAD_P((0 0 84px)); 
			@at-root {
				.pg_plan__dokodemoWifiRental .un_flow {
					@include pcPAD_P((0 0 40px)); 
				}
			}
		}
		@include mediaQ(SP){
			width: per(300px, 335px);
			padding: 40px 0 ;
			margin: 0 auto;
		}
		&_list {
			$flowList: #{$flow}_list;
			list-style: none;
			@include mediaQ(PC){
				display: flex;
				justify-content: center;
				padding-top: per(40px, $widthContent);
			}
			@include mediaQ(SP){
				padding-top: 40px;
			}
			&_item {
				$flowItem: #{$flowList}_item;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				flex: 0 1 auto;
				border: solid 1px #ccc;
				@include mediaQ(PC){
					width: per(245px, $widthContent);
					@include pcPAD_P((55px 23px 11px 24px), $widthContent);
				}
				@include mediaQ(SP){
					padding: 55px per(18px, 300px) 20px;
				}
				&:not(:last-of-type) {
					@include mediaQ(PC){
						margin-right: per(40px, $widthContent);
					}
					@include mediaQ(SP){
						margin-bottom: 90px;
					}
					&::after {
						content: "";
						display: block;
						position: absolute;
						border-color: #C3C3C3;
						border-width: 2px;
						transform: translate(-50%, -50%) rotate(45deg);
						@include mediaQ(PC) {
							top: 50%;
							right: per(-30px, 245px);
							width: per(15px, 245px);
							height: 0;
							padding-bottom: per(13px, 245px);
							border-top-style: solid;
							border-right-style: solid;
						}
						@include mediaQ(SP) {
							bottom: -42px;
							left: 50%;
							width: 20px;
							height: 20px;
							border-right-style: solid;
							border-bottom-style: solid;
						}
					}
				}
				&_step {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					position: absolute;
					top: 0;
					left: 50%;
					background: $blue;
					border-radius: 50%;
					transform: translate(-50%, -50%);
					@include mediaQ(PC) {
						width: per(80px, 245px);
						height: 0;
						padding-bottom: per(80px, 245px);
					}
					@include mediaQ() {
						width: 80px;
						height: 80px;
					}
					&_txt, &_num {
						display: flex;
						justify-content: center;
						position: absolute;
						width: 100%;
						height: 50%;
						color: $white;
						font-family: 'Roboto', sans-serif;
						font-weight: bold;
					}
					&_txt {
						top: 0;
						align-items: flex-end;
						background: $hv_blue;
						font-size: 17px;
						// @include mediaQ(PC) {
						// 	@include pcFS(17px);
						// }
						// @include mediaQ(SP) {
						// 	font-size: 17px;
						// }
					}
					&_num {
						top: 50%;
						align-items: flex-start;
						font-size: 29px;
						// @include mediaQ(PC) {
						// 	@include pcFS(29px);
						// }
						// @include mediaQ(SP) {
						// 	font-size: 29px;
						// }
					}
				}
				&_ttl {
					font-weight: bold;
					font-size: 20px;
					@include mediaQ(PC){
						// @include pcFS(20px);
						margin-bottom: per(8px, 195px);
					}
					@include mediaQ(SP){
						// font-size: 20px;
						margin-bottom: 15px;
					}
				}
				&_img {
					$flowItemImg: #{$flowItem}_img;
					@include mediaQ(PC){
						display: flex;
						justify-content: center;
						align-items: center;
						flex: 0 1 101px;
						flex: 0 1 clamp(0px, convVW(101px, $maxWidthContent), 101px);
						width: 100%;
						margin-bottom: per(10px, 195px);
					}
					@include mediaQ(SP){
						margin-bottom: 18px;
					}
					img {
						@include mediaQ(SP){
							max-width: none;
							width: 100%;
						}
					}
					@at-root {
						#{$body} {
							#{$flowItem}#{$flowItem}__step1 {
								#{$flowItemImg} {
									@include mediaQ(SP){
										width: 150px;
									}
								}
								#{$flowItemImg}__chinaMobile {
									@include mediaQ(SP){
										width: 46px;
									}
								}
							}	
							#{$flowItem}#{$flowItem}__step2 {
								#{$flowItemImg} {
									@include mediaQ(SP){
										width: 60px;
									}
								}
								#{$flowItemImg}__chinaMobile {
									@include mediaQ(SP){
										width: 150px;
									}
								}
							}	
							#{$flowItem}#{$flowItem}__step3 {
								#{$flowItemImg} {
									@include mediaQ(SP){
										width: 80px;
									}
								}
								#{$flowItemImg}__mobile {
									@include mediaQ(SP){
										width: 100px;
									}
								}
							}	
							#{$flowItem}#{$flowItem}__step4 {
								#{$flowItemImg} {
									@include mediaQ(SP){
										width: 60px;
									}
								}
								#{$flowItemImg}__chinaMobile {
									@include mediaQ(SP){
										width: 110px;
									}
								}
							}	
						}
					}
				}
				&_txt {
					line-height: math.div(24px, 14px);
					font-size: 14px;
					@include mediaQ(PC){
						flex: 1;
						// @include pcFS(14px);
					}
					// @include mediaQ(SP){
					// 	font-size: 14px;
					// }
					&_note {
						display: block;
						font-size: 12px;
						@include mediaQ(PC){
							// @include pcFS(12px);
							line-height: math.div(24px, 12px);
							margin-top: per(5px, 195px);
						}
						@include mediaQ(SP){
							// font-size: 12px;
							line-height: math.div(24px, 12px);
							margin-top: 5px;
						}
					}
				}
			}
		}
		&_noteList {
			@include mediaQ(PC){
				margin-top: 25px;
				margin-top: per(25px, $widthContent);
			}
			@include mediaQ(SP){
				margin-top: 25px;
			}
		}
	}
	.un_receivingReturn {
		@include mediaQ(PC) {
			margin-bottom: per(45px, $widthContent);
		}
		@include mediaQ(SP) {
			margin-top: 30px;
		}
		&_ttl {
			@include mediaQ(PC){
				margin-bottom: per(18px, $widthContent);
			}
			@include mediaQ(SP){
				margin-bottom: 8px;
			}
		}

	}
	.un_how {
		$how: ".un_how";
		background: $white;
		@include mediaQ(PC){
			@include pcPAD_P((0 0 82px)); 
		}
		@include mediaQ(SP){
			padding-bottom: 40px;
		}
		&_desc {
			@include mediaQ(PC){
				display: flex;
				justify-content: space-between;
			}
			&_img {
				@include mediaQ(PC){
					width: per(530px, $widthContent);
				}
				@include mediaQ(SP){
					margin-bottom: 17px;
				}
				img {
					@include mediaQ(SP){
						max-width: none;
						width: 100%;
					}
				}
			}
			&_txt {
				@include mediaQ(PC){
					width: per(530px, $widthContent);
				}
				&_note {
					display: block;
					line-height: 2;
					padding-left: 1em;
					text-indent: -1em;
					@include mediaQ(PC){
						font-size: 13px;
						// @include pcFS(13px);
						margin-top: per(13px, 530px);
					}
					@include mediaQ(SP){
						font-size: 14px;
						line-height: math.div(28px, 14px);
						// text-indent: -1em;
						// padding-left: 1em;
						margin-top: 5px;
					}
				}
			}
		}
	}
	//文字が少し右によって見えるため調整
	.btn_linkBtn.btn_linkBtn__rental {
		padding-right: 8px;
	}
	.un_qa {
		@include mediaQ(PC){
			@include pcMGN_P((0 0 76px)); 
		}
		@include mediaQ(SP){
			margin-bottom: 40px;
		}
	}
	.un_otherPlan {
		$otherPlan: ".un_otherPlan";
		background: #F2F7FA;
		@include mediaQ(PC) {
			@include pcPAD_P(70px 0 82px);
		}
		@include mediaQ(SP) {
			padding: 32px 0 42px;
		}
		&_bnr {
			@include mediaQ(PC) {
				margin-bottom: 50px;
				margin-bottom: clamp(0px, per(81px, $widthContent), 50px);
			}
			@include mediaQ(SP) {
				padding-bottom: 30px;
				a {
					display: flex;
					justify-content: center;
				}
			}
		}
		&_list {
			$otherPlanList: #{$otherPlan}_list;
			@include mediaQ(PC) {
				padding-top: per(7px, $widthContent);
			}
			&_item {
				$otherPlanItem: #{$otherPlanList}_item;
				overflow: hidden;
				position: relative;
				background: $white;
				box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
				@include mediaQ(PC) {
					width: per(340px, $widthContent);
				}
				@include mediaQ(SP) {
					&:not(:last-of-type) {
						margin-bottom: 30px;
					}
				}
				&_link {
					$otherPlanLink: #{$otherPlanItem}_link;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					flex: 1;
					position: relative;
					height: 100%;
					@include mediaQ(PC) {
						@include pcPAD_P((47px 18px 25px), 340px);
						transition: opacity .3s;
					}
					@include mediaQ(SP) {
						padding: 36px per(18px, 335px) 25px;
					}
					@at-root {
						#{$body} {
							#{$otherPlanItem}#{$otherPlanItem}__SIM #{$otherPlanLink} {
								@include mediaQ(SP) {
									padding-bottom: 40px;
								}
							}
							#{$otherPlanItem}#{$otherPlanItem}__wifiOffice #{$otherPlanLink} {
								@include mediaQ(SP) {
									padding-bottom: 37px;
								}
							}
						}
					}
					&::before, &::after {
						content: "";
						display: block;
						position: absolute;
					}
					&::before {
						right: 0;
						bottom: 0;
						background: #333;
						transform: translate(50%, 50%) rotate(45deg);
						@include mediaQ(PC) {
							width: per(74px, 340px);
							height: 0;
							padding-bottom: per(74px, 340px);
						}
						@include mediaQ(SP) {
							width: 76px;
							height: 76px;
						}
					}
					&::after {
						border-top: solid 1px $white;
						border-right: solid 1px $white;
						transform: rotate(45deg);
						@include mediaQ(PC) {
							right: per(11px, 340px);
							bottom: per(11px, 340px);
							width: per(10px, 340px);
							height: 0;
							padding-bottom: per(9px, 340px);
						}
						@include mediaQ(SP) {
							right: 10px;
							bottom: 10px;
							width: 10px;
							height: 10px;
						}
					}
					@include mediaQ(PC) {
						&:hover {
							opacity: 0.6;
						}
					}
				}	
				&_ttl {
					$otherPlanTtl: #{$otherPlanItem}_ttl;
					font-weight: bold;
					text-align: center;
					@include mediaQ(PC) {
						flex: 0 1 auto;
						display: flex;
						flex-direction: column;
						justify-content: center;
						font-size: 30px;
						// @include pcFS(30px);
						line-height: math.div(40px, 30px);
					}
					@include mediaQ(SP) {
						font-size: 26px;
						line-height: math.div(35px, 26px);
					}
					@at-root {
						#{$body} {
							#{$otherPlanItem}#{$otherPlanItem}__SIM #{$otherPlanTtl} {
								@include mediaQ(SP) {
									font-size: 24px;
									margin-top: 22px;
								}
							}
						}
					}
					&_anyWhere {
						display: block;
						@include mediaQ(PC) {
							font-size: 25px;
							// @include pcFS(25px);
							line-height: math.div(32px, 25px);
							margin-top: per(9px, 210px);
						}
						@include mediaQ(SP) {
							font-size: 22px;
							line-height: math.div(30px, 22px);
						}
					}
				}
				&_accept {
					$otherPlanAccept: #{$otherPlanItem}_accept;
					display: flex;
					justify-content: center;
					position: absolute;
					top: 0;
					left: 0;
					background: $hv_blue;
					transform: translate(-50%, -50%) rotate(-45deg);
					@include mediaQ(PC) {
						width: per(144px, 340px);
						height: 0;
						font-size: 13px;
						// @include pcFS(13px);
						padding-bottom: per(144px, 340px);
					}
					@include mediaQ(SP) {
						width: 140px;
						height: 140px;
					}
					&#{$otherPlanAccept}__ch {
						background: $blue;
					}
					&_txt {
						position: absolute;
						color: $white;
						font-weight: bold;
						text-align: center;
						@include mediaQ(PC) {
							bottom: per(6px, 140px);
						}
						@include mediaQ(SP) {
							bottom: 8px;
						}
					}
				}
				&_txt {
					$otherPlanTxt: #{$otherPlanItem}_txt;
					position: relative;
					@include mediaQ(PC) {
						display: flex;
						align-items: center;
						flex: 1;
						font-size: 16px;
						// @include pcFS(16px);
						line-height: math.div(28px, 16px);
					}
					@include mediaQ(SP) {
						font-size: 14px;
						line-height: math.div(24px, 14px);
					}
					@at-root {
						#{$body} {
							#{$otherPlanItem}#{$otherPlanItem}__wifiHome #{$otherPlanTxt} {
								@include mediaQ(PC) {
									padding-left: per(142px, 304px);
									margin-top: 3.9473684211%;
								}
							}
							#{$otherPlanItem}#{$otherPlanItem}__wifiRental #{$otherPlanTxt} {
								@include mediaQ(PC) {
									padding-left: per(142px, 304px);
									margin-top: 3.9473684211%;
								}
							}
							#{$otherPlanItem}#{$otherPlanItem}__SIM #{$otherPlanTxt} {
								@include mediaQ(PC) {
									padding-left: per(134px, 304px);
									margin-top: per(12px, 304px);
								}
							}
							#{$otherPlanItem}#{$otherPlanItem}__wifiOffice #{$otherPlanTxt} {
								@include mediaQ(PC) {
									padding-left: per(127px, 304px);
									margin-top: 10.263158%;
								}
							}
							#{$otherPlanItem}#{$otherPlanItem}__wifiMobile #{$otherPlanTxt} {
								@include mediaQ(PC) {
									padding-left: per(127px, 304px);
									margin-top: 3.9473684211%;
								}
							}
						}
					}
					&::before {
						$otherPlanTxtBefore: #{$otherPlanTxt}+"::before";
						content: "";
						display: block;
						background-repeat: no-repeat;
						background-position: center center;
						@include mediaQ(PC) {
							position: absolute;
							top: 0;
							left: 0;
							width: per(134px, 304px);
							height: 100%;
						}
						@include mediaQ(SP) {
							background-size: contain;
						}
						@at-root {
							#{$body} {
								#{$otherPlanItem}#{$otherPlanItem}__wifiHome #{$otherPlanTxtBefore} {
									background-image: url("/assets/img/icon_home.svg");
									@include mediaQ(PC) {
										background-size: per(98px, 134px) auto;
									}
									@include mediaQ(SP) {
										height: 78px;
										margin: 15px 15px 10px 0;
									}
								}
								#{$otherPlanItem}#{$otherPlanItem}__wifiRental #{$otherPlanTxtBefore} {
									background-image: url("/assets/img/icon_wifi2.svg");
									@include mediaQ(PC) {
										background-size: per(98px, 134px) auto;
									}
									@include mediaQ(SP) {
										height: 78px;
										margin: 15px 15px 10px 0;
									}
								}
								#{$otherPlanItem}#{$otherPlanItem}__SIM #{$otherPlanTxtBefore} {
									background-image: url("/assets/img/icon_sim.svg");
									@include mediaQ(PC) {
										background-size: per(52px, 134px) auto;
									}
									@include mediaQ(SP) {
										height: 63px;
										margin: 22px 0 18px;
									}
								}
								#{$otherPlanItem}#{$otherPlanItem}__wifiOffice #{$otherPlanTxtBefore} {
									background-image: url("/assets/img/icon_building.svg");
									@include mediaQ(PC) {
										left: per(-10px, 304px);
										background-size: per(45px, 134px) auto;
									}
									@include mediaQ(SP) {
										height: 61px;
										margin: 16px 0 17px;
									}
								}
								#{$otherPlanItem}#{$otherPlanItem}__wifiMobile #{$otherPlanTxtBefore} {
									background-image: url("/assets/img/icon_wifi1.svg");
									@include mediaQ(PC) {
										left: per(-10px, 304px);
										background-size: 68.80597% auto;
									}
									@include mediaQ(SP) {
										height: 61px;
										margin: 16px 0 17px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.pg_plan .un_otherPlan_list_item.un_otherPlan_list_item__wifiOffice .un_otherPlan_list_item_ttl {
	@include mediaQ(PC) {
		padding-top: 16px;
	}
}

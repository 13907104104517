@use "sass:math";
@import "mixin";

.bl_sitemap_wrap {
	display: flex;
	align-items: start;
	justify-content: space-between;
	padding-bottom:90px;
}
.bl_sitemap_box {
	width: 49%;
	flex-shrink: 0;
}
.bl_sitemap_box .ulList__map {
    width: 100%;
}
.bl_sitemap_box_detail {
	margin-left:30px;
}
.bl_sitemap_box_detail .ulList__mapMid li:not(:last-child) {
    margin-bottom: 0px;
}
.bl_sitemap_box_detail .ulList li:not(:last-child) {
    margin-bottom: 0px;
}
.el_sitemap_indent {
	margin-left:50px;
}
.ulList.el_sitemap_list {
    padding-left: 1rem;
}
.el_sitemap_txt {
    padding-left: 40px;
}



@media screen and (max-width: 768px) {
  
  
.bl_sitemap_wrap {
	display: block;
	align-items: start;
	justify-content: space-between;
	padding-bottom:0;
}
.bl_sitemap_box {
	width: 100%;
	flex-shrink: 0;
	padding-bottom:40px;
}
.bl_sitemap_box .ulList__map {
    width: 100%;
}
.bl_sitemap_box_detail {
	margin-left:30px;
}
.bl_sitemap_box_detail .ulList__mapMid li:not(:last-child) {
    margin-bottom: 0px;
}
.bl_sitemap_box_detail .ulList li:not(:last-child) {
    margin-bottom: 0px;
}
.el_sitemap_indent {
	margin-left:50px;
}
.ulList.el_sitemap_list {
    padding-left: 1rem;
}
.el_sitemap_txt {
    padding-left: 40px;
}
.bl_sitemap_wrap .mb15 {
	margin-bottom:5px!important;
}


}

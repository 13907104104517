@use "sass:math";

@mixin bl_ttl_lv2__02(){
    font-weight: bold; position: relative;
    @include mediaQ(PC) {
        font-size: 26px; font-size: clamp(1rem, 2.2807017544vw, 26px); padding-left: 46px;
    }
    @include mediaQ(SP) {
        font-size: 22px; padding: 0 0 2px 30px;
    }

    &::before, &::after {
        content: "";display: block;left: 0;position: absolute;top: 50%;top: calc(50% + 1px);
        @include mediaQ(PC) {
            width: 28px;
        }
        @include mediaQ(SP) {
            width: 20px;
        }
    }

    &::before {
        -webkit-transform: translateY(-50%);background: #005598;transform: translateY(-50%);
        @include mediaQ(PC) {
            padding-bottom: 28px;
        }
        @include mediaQ(SP) {
            height: 20px;
        }
    }

    &::after {
        background: #2D8EDB;top: calc(50% - 13px);
        @include mediaQ(PC) {
            padding-bottom: 14px;
        }
        @include mediaQ(SP) {
            height: 10px;
        }
    }
}

@mixin my_form_css(){
    input[type=number], input[type=text], input[type=tel], input[type=email], input[type="password"] {
        border-radius: 0.25rem;display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: 0.375rem 0.8rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    textarea {
        border-radius: 0.25rem;display: block;width: 100%;padding: 0.375rem 0.8rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}

@mixin form_bg_color{
    background-color: #F2F7FA;
}

.pg_checkout {

    fieldset {
        border: none;
    }

    .bl_flow {
        .bl_form_flow {
            margin: 75px auto 40px;
            width: 777px;

            @include mediaQ(SP) {
                margin: 60px auto 30px;
                width: 100%;
            }

            &:before {
                @include mediaQ(SP) {
                    left: 10%;
                    width: 88%;
                }
            }

            &_item {
                font-size: 14px;

                &.un_flow_item_01 {
                    width: percentage(math.div(80, 777));

                    @include mediaQ(SP) {
                        width: percentage(math.div(70, 330));
                    }
                }
                &.un_flow_item_02 {
                    width: percentage(math.div(96, 777));

                    @include mediaQ(SP) {
                        width: percentage(math.div(56, 330));
                    }
                }
                &.un_flow_item_03 {
                    width: percentage(math.div(112, 777));

                    @include mediaQ(SP) {
                        width: percentage(math.div(56, 330));
                    }
                }
                &.un_flow_item_04 {
                    width: percentage(math.div(32, 777));

                    @include mediaQ(SP) {
                        width: percentage(math.div(28, 330));
                    }
                }
            }
        }
    }

    .ly_inner {
        & > .el_txt {
            margin-bottom: 20px;
        }
    }

    .woocommerce .col2-set,
    .woocommerce-page .col2-set {
        margin-bottom: 60px;

        @include mediaQ(SP) {
            margin-bottom: 40px;
        }
    }

    #add_payment_method #payment,
    .woocommerce-cart #payment,
    .woocommerce-checkout #payment {
        @include form_bg_color;
    }
    #add_payment_method #payment div.payment_box,
    .woocommerce-cart #payment div.payment_box,
    .woocommerce-checkout #payment div.payment_box {
        @include form_bg_color;

        @include mediaQ(SP) {
            padding: 0;
        }

        .form-row {
            @include mediaQ(SP) {
                margin-bottom: 0;
            }
        }
    }
    #add_payment_method #payment div.payment_box::before,
    .woocommerce-cart #payment div.payment_box::before,
    .woocommerce-checkout #payment div.payment_box::before {
        border: none;
        @include form_bg_color;
    }

    #add_payment_method #payment ul.payment_methods,
    .woocommerce-cart #payment ul.payment_methods,
    .woocommerce-checkout #payment ul.payment_methods {
        border: none;

        @include mediaQ(SP) {
            padding-top: 0;
        }
    }

    #wc-stripe-cc-form .form-row {
        width: 100%;
        display: flex;
        padding-left: 15px;

        @include mediaQ(SP) {
            display: block;
        }

        label {
            width: 27.24%;
            font-weight: bold;
            font-size: 18px;

            @include mediaQ(SP) {
                width: 100%;
            }
        }

        .stripe-card-group,
        .wc-stripe-elements-field {
            width: 72.76%;

            @include mediaQ(SP) {
                width: 100%;
            }
        }

        .stripe-card-group {
            #stripe-card-element {
                width: 327px;

                @include mediaQ(SP) {
                    width: 100%;
                }
            }

            .stripe-card-brand {
                display: none;
            }

            .un_img_wrap {
                width: 300px;
                height: 50px;
                background-color: #fff;
                display: flex;
                justify-content: space-around;

                @include mediaQ(SP) {
                    width: 100%;
                }

                img {
                    display: inline-block;
                }
            }

            .un_img_text {
                font-size: 14px;
                line-height: 1.85;
                margin-top: 5px;
            }
        }
    }

    #payment {
        padding-bottom: 30px;
        margin-bottom: 50px;

        @include mediaQ(SP) {
            padding-bottom: 17px;
            margin-bottom: 40px;
        }

        .wc_payment_methods {
            .payment_method_stripe {
                margin: 0;
                padding-top: 4px;

                .wc-stripe-cc-form {
                    margin-top: 6px;
                }
            }
        }
        .place-order {
            margin-bottom: 0;
            .woocommerce-terms-and-conditions-wrapper {
                .woocommerce-privacy-policy-text {
                    background: #e7eff4;
                    width: 1019px;
                    margin: 0 auto;
                    font-size: 13px;
                    line-height: 2;
                    padding: 10px 20px;
                    color: #222222;

                    @include mediaQ(SP) {
                        width: 100%;
                        padding: 20px 24px;
                    }

                    a {
                        color: #222222;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    #order_review {
        .order {

            .shop_table_wrap {
                @include mediaQ(SP) {
                    overflow-x: auto;
                }
            }

            .shop_table {
                margin-bottom: 15px;

                @include mediaQ(SP) {
                    width: 500px;
                }

                th {
                    text-align: center;
                    background: #f2f7fa;
                    border-right: solid 1px #CCC;
                    font-weight: normal;
                    padding: 12px;
                    font-size: 14px;

                    &:last-child {
                        border-right: 0;
                    }

                    @include mediaQ(SP) {
                        text-align: left;
                    }
                }

                .product-name-txt {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 2;
                    padding-bottom: 5px;
                }

                .woocommerce-cart-form__cart-item {
                    .product-name {
                        padding: 6px 18px;
                        border-right: solid 1px #CCC;

                        .variation {
                            border-top: dashed 1px #ccc;

                            .variation- {
                                table {
                                    width: 100%;
                                }
                                tr:first-of-type {
                                    td {
                                        padding-top: 13px;
                                    }
                                }
                                td {
                                    font-size: 14px;
                                    color: #222222;
                                    border: none;
                                    padding-top: 4px;
                                    padding-bottom: 6px;

                                    &:first-child {
                                        width: 30% !important;
                                    }

                                    &:last-child {
                                        width: 70% !important;
                                    }

                                    strong,
                                    span {
                                        font-weight: normal !important;
                                        color: #222222 !important;
                                    }
                                }

                                p {
                                    font-size: 14px;
                                    color: #222222;
                                    border: none;
                                    font-weight: normal;
                                    padding-left: 35px;
                                    padding-top: 10px;

                                    @include mediaQ(SP) {
                                        padding-left: 0;
                                        padding-top: 5px;
                                    }
                                }
                            }
                        }
                    }

                    .product-price,
                    .product-quantity {
                        border-right: solid 1px #CCC;
                    }
                }

                .order-total {
                    display: none;
                }
            }

            .un_shop_table_total_wrap {
                display: flex;
                justify-content: flex-end;

                .un_shop_table_total {
                    display: flex;
                    justify-content: space-between;
                    width: 570px;
                    border-bottom: solid 1px #CCC;
                    padding-bottom: 8px;

                    @include mediaQ(SP) {
                        padding-bottom: 3px;
                    }

                    .un_shop_table_total_title {
                        font-size: 16px;
                        line-height: 2;
                    }
                    .un_shop_table_total_body {
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 1.6;
                    }
                }
            }
        }

        #place_order {
            width: 380px;
            margin: 0 auto;
            display: block;
            font-size: 24px;
            font-weight: bold;
            position: relative;
            margin-top: 40px;
            line-height: 2.75;

            @include mediaQ(SP) {
                width: 100%;
                margin-top: 30px;
                line-height: 2.25;
                font-size: 20px;
            }

            &:after {
                content: '';
                width: 15px;
                height: 15px;
                border: 0;
                border-top: solid 2px #FFF;
                border-right: solid 2px #FFF;
                position: absolute;
                top: 50%;
                right: 37px;
                transform: rotate(45deg) translate(0, -70%);

                @include mediaQ(SP) {
                    height: 10px;
                    width: 10px;
                    right: 26px;
                    transform: rotate(45deg) translate(0, -80%);
                }
            }
        }
    }

    .woocommerce {

        .woocommerce-message {
            padding: 19px 25px;
            margin-bottom: 40px;

            @include mediaQ(SP) {
                line-height: 1.7;
            }
        }

        .bl_ttl_lv2 {
            @include bl_ttl_lv2__02;
        }

        .woocommerce-form-login-toggle {
            .woocommerce-message {
                border: 1px solid #ccc;
                background-color: $white;
                font-size: 16px;

                &::before {
                    content: none;
                }
                .showlogin {
                    text-decoration: underline;
                    &:hover {
                        color: $hv_lightblue;
                    }
                }
            }
        }

        // .woocommerce-form-login {

        //     .woocommerce-form-login__rememberme {
        //         display: block;
        //     }

        //     .woocommerce-form-login__submit {
        //         margin-top: 18px;
        //     }
        // }

        form {

            .hp_h165 {
                height: 165px !important;
            }

            .hp_w230 {
                width: 230px !important;

                @include mediaQ(SP) {
                    width: 100% !important;
                }
            }
            .hp_w327 {
                width: 327px !important;
            }

            .hp_mb0 {
                margin-bottom: 0 !important;
            }

            .bl_checkout_addWrap {
                display: flex;
                padding: 0 3px;
                margin-bottom: 18px;

                @include mediaQ(SP) {
                    display: block;
                }

                &_child {
                    width: 73%;

                    @include mediaQ(SP) {
                        width: 100%;
                        margin-top: 5px;
                    }

                    .form-row {
                        margin-bottom: 30px;
                        padding: 0 3px;

                        @include mediaQ(SP) {
                            margin-bottom: 21px;
                        }
                    }
                }
            }
            .un_countryTitle {
                font-size: 18px;
                font-weight: bold;
                width: 27%;

                @include mediaQ(SP) {
                    width: 100%;
                }

                .un_countryTitle_required {
                    display: inline-block;
                    margin-left: 6px;
                    padding: 3px;
                    font-size: 10px;
                    font-weight: bold;
                    line-height: 1;
                    text-align: center;
                    border-radius: 2px;
                    border: 1px solid #FF6565!important;
                    color: #FF6565;
                    width: 37px;
                    text-decoration: none;

                    @include mediaQ(PC) {
                        display: block;
                        margin-left: 0;
                        margin-top: 3px;
                    }
                }
            }


            .select2-container--default .select2-selection--single {
                height: auto;

                .select2-selection__rendered {
                    line-height: 50px;
                    font-size: 16px;
                    padding-left: 20px;
                }

                .select2-selection__arrow {
                    b {
                        width: 7px;
                        height: 7px;
                        border: 0;
                        border-bottom: solid 1px #333;
                        border-right: solid 1px #333;
                        left: 6px;
                        margin-top: 0;
                        transform: rotate(45deg) translate(0, 130%);
                    }
                }
            }


            .col2-set .col-1{
                width: 100%;

                .woocommerce-billing-fields__field-wrapper {
                    background-color: $_bg_blue;
                    padding: 35px;

                    @include mediaQ(SP) {
                        padding: 30px 15px;
                    }

                    // .wooccm-field-last_name label, .wooccm-field-phone label, .wooccm-field-email label, .woocommerce-account-fields label, .wooccm-field-company label, .wooccm-field-wooccm11 label{
                    .woocommerce-account-fields, .woocommerce-account-fields .create-account{
                        width: 100%;
                    }
                    .wooccm-field-last_name, .wooccm-field-phone, .wooccm-field-email, .woocommerce-account-fields .form-row, .wooccm-field-company, .wooccm-field-wooccm11, .wc-credit-card-form.wc-payment-form {
                        display: flex;
                        width: 100%;

                        @include mediaQ(SP) {
                            display: block;
                        }

                        label:first-child {
                            font-size: 18px;
                            font-weight: bold;
                            width: 27%;

                            @include mediaQ(SP) {
                                width: 100%;
                            }
                        }

                        span.woocommerce-input-wrapper {
                            width: 73%;
                            @include mediaQ(SP) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            // &.login {
            //     margin-top: 14px;
            //     padding: 19px 20px 19px 22px;
            //     border: none;
            //     border-radius: unset;
            //     background: #f8f8f8;

            //     @include mediaQ(SP) {
            //         margin-bottom: 43px;
            //     }
            // }

            // &.register {
            //     margin-top: 14px;
            //     padding: 19px 20px 19px 22px;
            //     border: none;
            //     border-radius: unset;
            //     background: #f8f8f8;

            //     @include mediaQ(SP) {
            //         margin-bottom: 43px;
            //     }
            // }

            .form-row {
                margin: 0 0 45px;

                @include mediaQ(SP) {
                    margin: 0 0 20px;
                }

                .bl_checkout_noticeTxt {
                    display: inline-block;
                    margin-bottom: 10px;
                }

                .woocommerce-account-fields {
                    .form-row {
                        margin: 0 0 5px;
                    }
                }

                & label {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 4px;
                    line-height: 1.5;

                    .required {
                        display: inline-block;
                        margin-left: 6px;
                        padding: 3px;
                        font-size: 10px;
                        font-weight: bold;
                        line-height: 1;
                        text-align: center;
                        border-radius: 2px;
                        border: 1px solid #FF6565!important;
                        color: #FF6565;
                        width: 37px;
                        @include mediaQ(PC) {
                            display: block;
                            margin-left: 0;
                            margin-top: 3px;
                        }
                    }
                    .optional {
                        visibility: hidden;
                    }
                }

                & .bl_checkout_width .select2 {
                    max-width: 327px;
                    // width: 327px;
                    @include mediaQ(SP) {
                        max-width: 100%;
                    }
                }

                & input.input-text {
                    font-size: 16px;
                    height: 50px;
                    border: solid 1px #A3A3A3;
                }
                textarea {
                    border: solid 1px #A3A3A3;
                }

                & input[name="billing_last_name"], input[name="billing_phone"], input[name="billing_email"], .wc-stripe-elements-field {
                    max-width: 327px;
                    height: 50px;
                    width: 327px;
                    border: 1px solid #A3A3A3;
                    @include mediaQ(SP) {
                        max-width: 100%;
                    }
                }

                & input[name="billing_email"], input[name="billing_company"], textarea[name="billing_wooccm11"], .wc-stripe-elements-field {
                    width: 100%;
                }

                // .woocommerce-form-login__rememberme {
                //     margin-top: -4px;

                //     .woocommerce-form__input-checkbox {
                //         margin-right: 5px;
                //     }
                // }

                &.woocommerce-invalid {
                    label {
                        color: #000000;
                    }

                    .select2-container,
                    input.input-text,
                    select {
                        border: solid 1px #A3A3A3;
                    }
                }
                &.woocommerce-validated {
                    .select2-container,
                    input.input-text,
                    select {
                        border: solid 1px #A3A3A3;
                    }
                }

            }

            .password-input {
                margin-bottom: -6px;
            }
        }

        .button {
            background: $blue !important;
            color: $white !important;
            font-size: 16px;
            margin-right: 10px;
            padding: 8px 20px;

            &:hover {
                opacity: 0.8;
            }
        }

        button.button {
            // font-size: 16px;
            padding: 12px 40px;
            // background: $blue;
            // color: $white;
        }

    }

    // & .woocommerce {
    //     // display: flex;
    //     // justify-content: flex-start;
    //     // align-items: flex-start;

    //     // @include mediaQ(SP) {
    //     //     flex-direction: column;
    //     // }


    //     .button {
    //         background: #005598 !important;
    //         color: #fff !important;
    //         font-size: 16px;
    //         margin-right: 10px;
    //         padding: 8px 20px;

    //         &:hover {
    //             opacity: 0.8;
    //         }
    //     }
    // }


    & .woocommerce-LostPassword.lost_password a {
        font-size: 16px;
        color: $hv_blue;
    }

    & .woocommerce-privacy-policy-link {
        font-size: 14px;
        text-decoration: solid;
        color: $hv_blue;
    }

    & .woocommerce-privacy-policy-text {
        font-size: 14px;
        margin-top: 30px;
        margin-bottom: 10px;
        line-height: 1.7;

        @include mediaQ(SP) {
            margin-bottom: 16px;
        }
    }

    .woocommerce form .show-password-input,
    .woocommerce-page form .show-password-input {
        right: 14px;
        top: 56%;
        transform: translate(0, -50%);
        display: none;

        &::after {
            display: inline-block;
            width: 24px;
            height: 24px;
            content: "";
            background: url("/assets/img/icon_eye.svg");
            background-size: 24px 24px;
        }
    }

    .woocommerce form .form-row.woocommerce-validated .select2-container,
    .woocommerce form .form-row.woocommerce-validated select {
        border: none;
    }

}



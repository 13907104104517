
/////////////////////////////////////////
//　　汎用カラー
/////////////////////////////////////////

$black       : #222222;
$ft_gray     : #F6F6F6;
$gray        : #707070;
$nav_gray    : #7C7C7C;
$white       : #ffffff;
$blue        : #005598;
$green       : #00B160;
$hv_blue     : #2D8EDB;
$hv_lightblue: #76C2FF;
$_bg_blue    : #F2F7FA;
// $orange      : #FDA10D;
$orange      : #F27900;
$red         : #b02c2f;
$right_red   : #ee6d70;


/////////////////////////////////////////
//　　フォント
/////////////////////////////////////////
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap');

/////////////////////////////////////////
//　　サイズ
/////////////////////////////////////////

// ヘッダー高さ
$heightHeader   : 97px;
$heightHeaderSP : 60px;

// コンテンツ幅
$widthContent      : 1100px;
$maxWidthContent: $widthContent + 40px;
$widthContentForm  : 320px;
$widthContent2Col  : 790px;


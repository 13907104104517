@use "sass:math";
@import "mixin";

.pg_faq {
	.bl_faq#bl_faq {

		.wpsm_panel-group {
			.wpsm_panel-default > {
				.wpsm_panel-heading {
					border-color: #ccc!important;
					border-style: solid;
					border-width: 1px;
					border-radius: 0px!important;
					padding: 10px 15px 10px 40px;
					.wpsm_panel-title {
						position: relative;
						&::before {
							color: $hv_blue;
							content: "Q.";
							display: block;
							font-family: "Roboto", sans-serif;
							font-size: 36px;
							font-size: clamp(32px, 3.2727272727vw, 36px);
							left: -0.5em;
							position: absolute;
							transition: 0.7s ease;
						}
	
						span.ac_open_cl_icon  {
							float: right !important;
						}
						.ac_title_class {
							font-size: 18px;
							font-size: clamp(15px, 1.6363636364vw, 18px);
							font-weight: 500;
							font-family: 'Noto Sans JP', sans-serif;
							background-color:$white;
							color: $black;
						}
					}
				}
	
				.wpsm_panel-collapse  {
					border: 1px solid #ccc!important;
					border-top: 0;
					// background-color: #ccc!important;
	
					.wpsm_panel-body {
						background-color: #F8F8F8!important;
						border: 0!important;
						font-size: 18px;
						font-size: clamp(15px, 1.6363636364vw, 18px);
						font-weight: 500;
						font-family: 'Noto Sans JP', sans-serif;
						position: relative;

						&::before {
							display:block;
							position:absolute;
							left: 0.6em;
							top: 2px;
							content: "A.";
							color: $orange;
							font-family: 'Roboto', sans-serif;
							@include pcFS(36px, $widthContent, 32px);
							transition:0.7s ease;
							@include mediaQ(SP) {
								left:-1.3em;
							}
						}
					}
				}

			}
			
			
		}
		
	}
}

@use "sass:math";
@import "mixin";

.pg_topics {
	.el_txt p {
		margin-bottom: 20px;
	}

	strong {
		font-weight: 900;
	}

	.bl_breadClumb {
		
		@include mediaQ(PC) {
			$mb: 39px;
			margin-bottom: $mb;
			margin-bottom: clamp(0px, per($mb, $widthContent), $mb);
		}
		// @include mediaQ(SP) {
		// 	margin-bottom: 26px;
		// }
	}

	.el_contentTtl {
		
		@include mediaQ(PC) {
			@include pcFS(34px);
			margin-bottom: 55px;
			margin-bottom: clamp(0px, per(55px, $widthContent), 55px);
		}
		@include mediaQ(SP) {
			margin-bottom: 48px;
		}
	}
    .bl_topics {
		$popular: '.bl_topics__popular';

		&#{$popular} {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center bottom;
			padding-bottom: 30px;
			@include mediaQ(PC) {
				width: 100%;
				background-image: url("/assets/img/topics/bg_popular.jpg");
				padding-bottom: clamp(0px, per(30px, $widthContent), 30px);
				margin-bottom: 80px;
				margin-bottom: clamp(0px, per(80px, $widthContent), 80px);
	
			}
			@include mediaQ(SP) {
				background-image: url("/assets/img/topics/bg_popular_sp.jpg");
				margin-bottom: 50px;
			}
		}

		&_category {
			background: #F8F8F8;
			@include mediaQ(PC) {
				@include pcPAD_P((27px 30px));
				margin-bottom: 40px;
					margin-bottom: clamp(0px, per(40px, $widthContent),40px);
			}
			@include mediaQ(SP) {
				padding: 22px 16px 16px;
				margin-bottom: 26px;
			}

			&_txt {
				font-weight: bold;
				@include mediaQ(PC) {
					@include pcFS(20px);
					margin-bottom: 15px;
					margin-bottom: clamp(0px, per(15px, $widthContent), 15px);

				}
				@include mediaQ(SP) {
					font-size: 18px;
					margin-bottom: 10px;
				}
			}
			

			&_container {
				display: flex;
				flex-wrap: wrap;
				&_list {

					&:not(:last-child) {
						margin-right: 10px;  
						@include mediaQ(SP) {
							margin-bottom: 10px;  
						}
					}
					&_link {
						color: $white;
						font-weight: bold;
						background-color: #999;
						padding: 4px 21px;
						display: block;
						border-radius: 20px;
						font-size: 12px;
						// @include mediaQ(SP) {
						// 	&:not(:last-child) {
						// 		margin-bottom: 10px;  
						// 	}
						// }
						&.category_all.is_active {
							background: $blue;
						}
						&.travel-china.is_active {
							background: $hv_blue;
						}
						&.it-china.is_active {
							background: $green;
						}
						&.joytel-service.is_active {
							background: $orange;
						}
					}
				}
			}
		}
		

        &_list {
            display: flex;
            // justify-content: space-between;
            justify-content: flex-start;

            @include mediaQ(PC) {
                margin-bottom: per(45px, $widthContent);
				flex-wrap: wrap;
            }
            @include mediaQ(SP) {
                display: block;
                margin-bottom: 27px;
            }

            &_item {
                width: 31%;

				&:not(:last-child) {
					margin-bottom: 25px;  
				}
				@include mediaQ(PC) {
					&:not(:nth-of-type(3n)) {
						margin-right: 3.5%;  
					}
				}
                @include mediaQ(SP) {
                    width: 100%;
                    &:not(:last-child) {
                        margin-bottom: 23px;  
                    }
                }

                &_link {
                    // transition: .3s;
                    &:hover {
                        opacity: .7;
                    }
                }

				&_img {
                    @include mediaQ(PC) {
                        margin-bottom: per(40px, $widthContent);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 5px;
                    }
                    img {
                        width: 100%;
                        border: 1px solid #ccc;
                        min-height: 227px;
                        object-fit: cover;
						max-height: 24vh;

                        @include mediaQ(SP) {
                            min-height: 222px;
                        }
                    }
                }

                &_date {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 4px;
                    display: block;
                    @include mediaQ(SP) {
                        margin-bottom: 7px;
                    }
                }

                &_plan {
                    
                    color: $white;
                    font-size: 12px;
                    font-weight: bold;
                    border-radius: 20px;
                    display: inline-block;
                    padding: 4px 14px;
                    line-height: 1;
					letter-spacing: 0.02rem;

                    @include mediaQ(PC) {
                        margin-bottom: per(35px, $widthContent);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 12px;
                        padding: 4px 12px;
                    }

                    &.travel-china {
                        background-color: $hv_blue;
                    }
                    &.it-china {
                        background-color: $green;
                    }
                    &.joytel-service {
                        background-color: $orange
					}

                }

                &_txt {
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: -0.01rem;
                }
            }
        }

    }
}

@use "sass:math";
@import "mixin";

.pg_service {
    $body: &;
    &_bottomBg {
        @include mediaQ(PC) {
            @include pcPAD_P((0 0 80px 0));
        }
        @include mediaQ(SP) {
            padding-bottom: 40px;
        }
		&::before {
			@include mediaQ(PC) {
                $top: -365px;
				top: $top;
				top: clamp($top, convVW($top, $maxWidthContent), 0px);
			}
			@include mediaQ(SP) {
                top: convVW(-260px, 375px);
			}
		}
    }
    .un_plan {
        &_lead {
            @include mediaQ(PC) {
                @include pcMGN_P((0 0 40px));
            }
            @include mediaQ(SP) {
                margin-bottom: 47px;
            }
        }
        &_recommend {
            $recommend: ".un_plan_recommend";
            &:not(:last-of-type) {
                @include mediaQ(PC) {
                    @include pcMGN_P((0 0 175px));
                }
                @include mediaQ(SP) {
                    margin-bottom: 120px;
                }
            }
            &_head {
                @include mediaQ(PC) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @include pcMGN_P((0 0 42px));
                }
                @include mediaQ(SP) {
                    margin-bottom: 30px;
                }
                &_txt {
                    @include mediaQ(PC) {
                        width: per(540px, $widthContent);
                    }
                    @include mediaQ(SP) {
                        margin-bottom: 20px;
                    }
                    $parentPC: 540px;
                    &_ttl {
                        $recTtl: #{$recommend}_head_txt_ttl;
                        display: flex;
                        font-weight: bold;
                        @include mediaQ(PC) {
                            font-size: 28px;
                            // @include pcFS(28px);
                            @include pcMGN_P((3px 0 14px 2px), $parentPC);
                        }
                        @include mediaQ(SP) {
                            font-size: 24px;
                            line-height: math.div(38px, 24px);
                            margin-bottom: 13px;
                        }
                        &::before {
                            content: "";
                            display: block;
                        }
                        &#{$recTtl}__travel {
                            &::before {
                                background: url("/assets/img/icon_airplane.svg") no-repeat 0 0 / contain;
                                @include mediaQ(PC) {
                                    width: per(36px, $parentPC);
                                    @include pcMGN_P((0 10px 0 0), $parentPC);
                                }
                                @include mediaQ2(769, 1000) {
                                    @include pcMGN_P((10px 5px 0 0), $parentPC);
                                }
                                @include mediaQ(SP) {
                                    flex: 0 0 33px;
                                    margin-right: 16px; 
                                }
                            }
                        }
                        &#{$recTtl}__stay {
                            &::before {
                                background: url("/assets/img/icon_bag.svg") no-repeat 0 0 / contain;
                                @include mediaQ(PC) {
                                    width: per(35px, $parentPC);
                                    @include pcMGN_P((2px 13px 0 0), $parentPC);
                                }
                                @include mediaQ2(769, 1000) {
                                    @include pcMGN_P((10px 10px 0 0), $parentPC);
                                }
                                
                                @include mediaQ(SP) {
                                    flex: 0 0 30px;
                                    margin-right: 14px; 
                                }
                            }
                        }
                    }
                    &_lead {
                        line-height: math.div(32px, 16px);
                        font-size: 16px;
                        @include mediaQ(PC) {
                            // @include pcFS(16px);
                            $pr: 15px;
                            padding-right: $pr;
                            padding-right: per($pr, $parentPC);
                        }
                        // @include mediaQ(SP) {
                        //     font-size: 16px;
                        // }
                    }
                }
                &_img {
                    @include mediaQ(PC) {
                        width: per(530px, $widthContent);
                    }
                    img {
                        @include mediaQ(SP) {
                            max-width: none;
                            width: 100%;
                        }
                    }
                }
            }
            &_feature {
                $recFeature: #{$recommend}_feature;
                &_item {
                    $recFeatureItem: #{$recFeature}_item;
                    overflow: hidden;
                    position: relative;
                    background: $white;
                    border: solid 1px #ccc;
                    @include mediaQ(PC) {
                        @include pcPAD_P((38px 112px 46px));
                    }
                    @include mediaQ(SP) {
                        padding: 20px per(15px, 335px) 30px;
                    }
                    &:not(:last-of-type){
                        @include mediaQ(PC) {
                            @include pcMGN_P((0 0 30px 0));
                        }
                        @include mediaQ(SP) {
                            margin-bottom: 20px;
                        }
                    }
                    &#{$recFeatureItem}__ptl {
                        @include mediaQ(SP) {
                            padding-top: 38px;
                        }
                    }
                    &_head {
                        $recFeatureItemHead: #{$recFeatureItem}_head;
                        position: relative;
                        $parentW: 874px;
                        @include mediaQ(PC) {
                            $mb: 24px;
                            $pr: 40px;
                            @include pcPAD_P((0 $pr 0 206px), $parentW);
                            margin-bottom: $mb;
                            margin-bottom: per($mb, $parentW);
                        }
                        @include mediaQ(SP) {
                            margin-bottom: 15px;
                        }
                        $headWifiRental: #{$recFeatureItemHead}__wifiRental;
                        $headSim: #{$recFeatureItemHead}__sim;
                        $headWifiHome: #{$recFeatureItemHead}__wifiHome;
                        $headMobile: #{$recFeatureItemHead}__mobile;
                        &#{$headWifiRental} {
                            @include mediaQ(PC) {
                                $pl: 203px;
                                padding-left: $pl;
                                padding-left: per($pl ,874px);
                            }
                        }
                        &_ttl {
                            $recFeatureItemTtl: #{$recFeatureItemHead}_ttl;
                            font-weight: bold;
                            font-size: 28px;
                            @include mediaQ(PC) {
                                // @include pcFS(28px);
                                @include pcPAD_P((8px 0 10px), 628px);
                            }
                            @include mediaQ(SP) {
                                font-size: 24px;
                                text-align: center;
                                line-height: 1.3;
                                margin-bottom: 16px;
                            }
                            @at-root {
                                #{$body} {
                                    #{$recFeatureItemHead} {
                                        &#{$headMobile} {
                                            #{$recFeatureItemTtl}{
                                                @include mediaQ(PC) {
                                                    $mt: -4px;
                                                    margin-top: $mt;
                                                    margin-top: per($mt, 628px);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &::after {
                                $recFeatureItemTtlAfter: #{$recFeatureItemTtl}+"::"+after;
                                content: "";
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-repeat: no-repeat;
                                background-position: center center;
                                @include mediaQ(PC) {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: per(206px, 874px);
                                    height: 100%;
                                }
                                @include mediaQ(SP) {
                                    padding-bottom: 65px;
                                    margin-top: 13px;
                                    background-size: contain;
                                }
                                @at-root {
                                    #{$body} {
                                        #{$recFeatureItemHead} {
                                            &#{$headWifiRental} {
                                                #{$recFeatureItemTtlAfter} {
                                                    background-image: url("/assets/img/icon_wifi2.svg");
                                                    @include mediaQ(PC) {
                                                        background-size: per(127px, 206px) auto;
                                                    }
                                                    @include mediaQ(SP) {
                                                        margin-right: per(22px, 303px);
                                                    }
                                                }
                                            }
                                            &#{$headSim} {
                                                #{$recFeatureItemTtlAfter} {
                                                    background-image: url("/assets/img/icon_sim.svg");
                                                    @include mediaQ(PC) {
                                                        background-size: per(60px, 206px) auto;
                                                        $ml: 5px;
                                                        margin-left: $ml;
                                                        margin-left: per($ml, 874px);
                                                    }
                                                    @include mediaQ(SP) {
                                                        padding-bottom: 70px;
                                                    }
                                                }
                                            }
                                            &#{$headWifiHome} {
                                                #{$recFeatureItemTtlAfter} {
                                                    background-image: url("/assets/img/icon_home.svg");
                                                    @include mediaQ(PC) {
                                                        background-size: per(86px, 206px) auto;
                                                        $ml: 6px;
                                                        margin-left: $ml;
                                                        margin-left: per($ml, 874px);
                                                    }
                                                    @include mediaQ(SP) {
                                                        padding-bottom: 72px;
                                                    }
                                                }
                                            }
                                            &#{$headMobile} {
                                                #{$recFeatureItemTtlAfter} {
                                                    background-image: url("/assets/img/icon_wifi1.svg");
                                                    @include mediaQ(PC) {
                                                        background-size: per(156px, 206px) auto;
                                                    }
                                                    @include mediaQ(SP) {
                                                        padding-bottom: 97px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &#{$recFeatureItemTtl}__recommendTxt {
                                @include mediaQ(PC) {
                                    display: flex;
                                    flex-direction: row-reverse;
                                    justify-content: flex-end;
                                    align-items: center;
                                }
                                @include mediaQ(SP) {
                                    line-height: 1.2;
                                }
                                &::before {
                                    content: "おすすめ";
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: $white;
                                    background: $orange;
                                    font-size: 17px;
                                    @include mediaQ(PC) {
                                        @include pcFS(17px);
                                        font-weight: bold;
                                        @include pcPAD_P((6px, 22px, 5px), 666px);
                                        margin-left: 20px;
                                        margin-left: per(20px, 666px);
                                    }
                                    @include mediaQ2(769, 1000) {
                                        margin-left: per(10px, 666px);
                                        min-width: 92px;
                                    }
                                    @include mediaQ(SP) {
                                        display: block;
                                        width: 78px;
                                        font-size: 12px;
                                        text-align: center;
                                        padding: 2px 0;
                                        margin: 4px auto 9px;
                                    }
                                }
                            }
                        }
                        &_lead {
                            $recFeatureItemLead: #{$recFeatureItemHead}_lead;
                            font-weight: normal;
                            @include mediaQ(PC) {
                                $fz: 18px;
                                // @include pcFS($fz);
                                font-size: $fz;
                                line-height: math.div(32px, $fz);
                                $mt: -3px;
                                margin-top: $mt;
                                margin-top: per($mt, 874px);
                            }
                            @include mediaQ(SP) {
                                width: per(285px, 305px);
                                $fz: 14px;
                                font-size: $fz;
                                line-height: math.div(24px, $fz);
                                text-align: center;
                                margin: 0 auto;
                            }
                            @at-root {
                                #{$body} {
                                    #{$recFeatureItemHead} {
                                        &#{$headWifiRental} {
                                            #{$recFeatureItemLead} {
                                                @include mediaQ(PC) {
                                                    margin-top: 0;
                                                }
                                            }
                                        }
                                        &#{$headWifiHome}, &#{$headMobile} {
                                            #{$recFeatureItemLead} {
                                                @include mediaQ(PC) {
                                                    line-height: math.div(37px, 18px);
                                                }
                                            }
                                        }
                                        &#{$headMobile} {
                                            #{$recFeatureItemLead} {
                                                @include mediaQ(PC) {
                                                    $mt: -5px;
                                                    margin-top: $mt;
                                                    margin-top: per($mt, 874px);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &_txt {
                                @include mediaQ(SP) {
                                    display: inline-block;
                                    text-align: left;
                                }
                            }
                        }
                    }
                    &_place {
                        $recFeatureItemPlace: #{$recFeatureItem}_place;
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: translate(-50%, -50%) rotate(-45deg);
                        @include mediaQ(PC) {
                            width: per(193px, $widthContent);
                            padding-bottom: per(193px, $widthContent);
                        }
                        @include mediaQ(SP) {
                            width: 110px;
                            padding-bottom: 110px;
                        }
                        &#{$recFeatureItemPlace}__jp {
                            background-color: $hv_blue;
                        }
                        &#{$recFeatureItemPlace}__ch {
                            background-color: $blue;
                        }
                        &_txt {
                            position: absolute;
                            color: $white;
                            font-weight: 900;
                            text-align: center;
                            @include mediaQ(PC) {
                                // font-size: 18px;
                                @include pcFS(18px);
                                bottom: per(10px, 193px);
                            }
                            @include mediaQ(SP) {
                                font-size: 11px;
                                line-height: 1.3;
                                bottom: 6px;
                            }
                        }
                    }
                    &_desc {
                        counter-reset: num;
                        background: #F2F7FA;
                        @include mediaQ(PC) {
                            $parent: 874px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            @include pcPAD_P((25px 58px 24px 30px), $parent);
                            margin-bottom: 31px;
                            margin-bottom: per(31px, $parent);
                        }
                        @include mediaQ(SP) {
                            padding: 20px 0;
                            margin-bottom: 30px;
                        }
                        &_ttl {
                            color: $black;
                            font-weight: bold;
                            width: 100%;
                            text-align: center;
                            @include mediaQ(PC) {
                                font-size: 22px;
                                // @include pcFS(22px);
                                $parentW: 874px - 58px - 30px;
                                $pl: 28px;
                                padding-left: $pl;
                                padding-left: per($pl, $parentW);
                                @include pcMGN_P((0 0 22px), $parentW);
                            }
                            @include mediaQ(SP) {
                                font-size: 20px;
                                margin-bottom: 20px;
                            }
                        }
                        &_cont {
                            position: relative;
                            display: flex;
                            @include mediaQ(PC) {
                                $parentW: 874px - 58px - 30px;
                                width: per(378px, $parentW);
                                font-size: 16px;
                                // @include pcFS(16px);
                                font-weight: 500;
                                line-height: math.div(28px, 16px);
                                &:nth-of-type(-n+2){
                                    $mb: 16px;
                                    margin-bottom: $mb;
                                    margin-bottom: per($mb, $parentW);
                                }
                            }
                            @include mediaQ(SP) {
                                font-size: 14px;
                                line-height: math.div(26px, 14px);
                                padding: 0 per(20px, 305px);
                                &:not(:last-of-type) {
                                    margin-bottom: 16px;
                                }
                            }
                            &::before {
                                counter-increment: num;
                                content: counter(num, decimal-leading-zero);
                                font-family: 'Roboto', sans-serif;
                                color: $hv_blue;
                                font-weight: bold;
                                line-height: 1;
                                font-size: 26px;
                                @include mediaQ(PC) {
                                    // @include pcFS(26px);
                                    padding-top: 0.2em;
                                    margin-right: per(14px, 378px);
                                }
                                @include mediaQ(SP) {
                                    // font-size: 26px;
                                    padding-top: 0.01em;
                                    margin-right: 12px;
                                }
                            }
                        }
                    }
                    &_link {
                        &Wrap {
                            @include mediaQ(SP) {
                                width: per(250px, 305px);
                            }
                        }
                    }
                }
            }
        }
    }
    .un_otherService {
        @include mediaQ(PC) {
            @include pcPAD_P(75px 0 70px);
        }
        @include mediaQ(SP) {
            padding: 33px 0 40px;
        }
        &_list {
            @include mediaQ(SP) {
                padding-top: 4px;
            }
            &_item {
                @include mediaQ(PC) {
                    width: per(340px, $widthContent);
                    padding-bottom: 80px;
                    padding-bottom: clamp(0px, convVW(80px, $maxWidthContent), 80px);
                }
                @include mediaQ(SP) {
                    width: 100%;
                    height: 70px;
                    &:not(:last-of-type) {
                        margin-bottom: 20px;
                    }
                }
            }
            &_link {
                text-align: center;
                width: 100%;
                @include mediaQ(PC) {
                    font-size: 18px;
                    @include pcFS(18px);
                }
                @include mediaQ(SP) {
                    height: 100%;
                }
                &::after {
                    right: 21px;
                }
            }
        }
    }
}

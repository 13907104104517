@use "sass:math";
@import "mixin";

/*=============================================
=            margin-top             	      =
=============================================*/
.mt10 {
    margin-top: 10px!important;
}
.mt15 {
    margin-top: 15px!important;
}
.mt20 {
    margin-top: 20px!important;
}
.mt40 {
    margin-top: 40px!important;
}
.mt100-0 {
    margin-top: 100px!important;
    @include mediaQ(SP) {
        margin-top: 0px!important;
    }
}
/*=============================================
=            margin-bottom           	      =
=============================================*/
.mb15 {
    margin-bottom: 15px!important;
}
.mb20 {
    margin-bottom: 20px!important;
}
.mb25 {
    margin-bottom: 25px!important;
}
.mb30 {
    margin-bottom: 30px!important;
}
.mb40 {
    margin-bottom: 40px!important;
}
.mb75 {
    margin-bottom: 75px!important;
}
.mb60-40 {
    margin-bottom: 60px!important;
    @include mediaQ(SP) {
        margin-bottom: 40px!important;
    }
}
.mb80-40 {
    margin-bottom: 80px!important;
    @include mediaQ(SP) {
        margin-bottom: 40px!important;
    }
}
.mb130-80 {
    margin-bottom: 130px!important;
    @include mediaQ(SP) {
        margin-bottom: 80px!important;
    }
}

/*=============================================
=            title           	          =
=============================================*/
.bl_ttl {
    &_lv1 {
		position: relative;
		font-weight: bold;
		text-align: center;
        line-height: 1.5;
		@include mediaQ(PC) {
			font-size: 34px;
			// @include pcFS(34px);
			margin-bottom: 40px;
			margin-bottom: clamp(0px, per(40px, $widthContent), 40px);
		}
		@include mediaQ(SP) {
			font-size: 26px;
			margin-bottom: 30px;
		}
		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 50%;
			display: block;
			height: 3px;
			@include mediaQ(PC) {
				bottom: -13px;
			}
			@include mediaQ(SP) {
				bottom: -17px;
			}
		}
		&::before {
			width: 40px;
			background: $hv_blue;
			transform: translateX(-50%);
		}
		&::after {
			width: 20px;
			background: $blue;
		}
	}

  
    &_lv2 {
        position: relative;
        font-weight: bold;
        line-height: 1.5;
        @include mediaQ(PC){
            font-size: 24px;
            // @include pcFS(24px);
            padding-left: per(41px, $widthContent);
            margin-bottom: per(20px, $widthContent);
        }
        @include mediaQ(SP){
            font-size: 22px;
            padding: 0 0 2px 30px;
            margin-bottom: 8px;
        }
        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            // transform: translateY(-50%);
            left: 0;
            @include mediaQ(PC) {
                top: 9px;
                width: per(28px, $widthContent);
            }
            @include mediaQ(SP) {
                width: 20px;
                top: 10px;
            }
        }
        &::before {
            background: $blue;
            // background: $hv_blue;
            // transform: translateY(-50%);
            @include mediaQ(PC) {
                padding-bottom: per(28px, $widthContent);
            }
            @include mediaQ(SP) {
                height: 20px;
            }
        }
        &::after {
            background: $hv_blue;
            // background: $blue;
             transform: translateY(0);
            @include mediaQ(PC) {
                padding-bottom: per(14px, $widthContent);
            }
            @include mediaQ(SP) {
                height: 10px;
            }
        }
    }

    &_lv3 {
        position: relative;
        font-weight: bold;
        line-height: 1.5;
        @include mediaQ(PC){
            font-size: 20px;
            // @include pcFS(20px);
            margin-bottom: per(20px, $widthContent);
        }
        @include mediaQ(SP){
            font-size: 20px;
            margin-bottom: 8px;
        }
    }
}

/*=============================================
=            text            	          =
=============================================*/
//中国トピックスとお知らせのみ。太字をfont-weight700へ
.pg_topics .el_txt p strong, .pg_news .el_txt p strong  {
    font-weight: 700;
}


.bl_txt {
    //通常テキストは.el_txtでlayout.scssで

    &_lg {
        font-size: 18px;
        // @include mediaQ(PC){
		// 	@include pcFS(18px);
		// }
		// @include mediaQ(SP){
		// 	font-size: 18px;
		// }
    }

    &_sml {
        font-size: 14px;
        // @include mediaQ(PC){
		// 	@include pcFS(14px);
		// }
		// @include mediaQ(SP){
		// 	font-size: 14px;
		// }
    }
    // &_red {
    //     color: #FF0000;
    // }
    
    &_notice {
        color: #5A5A5A;
        padding-left:1em;
        text-indent:-1em;
        font-size: 14px;
        &::before {
            content:'※';
            display: inline-block;
            padding-left: 1em;
        }
    }

    &_notice_sml {
        font-size: 13px;
    }

    &_link {
        text-decoration: underline;
        display: block;
        margin-bottom: 20px;
        &:hover {
            color: $hv_blue;
        }
    }
    &_link.md_link {
        display: inline-block;
    }
    
    &_box {
        padding: 25px 40px;
        background: $_bg_blue;
        @include mediaQ(SP){
            padding: 12px 19px;
        }

        h6 {
            font-size: 20px;
            font-weight: bold;
            color: $blue;
            line-height: 1.5;
        }
        
        &__02 {
            padding: 30px 40px;
            @include mediaQ(SP){
                padding: 20px 19px;
            }
            .olList {
                margin-bottom: 0;
            }
        }
    }
    
}



/*=============================================
=            button           	          =
=============================================*/
.btn {
    &_link {
		&Btn {
			$linkBtn: &;
			&Wrap {
				position: relative;
				margin: 0 auto;
				height: 0;
				@include mediaQ(PC){
					display: block;
					max-width: 320px;
					width: convVW(320px, $maxWidthContent);
					padding-bottom: 60px;
					padding-bottom: clamp(0px, convVW(60px, $maxWidthContent), 60px);
				}
				@include mediaQ(SP){
					height: 70px;
				}

                &__left {
                    margin-left: 0;
                }
                &__right {
                    margin-right: 0;
                }

                &#{&}__noSize {
					@include mediaQ(PC){
						max-width: none;
						width: 100%;
						padding-bottom: 0;
						margin: 0;
					}
					@include mediaQ(SP){
						height: auto;
					}
				}
				&#{&}__2lines {
					@include mediaQ(PC){
                        display: block;
                        max-width: 320px;
                        width: convVW(320px, $maxWidthContent);
                        padding-bottom: 80px;
                        padding-bottom: clamp(0px, convVW(80px, $maxWidthContent), 80px);
                    }
                    @include mediaQ(SP){
                        height: 80px;
                    }
				}
                &#{&}__2colitem {
                    @include mediaQ(PC) {
                        width: per(530px, $widthContent);
                        padding-bottom: 60px;
                        padding-bottom: clamp(0px, convVW(60px, $maxWidthContent), 60px);
                    }
                    @include mediaQ(SP) {
                        width: 100%;
                        height: 70px;
                        &:not(:last-of-type) {
                            margin-bottom: 20px;
                        }
                    }
                }
                &#{&}__3colitem {
                    @include mediaQ(PC) {
                        width: per(340px, $widthContent);
                        padding-bottom: 60px;
                        padding-bottom: clamp(0px, convVW(60px, $maxWidthContent), 60px);
                    }
                    @include mediaQ(SP) {
                        width: 100%;
                        height: 70px;
                        &:not(:last-of-type) {
                            margin-bottom: 20px;
                        }
                    }
                }
                &#{&}__single {
                    @include mediaQ(PC) {
                        width: per(215px, $widthContent2Col);
                        padding-bottom: 45px;
                        padding-bottom: clamp(0px, convVW(45px, $widthContent2Col), 45px);
                    }
                    @include mediaQ(SP) {
                        width: 215px;
                        height: 45px;
                    }
                }
			
			}
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			color: $blue;
			font-weight: bold;
			background: $white;
			border: solid 1px $blue;
			border-radius: 4px;
			margin: 0 auto;
			transition: .3s;
			@include mediaQ(PC){
                font-size: 18px;
				// @include pcFS(18px);
			}
			@include mediaQ(SP){
				font-size: 16px;
			}
			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				right: 17px;
				width: 10px;
				height: 10px;
				border-top: solid 2px $blue;
				border-right: solid 2px $blue;
				transform: translateY(-50%) rotate(45deg);
				transform: translateY(calc(-50% + 1px)) rotate(45deg);
				transition: .3s;
			}
			&:hover {
				color: $white;
				background: $blue;
				&::after {
					border-color: $white;
				}
			}

            &__other {
                background: $blue;
                color: $white;

                &::after {
                    border-top: solid 2px $white;
				   
                    border-right: solid 2px $white;
                }
                &:hover {
                    color: $blue;
                    background: $white;
                    &::after {
                        border-color: $blue;
                    }
                }
            }

            &__3col {
                font-size: 16px;
                // @include mediaQ(PC){
                //     @include pcFS(16px);
                // }
                // @include mediaQ(SP){
                //     font-size: 16px;
                // }
            }
		
		}
	}
   
}

/******************************
PageTop
******************************/
.el_pageTop { 
    background-image:url("/common/images/parts/icon_top_wh.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 33%;
    background-color: $black;
    border: 1px solid $black;
    border-radius: 50%;
    bottom: 245px;
    width: 54px;
    height: 54px;
    position: fixed;
    right: 4%;
    z-index: 20;
    @include mediaQ(SP) {
        bottom: 179px;
        right: 3%;
    }
    @include mediaQ(PC) {
        &:hover {
            background-color: $white;
            background-image: url("/common/images/parts/icon_top_bk.svg");
        }
    }
}
a.el_pageTop {
    transition: none;
}

/*=============================================
=            list link           	          =
=============================================*/

a:link.list_link, a:visited.list_link, a:active.list_link, .forlink a:link, .forlink a:visited, .forlink a:active {
    text-decoration: underline;
    color: $hv_blue;
    &:hover {
        color: $hv_blue;
        text-decoration: none;
    }
}

.md_link {
    &[href*=".pdf"]::after, &[href*=".PDF"]::after, &[href*=".doc"]::after, &[href*=".docm"]::after, &[href*=".docx"]::after, &[href*=".xls"]::after, &[href*=".xlsx"]::after, &[href*=".xlsm"]::after, &[href*=".xlsb"]::after, &[href*=".xltx"]::after, &[href*=".ppt"]::after, &[href*=".pptx"]::after {
         display: inline-block;
         font-size: 14px;
         font-weight: 500;
         height: 21px;
         width: 43px;
         line-height: 1.3;
         margin-bottom: 4px;
         margin-left: 6px;
         padding-left: 2px;
         padding-top: 1px;
         text-align: center;
         text-decoration: none;
         border-radius: 2px;
         vertical-align: middle;
         color: $white;
    }
    &[href*=".pdf"]::after, &[href*=".PDF"]::after {
        background-color: #FF6565;
        content: "PDF";
    }

    &[href*=".doc"]::after, &[href*=".docm"]::after, &[href*=".docx"]::after {
        font-size: 11px;
        background-color: #185ABD;
        content: "doc";

    }

    &[href*=".xls"]::after, &[href*=".xlsx"]::after, &[href*=".xlsm"]::after, &[href*=".xlsb"]::after, &[href*=".xltx"]::after {
        font-size: 11px;
        background-color: #107C41;
        content: "xlsx";
    }
    &[href*=".ppt"]::after, &[href*=".pptx"]::after {
        font-size: 11px;
        background-color: #e4752b;
        padding-top: 0;
        content: "ppt";
    }
}

.other_link {
    &::after {
        content: "";
         display: inline-block;
         background: url(/common/images/parts/new_black.svg) center center no-repeat;
         height: 20px;
         width: 20px;
         margin-bottom:-4px;
         margin-left: 5px;
    }
}

/*=============================================
=            text link           	          =
=============================================*/

.txt_inlineLink {
    // margin-bottom: 16px;
    display: inline-block;
    // line-height: 1.5;
}
.txt_link.md_link, .txt_inlineLink.md_link {
    &[href*=".pdf"]::after, &[href*=".PDF"]::after, &[href*=".doc"]::after, &[href*=".docm"]::after, &[href*=".docx"]::after, &[href*=".xls"]::after, &[href*=".xlsx"]::after, &[href*=".xlsm"]::after, &[href*=".xlsb"]::after, &[href*=".xltx"]::after, &[href*=".ppt"]::after, &[href*=".pptx"]::after {
        padding-left: 4px;
    }
}

.md_link.for_link {
    &[href*=".pdf"]::after, &[href*=".PDF"]::after, &[href*=".doc"]::after, &[href*=".docm"]::after, &[href*=".docx"]::after, &[href*=".xls"]::after, &[href*=".xlsx"]::after, &[href*=".xlsm"]::after, &[href*=".xlsb"]::after, &[href*=".xltx"]::after, &[href*=".ppt"]::after, &[href*=".pptx"]::after {
        padding-left: 18px;
    }
}

.md_link.for_link.for_Numlink {
    &[href*=".pdf"]::after, &[href*=".PDF"]::after, &[href*=".doc"]::after, &[href*=".docm"]::after, &[href*=".docx"]::after, &[href*=".xls"]::after, &[href*=".xlsx"]::after, &[href*=".xlsm"]::after, &[href*=".xlsb"]::after, &[href*=".xltx"]::after, &[href*=".ppt"]::after, &[href*=".pptx"]::after {
        padding-left: 28px;
    }
}

/*=============================================
=            list           	          =
=============================================*/
.olList { 
    margin-bottom: 40px;
    
    li {
        font-size: 16px;
        line-height: 2;
        list-style-type: none;
        margin-bottom: 15px;
        line-height: 1.7;
    }

    &_01 {
        padding-left: 2.5rem;
        @include mediaQ(SP) {
            padding-left: 2rem;
        }
        li{
            list-style-type: decimal;
            &::marker {
                font-size: 25px;
                font-weight: 500;
                font-family: 'Roboto', sans-serif;
            }
        }
    }

    &_02 li{
        counter-increment: cnt;
        text-indent: -3rem;
        padding-left: 4rem;
        &::before {
            content: counter(cnt);
            font-size: 18px;
            font-weight: bold;
            font-family: 'Roboto', sans-serif;
            background-color: $blue;
            color: $white;
            border-radius: 50%;
            font-size: 18px;
            font-weight: bold;
            padding: 2px 8px;
            position: relative;
            left: -17px;
            top: 0px;
            // @include mediaQ(SP) {
            //     top: 2px;
            // }
        }
    }

    &_03 li{
        counter-increment: cnt;
        text-indent: -6rem;
        padding-left: 4rem;
        &::before {
            content:"（" counter(cnt)"）";
            font-size: 18px;
            font-weight: bold;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            padding: 2px 8px;
            position: relative;
            top: 1px;
            @include mediaQ(SP) {
                top: 2px;
            }
        }
    }
    &_04 {
        li{
            font-weight: bold;
        }
    }

    &_05 {
        padding-left: 2rem;
        li{
            list-style-type: decimal;
            ol {
                counter-reset: list;
                margin-bottom: 0; 
                text-indent: -2rem;
                padding-left: 2rem;
                padding-top: 4px;
                
                li {
                    counter-increment: cnt;
                    list-style-type: none;
                    margin-bottom: 9px; 
                    padding-bottom: 0;
                    &:last-child {margin-bottom: 0;}

                    &::before {
                        content: counter(cnt) "） ";
                    }
                }

            }
        }

        &_02 {
            li {
                text-indent: -3rem;
                padding-left: 3rem;
                ol {
                    counter-reset: cnt 0;
                    margin-bottom: 0; 
                    padding-top: 4px;
                    
                    li {
                        list-style-type: none;
                        text-indent: -2rem;
                        counter-increment: cnt 1;
                        margin-bottom: 9px; 
                        padding-bottom: 0;
                        &:last-child {margin-bottom: 0;}
                        
                        &::before {
                            content: counter(cnt) "） ";
                        }
                    }
                }
            }
            

        }

        &_03 {
            padding-left: 2rem;
            li{
                list-style-type: decimal;
                ul{
                    // counter-reset: list;
                    // margin-bottom: 0; 
                    text-indent: -1.5rem;
                    padding-left: 1.5rem;
                    padding-top: 4px;
                    
                    li {
                        // counter-increment: cnt;
                        list-style-type: none;
                        margin-bottom: 9px; 
                        padding-bottom: 0;
                        &:last-child {margin-bottom: 0;}
    
                        &::before {
                            content: "・ ";
                        }
                    }
    
                }
            }
        }
    }
}
//本文の中のリストのため
.el_txt.forlink ol {
    padding-left: 1em;
    text-indent: -1em;
}

.ulList {
    // margin-bottom: 40px;
    padding-left: 2rem;
    $ulList :'$ulList';

    &#{&}_child {
        padding-left: 0;
        li {
            font-size: 16px;
            line-height: 1.7;
            list-style-type: disc;
            list-style-type: none;
            text-indent: 0;
            padding-left: 0;
            &:not(:last-child) {
                margin-bottom: 20px;
            }
    
            &::before {
                background-color: $blue;
                content: "";
                display: inline-block;
                border-radius: 50%;
                position: relative;
                height: 2px;
                margin-right: 8px;
                top: -1px;
                width: 1px;
                padding: 4px;
            }
    
            p {
                padding-left: 18px;
                display: inline-block;
            }
        }
    }
    li {
        font-size: 16px;
        line-height: 1.7;
        list-style-type: disc;
        list-style-type: none;
        text-indent: -18px;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        // padding-left: 20px;

        &::before {
            background-color: $blue;
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: relative;
            top: -2px;
            margin-right: 10px;
        }

        p {
            padding-left: 18px;
            display: inline-block;
        }
    }

    &__map {
        width: 50%;
        @include mediaQ(SP) {
            width: 100%;
        }
        li {
            font-weight: bold;
            @include mediaQ(PC) {
                font-size: 23px;
                // @include pcFS(23px);
                &:not(:last-child) {
                    margin-bottom: 34px;
                }
            }
            @include mediaQ(SP) {
                font-size: 21px;
                text-indent: -1.4em;
                padding-left: 0.5em;
            }
            &::before {
                // background-color: $blue;
                content: "";
                display: inline-block;
                width: 19px;
                height: 19px;
                border-radius: 50%;
                position: relative;
                top: 1px;
                margin-right: 10px;
                background: $blue url("/assets/img/icon_arrow_white.svg") no-repeat center center;
                border-radius: 50%;
            }
            a.list_link {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__mapMid {
        
        li {
            margin-bottom: 0;
            padding-left: 1em;
            text-indent: 0em;
            position: relative;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &::before {
                content: none;
            }
            
            a.list_link {
                font-weight: 500;
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 3px 0;
                @include mediaQ(SP) {
                    padding: 5px 0;
                }
                &::before {
                    border-top: 2px solid $blue;
                    content: "";
                    margin-right: 2em;
                    width: 1em;
                    left: -6px;
                    position: absolute;
                    @include mediaQ(SP) {
                        margin-right: 0.7em;
                        width: 1em;
                        top: 18px;
                        left: -13px;
                    }
                }
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__mapSml {
        
        li {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &::before {
                content: none;
            }
            
            a.list_link {
                font-size: 14px;
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 3px 0;
                @include mediaQ(SP) {
                    padding: 5px 0;
                }
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

/*=============================================
=            table          	          =
=============================================*/
.md_table {
    margin-bottom: 40px;
    table {
        border-left: solid 1px #ccc;
        border-top: solid 1px #ccc;
        margin-bottom: 20px;
        table-layout: fixed;
        width: 100%;
        @include mediaQ(SP) {
            min-width: 760px;
        }
    }
        
    @include mediaQ(SP) {
        &_inner {
            overflow-x: scroll;
            width: 100%;
        }
    }
    
    th, td {
        border-bottom: solid 1px #ccc;
        border-right: solid 1px #ccc;
        font-size: 16px;
        line-height: 1.8;
        padding: 13px 20px;
        @include mediaQ(SP) {
           font-size: 14px;
        }
    }
    th {
        background-color: $_bg_blue ;
        font-weight: bold;
        width: 25.4%;
        @include mediaQ(SP) {
            max-width: 128px;
        }
    }

    &_non {
        table, th, td {
            border: 0;
            background-color: transparent;
            font-weight: normal;
            line-height: 1;
        }
    }

    &_01 {
        @include mediaQ(SP) {
            table {  
                min-width: 100%;
            }
            tr {
                display: flex;
                flex-direction: column;
            }
            th {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    &_02 {
        th,td {
            @include mediaQ(SP) {
                width: 334px;
                max-width: 334px;
            }
        }
    }
    &_03 {
        th,td {
            @include mediaQ(SP) {
                width: 250px;
                max-width: 250px;
            }
        }
    }
    // &_04 {
    //     th,td {
    //         @include mediaQ(SP) {
    //             width: 200px;
    //             max-width: 200px;
    //         }
    //     }
    // }

}

/*=============================================
=            anchor link          	          =
=============================================*/
.anchor_link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    
    li {
        font-size: 16px;
        margin-right: 30px;
        margin-bottom: 10px;
        // &:first-child {
        //     margin-left: 0;
        // }

        &::after {
            content: "";
            display: inline-block;
            background-image: url(/common/images/parts/icon_arrow_blue.svg);
            background-repeat: no-repeat;
            background-size: contain;
            bottom: 0px;
            width: 12px;
            height: 12px;
            transform: rotate(90deg);
            margin-left: 10px;
        }
    }
}

/*=============================================
=                  画像           	          =
=============================================*/

.md_card_img {
    -o-object-fit: cover;
    -o-object-position: 50% 50%;
    max-width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    vertical-align: top;
    height: 100%;
    width: 100%;
}

.md_card_img_noof {
    max-width: 100%;
    object-position: 50% 50%;
    vertical-align: top;
    height: 100%;
    width: 100%;
}

.md_card {
    list-style: none;
    &__single {
        margin-bottom: 60px;
        justify-content: center;
    }
    &_caption {
        font-size: 16px;
        line-height: 1.8;
        margin-top: 17px;
        min-height: 33px;
        @include mediaQ(PC) {
            min-height: 47px;
        }
    }
}

.md_cardUnit {
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;

    &__col2 {
        justify-content: space-between;
        .md_card {
            width: calc(100% / 2 - 20px);
            // &_caption {
            //     margin-top: 10px;
            // }

            &_imgWrap {
                @include mediaQ(PC) {
                    // height: 30vh;
                    object-fit: cover;
                }
            }
        }
        @include mediaQ(SP) {
            .md_card {
                width: 100%;
                &:nth-child(n+2) {
                    margin-top: 30px;
                }
                &_imgWrap {
                    max-height: none;
                    // height: 30.7vh;
                }
            }
        }
    }
    &__col3 {
        .md_card {
            width: calc(100% / 3 - 14px);
            &:not(:nth-child(3n)) {
                margin-right: 20px;
            }
            &_caption {
                margin-top: 10px;
            }
            &_imgWrap {
                @include mediaQ(PC) {
                    // height: 17vh;
                    object-fit: cover;
                }
            }

        }
        @include mediaQ(SP) {
            .md_card {
                width: 100%;
                margin-right: 0;
                &:nth-child(n+2) {
                    margin-top: 30px;
                }
            }
        }
    }

    &__col3long {
        .md_card {
            width: calc(100% / 3 - 14px);
            &:not(:nth-child(3n)) {
                margin-right: 20px;
            }
            &_caption {
                font-size: 18px;
                margin-bottom: 10px;
            }
            &_imgWrap {
                @include mediaQ(PC) {
                    object-fit: contain;
                    max-width: 340px;
                }
            }
           

        }
        @include mediaQ(SP) {
            .md_card {
                width: 100%;
                margin-right: 0;
                &:nth-child(n+2) {
                    margin-top: 30px;
                }
            }
        }
    }
    &__col4 {
        .md_card {
            width: calc(100% / 4 - 15px);
            &:not(:nth-child(4n)) {
                margin-right: 20px;
            }
            &_caption {
                margin-top: 5px;
            }
        }
        @include mediaQ(SP) {
            .md_card {
                width: 100%;
                margin-right: 0;
                &:nth-child(n+2) {
                    margin-top: 30px;
                }
                &_imgWrap {
                    max-height: none;
                    // height: 30.7vh;
                }
            }
        }
    }
    @include mediaQ(SP) {
        display: block;
    }
}
.pg_topics .md_cardUnit {
    &__col3 {
        .md_card {         
            &_imgWrap {
                @include mediaQ(PC) {
                    // height: 17.5vh;
                    max-width: 236px;
                }
            }
        }
    }

    &__col3long {
        .md_card {
            &_imgWrap {
                @include mediaQ(PC) {
                    max-width: 236px;
                }
            }
           

        }
       
    }

}

.md_imgText {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
    &_imgWrap {
        // width: 49%;
        width: calc(100% / 2 - 20px);
        margin-bottom: 8px;
        img {
            width: 100%;
        }
    }
    &_text {
        width: 51%;
        font-size: 16px;
        line-height: 1.8;
        padding: 0 0% 0 3%;
        margin-top: -4px;
    }
    &__reverse {
        flex-direction: row-reverse;
        .md_imgText_text {
            padding: 0 3% 0 0%;
        }
    }

    @include mediaQ(SP) {
        display: block;
        &_imgWrap {
            width: 100%;
        }
        &_text {
            width: 100%;
            padding: 0;
            margin-top: 10px;
        }
        &__reverse {
            .md_imgText_text {
                padding: 0;
            }
        }
    }
}

.md_cardTextUnit_text {
    font-size: 16px;
    line-height: 1.8;
    margin-bottom: 16px;
}



/*=============================================
=          QA              	          =
=============================================*/
.bl_qaParts {

    &_contents {
        margin: 24px 0 52px;

        @include mediaQ(SP) {
            margin: 24px 0 32px;
        }

        &_title {
            $two: '.bl_qaParts_contents_title__two';
            position:relative;
            padding: 19px 2% 19px 8.3%;
            margin-top: 8px;
            font-size: 18px;
            // @include pcFS(18px, $widthContent, 15px);
            font-weight: 500;
            background-color:$white;
            border: 1px solid #ccc;
            cursor:pointer;
            transition:0.7s ease;
            
            @include mediaQ(SP) {
                padding: 14px 12% 10px 16%;
                min-height: 70px;
                margin-top: 6px;
            }

            &#{$two} {
                @include mediaQ(SP) {
                    padding-bottom: 15px;
                }

            }
            &:last-child {
                margin-bottom: 0;
            }

            &_h4 {
                $one: '.bl_qaParts_contents_title_h4__one';
                position:relative;
                letter-spacing: 0.02rem;
                display:flex;
                align-items:center;
                line-height:1.4;

                @include mediaQ(SP) {
                    line-height:1.8;
                }

                &#{$one} {
                    @include mediaQ(SP) {
                        top: 6px;
                    }

                    &::before {
                        @include mediaQ(SP) {
                            top: -17px;
                        }
                    }
                }

                &::before {
                    display:block;
                    position:absolute;
                    left: -1.7em;
                    content:"Q.";
                    color: $hv_blue;
                    font-family: 'Roboto', sans-serif;
                    font-size: 36px;
                    // @include pcFS(36px, $widthContent, 32px);
                    transition:0.7s ease;

                    @include mediaQ(SP) {
                        left:-1.4em;
                        top: -10px;
                    }

                }
            }
        }

        &_title.is_open {
            .bl_qaParts_contents_icon_span {
                background-color: $blue;
                &:last-child{
                    right: -20px;
                }
            }
        }

        &_icon {
            overflow:hidden;
            display:block;
            position:absolute;
            top:50%;
            right:22px;
            width:25px;
            height:25px;
            padding-top:11px;
            transform:translateY(-50%);

            @include mediaQ(SP) {
                height: 22px;
                padding-top: 11px;
                right: 12px;
                top: 48%;
                width: 22px;
            }

            &_span {
                display:block;
                width:25px;
                height:2px;
                background-color:$blue;
                transition:0.7s ease;

                @include mediaQ(SP) {
                    width:22px;
                    height:1px;
                }

                &:last-child {
                    position:relative;
                    top:0;
                    right:auto;
                    transform:rotate(90deg);
                }
            }
        }

        &_answer {
            display:none;
            padding: 20px 6% 20px 8%;
            background-color:#F8F8F8;
            // margin-bottom: 8px;
            border: 1px solid #ccc;
					border-top: 0;

            @include mediaQ(SP) {
                padding: 20px 10% 20px 16%;
                // margin-bottom: 6px;
            }

            &_txt {
                position:relative;
                font-size: 18px;
                // @include pcFS(18px, $widthContent, 15px);
                font-weight:500;
                @include mediaQ(SP) {
                   line-height: 1.8;
                }

                &::before {
                    display:block;
                    position:absolute;
                    left:-1.7em;
                    top:-10px; content:"A.";
                    color: $orange;
                    font-family: 'Roboto', sans-serif;
                    font-size: 36px;
                    // @include pcFS(36px, $widthContent, 32px);
                    transition:0.7s ease;
                    @include mediaQ(SP) {
                        left:-1.3em;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &_txt.un_iconNone {
              margin-top: 20px;

                &::before {
                    display: none;    
                }

            }
        }
    }
}

.md_simulation {
    background-color: #F8F8F8;
    width: 100%;
    padding: 31px 2% 35px;
    text-align: center;
    // max-height: 238px;


    @include mediaQ(SP) {
        width: 100%;
        max-height: none;
        // padding: 19px 7%;
        @include pcPAD_P((60px 46px), 768px);
    }

    &_date {
        background-color: $white;
        color: $blue;
        border: 1px solid $blue;
        font-size: 16px;
        // @include pcFS(16px);
        font-weight: bold;
        padding: 3px 0;
        width: 38.3%;
        margin: 0 auto 15px;

        @include mediaQ(SP) {
            font-size: 16px;
            padding: 5px 0;
            min-width: 298px;
            max-width: 400px;
            width: 88%;
            margin: 0 auto per(8px, 375px);
        }

        span {
            margin-left: 2px;
            font-size: 27px;
            // @include pcFS(27px);
            @include mediaQ(SP) {
                font-size: 27px;
            }
        }
       
    }

    &_st {
        // @include pcFS(31px);
        font-size: 31px;
        color: $black;
        line-height: 1.5;
        margin-bottom: 9px;
        @include mediaQ(SP) {
            font-size: 28px;
            margin-bottom: per(12px, 375px);
        }
    }

    &_txt {
        font-size: 14px;
        line-height: 1.7;
        color: $black;
        text-align: center;
        margin-bottom: 21px;
        @include mediaQ(SP) {
            margin-bottom: per(13px, 375px);
            text-align: left;
        }
    }

    &_linkwrap {
        &_link {
            position: relative;
            font-size: 16px;
            // @include pcFS(18px);
            color: $orange;
            border: 1px solid $orange;
            margin: auto;
            @include btn_part02 (359px, 62px, $white, $orange);

            @include mediaQ(SP) {
                font-size: 16px;
                @include btn_part02 (100%, math.div(60px, 375px) * 100 + vw, $white, $orange);
            }

            &::before {
                @include mediaQ(PC) {
                    height: 36px;
                    left: -28px;
                    width: 31px;
                    margin-right: 0;
                }
                @include mediaQ(SP) {
                    height: 9vw;
                    width: 10%;
                }
            }
            &::after {
                @include mediaQ(PC) {
                    height: 11px;
                    right: 5%;
                    width: 11px;

                }
                @include mediaQ(SP) {
                    right: 5%;
                }
            }

        }
    }
    
}

/*=============================================
=          Topics 詳細             	          =
=============================================*/
.ly {
    &_topics_inner {
        display: flex;
        justify-content: space-between;
        @include mediaQ(SP) {
            flex-direction: column;
        }
    }
    &_contents {
        width: 71.8%;
        @include mediaQ(SP) {
            width: 100%;
        }
    }
    &_side {
        width: 25.5%;
        @include mediaQ(SP) {
            width: 100%;
        }
    }
}
.bl_side {
    @include mediaQ(SP) {
        margin-bottom: 85px;
    }

    &_banner {
        width: 100%;
        img {
            width: 100%;
        }
    }

    &_ttl {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: $white;
        background: $blue;
        border-radius: 8px 8px 0 0;
        // height: 60px;
        padding: 15px 0;
        @include mediaQ(SP) {
            font-size: 18px;
            padding: 13px 0;
        }
    }

    &_list {
        &:first-of-type {
            margin-bottom: 27px;
            @include mediaQ(SP) {
                margin-bottom: 44px;
            }
        }
        &_item {
            // display: flex;
            // justify-content: space-between;
            // align-items: flex-start;
            padding: 20px 2.5%;
            border-bottom: 1px solid #E8E8E8;
            @include mediaQ(SP) {
                padding: 22px 2.5%;
            }
            

            &_link {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                // width: 33%;
                // flex-shrink: 0;
                
                @include mediaQ(SP) {
                    font-size: 18px;
                }
                img {
                    width: 84px;
                    height: 84px;
                    object-fit: cover;
                    border: 1px solid #E8E8E8;
                }
            }

            &_ttl {
                width: 57%;
                line-height: 1.7;
                @include mediaQ(SP) {
                    width: 68%;
                }
            }
        }
    }
    
}

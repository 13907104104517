@use "sass:math";
@import "mixin";

.pg_applyPlan {
	$root: &;
	&_flow {
		@include mediaQ(PC) {
			width: per(970px, $widthContent);
			margin-bottom: per(40px, $widthContent);
		}
		@include mediaQ(SP) {
			margin-bottom: 30px;
		}
		&_item {
			@include mediaQ(PC) {
				width: clamp(110px, per(190px, 970px), 190px);
			}
			&:not(:last-of-type) {
				@include mediaQ(PC) {
					margin-right: per(70px, 970px);
				}
				@include mediaQ(SP) {
					margin-bottom: 20px;
				}
			}
		}
	}
	&.woocommerce {
		#{$root}_service {
			.product {
				display: flex;
				@include mediaQ(PC) {
					justify-content: space-between;
				}
				@include mediaQ(SP) {
					flex-direction: column;
				}
				* { float: none !important; font-family:'Noto Sans JP', sans-serif!important;}
				$productContWidth: 530px;
				.woocommerce-product-gallery {
					&__wrapper {
						&::after {
							content: "";
							display: block;
							clear: both;
						}
					}
					@include mediaQ(PC) {
						width: per($productContWidth, $widthContent);
					}
					@include mediaQ(SP) {
						margin-bottom: 30px;
					}
					&__image {
						float: left !important;
					}
					.flex-control-nav{
						clear: both;
						@include mediaQ(PC) {
							margin-top: per(25px, $productContWidth);
						}
						@include mediaQ(SP) {
							margin-top: 19px;
						}
						&.flex-control-thumbs {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							&::after {
								content: "";
								display: block;
								@include mediaQ(PC) {
									width: per(160px, $productContWidth);
								}
								@include mediaQ(SP) {
									width: per(101px, 335px);
								}
							}
							li {
								@include mediaQ(PC) {
									width: per(160px, $productContWidth);
									margin-bottom: per(20px, $productContWidth);
								}
								@include mediaQ(SP) {
									width: per(101px, 335px);
									margin-bottom: 10px;
								}
							}
						}
					}
				}
				.summary {
					@include mediaQ(PC) {
						width: per($productContWidth, $widthContent);
					}
					.product_title {
						font-weight: bold;
						@include mediaQ(PC) {
							$fs: 30px;
							@include pcFS($fs);
							line-height: math.div(48px, $fs);
							margin-bottom: per(6px, $widthContent);
						}
						@include mediaQ(SP) {
							$fs: 24px;
							font-size: $fs;
							line-height: math.div(36px, $fs);
							margin-bottom: 6px;
						}
					}
					.price {
						font-weight: bold;
						color: $black;
						@include mediaQ(PC) {
							$fs: 22px;
							@include pcFS($fs);
							line-height: math.div(42px, $fs);
							margin-bottom: per(21px, $productContWidth);
						}
						@include mediaQ(SP) {
							font-size: 18px;
							margin-bottom: 16px;
						}
					}
					.woocommerce-product-details__short-description {
						@include mediaQ(PC) {
							$fs: 16px;
							@include pcFS($fs);
							line-height: math.div(32px, $fs);
						}
						@include mediaQ(SP) {
							$fs: 16px;
							font-size: $fs;
							line-height: math.div(32px, $fs);
						}
						> * {
							@include mediaQ(PC) {
								margin-top: per(20px, $productContWidth);
							}
							@include mediaQ(SP) {
								margin-top: 20px;
							}
						}
					}
					.dopbsp-sidebar-content {
						$bgColor: #f8f8f8;
						[class^="dopbsp-row"] {
							background: $bgColor;
							&:not(:empty) {
								+ [class^="dopbsp-row"] {
									&:empty {
										@include mediaQ(PC) {
											padding-top: per(24px, $productContWidth);
										}
										@include mediaQ(SP) {
											padding-top: 24px;
										}
									}
								}
								&:last-of-type {
									@include mediaQ(PC) {
										padding-bottom: per(24px, $productContWidth);
									}
									@include mediaQ(SP) {
										padding-bottom: 24px;
									}
								}
							}
						}
						.dopbsp-module {
							border: none !important;
							background: none;
							margin: 0;
							@include mediaQ(PC) {
								@include pcPAD_P((0 24px), $productContWidth); 
							}
							@include mediaQ(SP) {
								padding: 0 per(20px, 335px);
							}
							.dopbsp-input-wrapper{
								margin: 0;
								@include mediaQ(PC) {
									@include pcPAD_P((24px 0 0), $productContWidth);
								}
								@include mediaQ(SP) {
									&:not(:last-of-type) {
										padding: 24px 0 0;
									}
								}
								label {
									color: $black;
									font-style: normal;
									font-weight: bold;
									margin-bottom: 4px;
									@include mediaQ(PC) {
										@include pcFS(16px);
									}
									@include mediaQ(SP) {
										font-size: 16px;
									}
								}
								.dopselect-select {
									position: relative;
									&::after {
										content: "";
										position: absolute;
										transform: translateY(-50%) rotate(45deg);
										border-right: solid 1px #a7a7a7;
										border-bottom: solid 1px #a7a7a7;
										top: calc(50% - 2px);
										right: 10px;
										width: 8px;
										height: 8px;
									}
									.dopselect-selection {
										color: $black!important;
									}
								}
							}
						}
						[id^="DOPBSPCalendar-search"] {
							@include mediaQ(PC) {
								display: flex;
								flex-wrap: wrap;
								justify-content: space-between;
							}
							.dopbsp-input-wrapper{
								&.DOPBSPCalendar-left {
									@include mediaQ(PC) {
										width: per(231px, $productContWidth - 48px);
									}
									@include mediaQ(SP) {
										&:not(:first-of-type) {
											padding-top: 0;
										}
										&:not(:last-of-type) {
											margin-bottom: 15px;
										}
									}
									input[type=text]{
										&.DOPBSPCalendar-check-in-view,
										&.DOPBSPCalendar-check-out-view {
											box-sizing: border-box;
											width: 100%;
											color: $black;
											padding: 4px 34px 4px 9px !important;
											background: #fff url(/assets/img/icon_date.svg) no-repeat right 10px center !important;
											&:not([disabled="disabled"]) {
												cursor: pointer;
											}
										}
									}
								}
							}
						}
						[id^="DOPBSPCalendar-extras"] {
							h4 { display: none !important; }
						}
						[id^="DOPBSPCalendar-reservation"] {
							@include mediaQ(PC) {
								padding-top: 24px;
								padding-top: clamp(0px, per(24px, $productContWidth), 24px);
								margin-top: per(20px, $productContWidth);
							}
							@include mediaQ(SP) {
								padding-top: 24px;
								margin-top: 15px;
							}
							h4 {
								$fs: 16px;
								font-weight: bold !important;
								padding: 0 !important;
								margin-bottom: 4px !important;
								@include mediaQ(PC) {
									font-size: $fs !important;
									font-size: clamp(0px, convVW($fs, $maxWidthContent), $fs) !important;
								}
								@include mediaQ(PC) {
									font-size: $fs;
								}
							}
							[id^="DOPBSPCalendar-reservation-cart"] {
								padding-bottom: 0;
								margin-top: 0;
								@include mediaQ(PC) {
									padding-top: 0;
								}
								.dopbsp-message {
									background: $blue;
									display: flex;
									align-items: center;
									margin-bottom: 0;
									@include mediaQ(PC) {
										height: 38px;
										@include pcFS(14px);
									}
									@include mediaQ(SP) {
										min-height: 38px;
										font-size: 12px;
									}
								}
								.dopbsp-cart {
									.dopbsp-total{
										.dopbsp-label,
										.dopbsp-value {
											background: $blue;
										}
									}
								}
							}
						}
					}
					.cart {
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						justify-content: center;
						@include mediaQ(PC) {
							margin-top: 20px;
						}
						.wapf{
							&-wrapper {
								margin-top: 10px;
							}
							&-product-totals {
								.wapf--inner {
									div {
										display: flex;
										justify-content: space-between;
										span {
											font-size: 16px;
											&.price {
												color: #77a464;
												font-size: 20px;
												font-weight: normal;
												margin-bottom: 0;
											}
										}
									}
								}
							}
						}
						.quantity {
							// width: 20%;
							input {
								text-align: right;
								@include pcPAD_P((6px 10px 6px 6px), 107px);
								border: 1px solid #ccc;
								border-radius: 3px;
								@include mediaQ(PC) {
									width: 58px;
									height: 48px;
									font-size: 16px;
								}
								@include mediaQ(SP) {
									width: 80%;
									height: 42px;
									font-size: 14px;
								}
							}
						}
						.single_add_to_cart_button{
							width: 78%;
							&.button{
								background: $blue;
								@include mediaQ(PC) {
									height: 48px;
									font-size: 16px;
									transition: opacity .3s;
									&:hover {
										opacity: .7;
									}
								}
								@include mediaQ(SP) {
									height: 42px;
									font-size: 14px;
								}
								&::after {
									content: "";
									display: inline-block;
									background: url('data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22shopping_cart_black_24dp%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2221%22%20height%3D%2221%22%20viewBox%3D%220%200%2021%2021%22%3E%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_454%22%20data-name%3D%22%E3%83%91%E3%82%B9%20454%22%20d%3D%22M0%2C0H21V21H0Z%22%20fill%3D%22none%22%2F%3E%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_455%22%20data-name%3D%22%E3%83%91%E3%82%B9%20455%22%20d%3D%22M6.25%2C16A1.75%2C1.75%2C0%2C1%2C0%2C8%2C17.75%2C1.748%2C1.748%2C0%2C0%2C0%2C6.25%2C16ZM1%2C2V3.75H2.75L5.9%2C10.391%2C4.719%2C12.535a1.692%2C1.692%2C0%2C0%2C0-.219.84%2C1.755%2C1.755%2C0%2C0%2C0%2C1.75%2C1.75h10.5v-1.75H6.618a.217.217%2C0%2C0%2C1-.219-.219l.026-.1.788-1.426h6.519a1.742%2C1.742%2C0%2C0%2C0%2C1.531-.9l3.132-5.679a.855.855%2C0%2C0%2C0%2C.105-.42.878.878%2C0%2C0%2C0-.875-.875H4.684L3.861%2C2H1ZM15%2C16a1.75%2C1.75%2C0%2C1%2C0%2C1.75%2C1.75A1.748%2C1.748%2C0%2C0%2C0%2C15%2C16Z%22%20transform%3D%22translate(-0.125%20-0.25)%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E') no-repeat center center / cover;
									margin-left: 0.2em;
									@include mediaQ(PC) {
										width: 18px;
										height: 16px;
										transform: translateY(2px);
									}
									@include mediaQ(SP) {
										width: 16px;
										height: 14px;
										transform: translateY(1px);
									}
								}
							}
						}
					}
					.DOPBSPCalendar-container {
						display: none;
					}
				}
			}
		}
		#{$root}_detail {
			@include mediaQ(PC) {
				$fs: 16px;
				@include pcFS($fs);
				line-height: math.div(35px, $fs);
				margin-bottom: per(80px, $widthContent);
			}
			@include mediaQ(SP) {
				$fs: 16px;
				font-size: $fs;
				line-height: math.div(32px, $fs);
				margin-bottom: 40px;
			}
			.el_contentTtl {
				@include mediaQ(SP) {
					line-height: math.div(36px, 26px);
				}
			}
			&_lead {
				@include mediaQ(PC) {
					text-align: center;
					margin-bottom: per(60px, $widthContent);
				}
			}
			&_subTtl {
				@include mediaQ(PC) {
					margin-top: per(40px, $widthContent);
				}
				@include mediaQ(SP) {
					line-height: math.div(34px, 22px);
					margin-top: 35px;
				}
				&::before,
				&::after {
					@include mediaQ(SP) {
						top: 19px;
					}
				}
			}
			ol,
			ul {
				list-style-position: inside;
				text-indent: -1em;
				padding-left: 1em;
				@include mediaQ(PC) {
					margin-top: per(24px, $widthContent);
					margin-bottom: per(19px, $widthContent);
				}
				@include mediaQ(SP) {
					margin-top: 20px;
					margin-bottom: 16px;
				}
				li {
					&:not(:last-of-type) {
						@include mediaQ(PC) {
							margin-bottom: per(14px, $widthContent);
						}
						@include mediaQ(SP) {
							margin-bottom: 12px;
						}
					}
				}
			}
			table {
				width: 100%;
				border-top: solid 1px rgba(0, 0, 0, 0.05);
				td {
					padding: 14px;
					border-bottom: solid 1px rgba(0, 0, 0, 0.05);
				}
			}
			a[href*="stripe"] {
				img {
					display: inline-block;
					vertical-align: middle;
					width: 126px;
				}
			}
		}

		.un_singleProduct_errorText {
			@include mediaQ(PC) {
				color: #FF6565;
				font-size: 14px;
				line-height: 1.6;
				width: 96%;
				margin: 0 auto;
			}
			@include mediaQ(SP) {
				color: #FF6565;
				font-size: 14px;
				line-height: 1.6;
				width: 96%;
				margin: 0 auto 40px;
			}
		}
	}
	&#{$root}__confirm {
		.woocommerce {
			.shop_table {
				$tableBorderColor: #CCC;
				background: #fff;
				border: solid 1px $tableBorderColor;
				@include mediaQ(PC){
					@include pcFS(16px);
				}
				@include mediaQ(SP){
					font-size: 16px;
				}
				> thead {
					th {
						color: $white;
						background: $blue;
						@include mediaQ(PC){
							text-align: center;
							&:not(:last-of-type) {
								border-right: solid 1px $tableBorderColor;
							}
						}
					}
				}
				> tbody {
					td {
						font-weight: bold;
						padding: 0;
						&[class^="product-"] {
							@include mediaQ(PC){
								&:not(:last-of-type) {
									border-right: solid 1px $tableBorderColor;
								}
							}
						}
					}
					.product-name{
						@include mediaQ(PC){
							width: per(532px, $widthContent);
						}
						&-txt {
							color: $black;
							@include mediaQ(PC){
								@include pcFS(16px);
								@include pcPAD_P((10px 20px), 532px);
							}
						}
						.variation {
							background: #F8F8F8;
							.dopbsp-wc-cart {
								@include mediaQ(PC){
									width: 100%;
									@include pcFS(14px);
									border-top: solid 1px $tableBorderColor;
								}
								tr {
									@include mediaQ(PC){
										display: flex;
									}
								}
								td {
									@include mediaQ(PC){
										width: auto !important;
										@include pcPAD_P((10px 20px 10px 37px), 512px);
									}
									&:first-of-type {
										@include mediaQ(PC){
											padding-right: 0;
										}
										&::after {
											content: "：";
										}
									}
									&:not(:first-of-type) {
										@include mediaQ(PC){
											flex: 1;
											padding-left: 0;
										}
									}
									strong {
										color: $black !important;
									}
									span {
										color: $black !important;
										font-weight: normal;
									}
								}
							}
						}
					}
				}
				.product-price,
				.product-quantity,
				.product-subtotal {
					@include mediaQ(PC){
						width: per(160px, $widthContent);
						@include pcPAD_P((10px 20px), $widthContent);
					}
				}
				.product-remove {
					@include mediaQ(PC){
						width: per(92px, $widthContent);
					}
					a {
						@include mediaQ(PC){
							margin: 0 auto;
						}
					}
				}
			}
			.cart_totals {
				.wc-proceed-to-checkout {
					max-width: 324px;
					width: 100%;
					padding: 0 0 50px;
					padding: 0 0 clamp(0px, convVW(50px, $maxWidthContent), 50px);
				}
			}
		}
	}
	&#{$root}__checkout {
	}
	
	.pg_product_detail {
		
		.bl_flow {
			.bl_form_flow {
				margin: 75px auto 45px;
				width: 777px;
				
				@include mediaQ(SP) {
					margin: 60px auto 30px;
					width: 100%;
				}
				
				&:before {
					@include mediaQ(SP) {
						left: 10%;
						width: 88%;
					}
				}
				
				&_item {
					font-size: 14px;
					
					&.un_flow_item_01 {
						width: percentage(math.div(80, 777));
						
						@include mediaQ(SP) {
							width: percentage(math.div(70, 330));
						}
					}
					&.un_flow_item_02 {
						width: percentage(math.div(96, 777));
						
						@include mediaQ(SP) {
							width: percentage(math.div(56, 330));
						}
					}
					&.un_flow_item_03 {
						width: percentage(math.div(112, 777));
						
						@include mediaQ(SP) {
							width: percentage(math.div(56, 330));
						}
					}
					&.un_flow_item_04 {
						width: percentage(math.div(32, 777));
						
						@include mediaQ(SP) {
							width: percentage(math.div(28, 330));
						}
					}
				}
			}
		}
		
		.woocommerce-product-gallery__image {
			margin-right: 4.7169%;
			margin-bottom: 25px;
			width: 30.1886% !important;

			&:nth-child(3n+4) {
				margin-right: 0;
			}

			&:first-child {
				width: auto !important;
				margin-right: 0;
			}
		}
		
		.un_creditCardText {
			color: #222222;
			font-size: 16px;
			line-height: 2;
			
			@include mediaQ(SP) {
				margin: 0 auto 30px;
				width: 89.3333333333%;
			}
			
			.un_text_small {
				font-size: 14px;
				line-height: 1.7;
			}
		}
		
		.un_text_border {
			border: solid 1px #CCCCCC;
			font-size: 16px;
			color: #222222;
			line-height: 1.62;
			padding: 19px 28px;
			
			
			.un_text_border_item {
				padding-left: 5.5em;
				text-indent: -5.5em;
				margin-bottom: 10px;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		
		.pg_applyPlan_service .product .summary .dopbsp-sidebar-content [id^=DOPBSPCalendar-reservation] [id^=DOPBSPCalendar-reservation-cart] .dopbsp-message {
			background: #cccccc;
		}
		
		.pg_applyPlan_service .product .summary .dopbsp-sidebar-content [class^=dopbsp-row] {
			background: #f8f8f8;
		}
	
	}
	
	.hp_mb20 {
		margin-bottom: 20px;
	}
}

@charset "utf-8";
@use "sass:math";

// 横幅を px → % へ変換
@mixin widthPct( $parentPxWidth, $pxWidth ){
    width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}

@mixin fz($fz) {
	$rem: $fz / 10;
	font-size: #{$fz}px;
	font-size: #{$rem}rem;
}

// %変換
@function per($current, $parent) {
	@return percentage(math.div($current, $parent));
}

// vw変換
@function convVW($current, $view) {
	@return math.div($current, $view) * 100 + vw;
}

// メディアクエリ
$breakpoints: (
	'PC': 'screen and (min-width: 769px)',
	'PC_O': 'screen and (min-width: 1921px)',
	'PC_1160': 'screen and (min-width: 769px) and (max-width: 1160px)',
	'SP': 'screen and (max-width: 768px)',
) !default;
// @mixinの定義
@mixin mediaQ($breakpoint: SP) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mediaQ2($min, $max) {
	@media screen and (min-width: #{$min}px ) and (max-width: #{$max}px) {
		@content;
	}
}

// pcのフォントサイズ
@mixin pcFS($fs, $view: $maxWidthContent, $min: 1rem) {
	font-size: $fs;
	font-size: clamp($min, convVW($fs, $view), $fs);
}

// pcのマージン設定（%）
@mixin pcMGN_P($mgn, $view: $widthContent, $min: 0px){
	margin: $mgn;
	$mVal: ();
	@each $m in $mgn {
		@if $m == 0 {
			$mVal: append($mVal, 0);
		}
		@else {
			$mVal: append($mVal, clamp($min, per($m, $view), $m));
		}
	}
	margin: $mVal;
}

// pcのパディング設定（%）
@mixin pcPAD_P($pad, $view: $widthContent, $min: 0px){
	padding: $pad;
	$pVal: ();
	@each $p in $pad {
		@if $p == 0 {
			$pVal: append($pVal, 0);
		}
		@else {
			$pVal: append($pVal, clamp($min, per($p, $view), $p));
		}
	};
	padding: $pVal;
}

//burger icon
@mixin btn_item($pos:0) {
	width: 90%;
	height: 2px;
	background: $black;
	position: absolute;
	top: calc(50% - 0.5px);
	transition: .3s;
	transform: translateY($pos);
}

@mixin btn_part01 ($width, $height, $color, $bk_color){
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $bk_color;
	border: solid 1px $bk_color;
	border-radius: 4px;
	color: $color;
	font-weight: bold;
	line-height: 1.43;
	width: $width;
	height: $height;
	transition: .3s;
	text-decoration: none;

	// &::before {
	// 	content: "";
	// 	display: block;
	// 	background-image: $svg_url;
	// 	background-repeat: no-repeat;
	// 	background-size: contain;
	// 	bottom: 0px;
	// 	width: 15px;
	// 	height: 15px;
	// 	margin: auto;
	// 	position: absolute;
	// 	right: 20px;
	// 	top: 0;
	// }

	&:hover {
		background-color: $color;
		color: $bk_color;
		// &::before {
		// 	background-image: url(/common/images/parts/icon_arrow_white.svg);
		// }
	}
	// @include mediaQ(SPwide) {
	// 	min-width: auto;
	// 	width: 100%;
	// }
}

@mixin btn_part02 ($width, $height, $color, $bk_color){
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $bk_color;
	border: solid 1px $bk_color;
	border-radius: 4px;
	color: $color;
	font-weight: bold;
	line-height: 1.43;
	width: $width;
	height: $height;
	transition: .3s;

	&::before{
		content: '';
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 53"><path class="svg_calc" fill="%23fff" d="M45,53H0V0H45V53ZM31,40v8h9V40ZM18,40v8h9V40ZM5,40v8h9V40ZM31,29v8h9V29ZM18,29v8h9V29ZM5,29v8h9V29ZM31,18v8h9V18ZM18,18v8h9V18ZM5,18v8h9V18ZM5,6v8H40V6Z"/></svg>');
		display: inline-block;
		position: relative;
		left: -4px;
		width: 27px;
		height: 31px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 10px;
	}

	&::after{
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		content: "";
		vertical-align: middle;
		right: 3%;
		width: 8px;
		height: 8px;
		border-top: 1px solid $color;
		border-right: 1px solid $color;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}


	&:hover {
		background-color: $color;
		color: $bk_color;

		&::before {
			background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 53"><path class="svg_calc" fill="%23FDA10D" d="M45,53H0V0H45V53ZM31,40v8h9V40ZM18,40v8h9V40ZM5,40v8h9V40ZM31,29v8h9V29ZM18,29v8h9V29ZM5,29v8h9V29ZM31,18v8h9V18ZM18,18v8h9V18ZM5,18v8h9V18ZM5,6v8H40V6Z"/></svg>');
			// .svg_calc {
			// 	fill: $bk_color;
			// }
		}
		&::after {
			border-color: $bk_color;
		}
		
	}
	// @include mediaQ(SPwide) {
	// 	min-width: auto;
	// 	width: 100%;
	// }
}

// * hack IE
$hack-ie11: "*::-ms-backdrop,";
@mixin hack-ie($hack-ver:'') {
	// * IE10 hack(add propary \9)
	@if $hack-ver == "ie10" {
		@media all and (-ms-high-contrast:none\0) {
			@content;
		}
	}
		// * IE11 hack(add selector *::-ms-backdrop)
	@else if $hack-ver == "ie11" {
		@media all and (-ms-high-contrast:none) {
			@content;
		}
	}
}

@use "sass:math";
@import "mixin";

.pg_plan__joytelProduct {
	.el_sp{
        display: none!important;
    }
    .el_link_blue {
        color: #2d8edb;
    }
    .el_link_underbar {
        text-decoration: underline;
    }
    .el_link_underbar:hover {
        text-decoration: none;
    }
    .un_sim_section{
        font-size: 16px;
        line-height: 2;
        color: #222;
        margin-bottom: 70px;
    }
    .un_sim_section:last-child{
        margin-bottom: 80px;
    }
 
    .un_sim_head01 {
        margin-bottom: 44px;
    }
    .un_sim_head02 {
        margin-bottom: 40px;
    }
    .un_sim_head03 {
        margin-bottom: 43px;
    }
    .un_sim_col2{
        display: flex;
        justify-content: space-between;
        margin-bottom: 33px;
    }
    .un_sim_col2_img{
        flex-shrink: 0;
        width: 48.2%;
    }
    .un_sim_col2_txt{
        flex-shrink: 0;
        width: 48.7%;
    }
    .un_sim_about{
        margin-bottom: 26px;
    }
    .un_sim_about_item{
        margin-bottom: 27px;
    }
    .un_sim_about_item:last-child {
        margin-bottom: 0;
    }
    .un_sim_about_head {
        font-weight: bold;
        position: relative;
        font-size: 26px;
        margin-bottom: 5px;
        padding-left: 2.4%;
    }
    .un_sim_about_head02 {
        padding-left: 4%;
    }
    .un_sim_about_head::before {
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: calc(50% + 1px);
        width: 2.5454545455%;
        background: #2D8EDB;
        transform: translateY(-50%);
        padding-bottom: 2.5454545455%;
    }
    .un_sim_about_head::after{
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: calc(50% + 1px);
        width: 2.5454545455%;
        background: #005598;
        padding-bottom: 1.2727272727%;
    }
    .un_sim_about_req{
        font-size: 13px;
        line-height: 2.615;
        text-indent: -1em;
        padding-left: 1em;
    }
    .un_sim_blue {
        padding-top: 24px;
        padding-right: 40px;
        padding-bottom: 28px;
        padding-left: 40px;
        background-color: #F2F7FA;
    }
    .un_sim_blue_head{
        margin-bottom: 2px;
        font-size: 20px;
        font-weight: bold;
        color: #005598;
    }
    .un_sim_blue_head02 {
        margin-bottom: -1px;
    }
    .un_sim_blue_list{
        margin-bottom: 18px;
    }
    .un_sim_blue_list_item{
        position: relative;
        padding-left: 20px;
        font-size: 16px;
        line-height: 2;
        margin-bottom: 3px;
    }
    .un_sim_blue_list_item:last-child {
        margin-bottom: 0;
    }
    .un_sim_blue_list_item::before {
        content: "";
        display: block;
        position: absolute;
        top: 13px;
        left: 0;
        width: 8px;
        height: 8px;
        background-color: #005598;
        border-radius: 4px;
    }
    .un_sim_blue_req{
        font-size: 13px;
        line-height: 1.85;
        margin-top: 5px;
    }
    
    .un_sim_ol_col2{
        display: flex;
        justify-content: space-between;
        margin-top: 34px;
        margin-bottom: 30px;
    }
    .un_sim_ol_img{
        flex-shrink: 0;
        width: 30%;
    }
    .un_sim_ol_wrap {
        flex-shrink: 0;
        width: 66.3%;
        margin-top: -4px;
    }
    .un_sim_ol{
        list-style-type: none;
        counter-reset: item;
    }
    .un_sim_ol_list{
        position: relative;
        padding-left: 46px;
        margin-bottom: 24px;
    }
    .un_sim_ol_list::before{
        counter-increment: item;
        content: counter(item);
        position: absolute;
        top: 5px;
        left: 0;
        padding-top: 4px;
        padding-bottom: 5px;
        width: 27px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        background-color: #005598;
        line-height: 1;
        border-radius: 13.5px;
    }
    .pg_plan .un_sim_planbtn.un_toSubscription {
        background-color: #F8F8F8;
        width: 100%;
        padding-top: 23px;
        padding-bottom: 30px;
    }
    .pg_plan .un_sim_planbtn.un_toSubscription .un_toSubscription_txt{
        padding-left: 0;
    }
    .pg_plan .un_sim_planbtn.un_toSubscription .un_toSubscription_linkWrap{
      
    }
    .pg_plan .un_sim_planbtn.un_toSubscription .el_linkBtnWrap__trial{
      margin-top: 15px;
      width: 372px;
      padding-bottom: 60px;
    }
    .pg_plan .un_sim_planbtn.un_toSubscription .el_linkBtn{
      background: #005598;
      border-color: #005598;
    }
    .pg_plan .un_sim_planbtn.un_toSubscription .el_linkBtnWrap.el_linkBtnWrap__trial .el_linkBtn:hover {
        background: #ffffff;
        color: #005598;
    }
    .pg_plan .un_sim_planbtn.un_toSubscription .el_linkBtnWrap.el_linkBtnWrap__trial .el_linkBtn:hover::after {
        border-color: #005598;
    }
    .un_sim_col3btn{
        display: flex;
        justify-content: space-between;
        margin-top: 28px;
    }
    .un_sim_col3btn_item{
        flex-shrink: 0;
        width: 30.91%;
    }
    .un_sim_col3btn_item_link{
        display: block;
        position: relative;
        padding-top: 13px;
        padding-left: 38%;
        padding-bottom: 13px;
        font-weight: bold;
        color: #fff;
        background-color: #005598;
        border: 1px solid  #005598;
        border-radius: 4px;
        transition: 0.3s;
    }
    .un_sim_col3btn_item_link:hover {
        color: #005598;
        background-color: #fff;
    }
    .un_sim_col3btn_item.un_sim_col3btn_item_type03 .un_sim_col3btn_item_link {
        padding-left: 18.3%;
    }
    .un_sim_col3btn_item_link::after {
        border-right: solid 2px #fff;
        border-top: solid 2px #fff;
        content: "";
        display: block;
        width: 2.96%;
        padding-bottom: 2.37%;
        position: absolute;
        right: 6.1%;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        transform: translateY(calc(-50% + 1px)) rotate(45deg);
        transition: 0.3s;
    }
    .un_sim_col3btn_item_link:hover::after {
        border-color: #005598;
    } 
    .un_sim_col3btn_item_span {
        display: block;
        font-size: clamp(1rem, 1.5789473684vw, 16px);
    }
    
    .un_sim_col3btn_item_span::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 27.5%;
        margin-top: -3.4%;
        width: 9.47%;
        padding-bottom: 7.101%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url(../img/service/joytel-product/icon_model01_w.png);
        transition: 0.3s;
    }
    .un_sim_col3btn_item_type02 .un_sim_col3btn_item_span::before {
        background-image: url(../img/service/joytel-product/icon_model02_w.png); 
    }
    .un_sim_col3btn_item_type03 .un_sim_col3btn_item_span::before {
        background-image: url(../img/service/joytel-product/icon_model03_w.png);
        left: 8%;
    }
    .un_sim_col3btn_item_link:hover .un_sim_col3btn_item_span::before {
        background-image: url(../img/service/joytel-product/icon_model01.png);
    }
    .un_sim_col3btn_item_type02 .un_sim_col3btn_item_link:hover .un_sim_col3btn_item_span::before {
        background-image: url(../img/service/joytel-product/icon_model02.png);
    }
    .un_sim_col3btn_item_type03 .un_sim_col3btn_item_link:hover .un_sim_col3btn_item_span::before {
        background-image: url(../img/service/joytel-product/icon_model03.png);
    }
    
    
    @media screen and (max-width: 768px) {
      
      .el_pc{
        display: none!important;
      }
      .el_sp{
        display: block!important;
      }
    
        .un_sim_section{
            margin-bottom: 38px;
        }
        .un_sim_section:nth-child(2) {
            margin-bottom: 29px;
        }
        .un_sim_section:last-child{
            margin-bottom: 39px;
        }

        .un_sim_head01 {
            margin-bottom: 41px;
        }
        .un_sim_head02 {
            line-height: 1.46;
            margin-bottom: 40px;
        }
        .un_sim_head03 {
            margin-bottom: 36px;
        }
        .un_sim_col2{
            display: block;
            margin-bottom: 31px;
        }
        .un_sim_col2_img{
            width: 100%;
            margin-bottom: 16px;
        }
        .un_sim_col2_txt{
            width: 100%;
        }
        .un_sim_about{
            margin-bottom: 26px;
        }
        .un_sim_about_item{
            margin-bottom: 34px;
        }
        .un_sim_about_item:last-child {
            margin-bottom: 0;
        }
        .un_sim_about_head {
            font-size: 22px;
            margin-bottom: 15px;
            padding-left: 31px;
            line-height: 1.55;
        }
        .un_sim_about_head02 {
            padding-left: 30px;
        }
        .un_sim_about_head::before {
            top: 9px;
            transform: none;
            width: 20px;
            height: 20px;
        }
        .un_sim_about_head::after{
            top: 19px;
            transform: none;
            width: 20px;
            height: 10px;
        }
        .un_sim_about_req{
            font-size: 14px;
            line-height: 1.71;
            margin-top: 4px;
        }
        .un_sim_blue {
            padding-top: 23px;
            padding-right: 17px;
            padding-bottom: 18px;
            padding-left: 17px;
        }
        .un_sim_blue_head{
            margin-bottom: 17px;
            font-size: 20px;
            line-height: 1.4;
        }
        .un_sim_blue_head02 {
            margin-bottom: 11px;
        }
        .un_sim_blue_list{
            margin-bottom: 32px;
        }
        .un_sim_blue_list_item{
            padding-left: 20px;
            line-height: 1.375;
            margin-bottom: 12px;
        }
        .un_sim_blue_list_item:last-child {
            margin-bottom: 0;
        }
        .un_sim_blue_list_item::before {
            top: 7px;
            left: 1px;
        }
        .un_sim_blue_req{
            font-size: 14px;
            line-height: 1.86;
            margin-top: 7px;
        }
        
        .un_sim_ol_col2{
            display: block;
            margin-top: 34px;
            margin-bottom: 30px;
        }
        .un_sim_ol_img{
            flex-shrink: 0;
            width: 257.5px;
            margin-right: auto;
            margin-bottom: 19px;
            margin-left: auto;
        }
        .un_sim_ol_wrap {
            flex-shrink: 0;
            width: 100%;
            margin-top: 0;
        }
        .un_sim_ol{
            list-style-type: none;
            counter-reset: item;
        }
        .un_sim_ol_list{
            margin-bottom: 19px;
        }
        .un_sim_ol_list::before{
            counter-increment: item;
            content: counter(item);
            position: absolute;
            top: 5px;
            left: 0;
            padding-top: 4px;
            padding-bottom: 5px;
            width: 27px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            background-color: #005598;
            line-height: 1;
            border-radius: 13.5px;
        }
        .pg_plan .un_sim_planbtn.un_toSubscription {
            background-color: #F8F8F8;
            width: 100%;
            padding-top: 22px;
            padding-bottom: 30px;
        }
        .pg_plan .un_sim_planbtn.un_toSubscription .un_toSubscription_txt{
            padding-left: 0;
            text-align: center;
            font-size: 18px;
            line-height: 1.44;
        }
        
        .pg_plan .un_sim_planbtn.un_toSubscription .el_linkBtnWrap__trial{
        margin-top: 15px;
        width: 100%;
        padding-bottom: 50px;
        height: 50px;
        }
        .pg_plan .un_sim_planbtn.un_toSubscription .el_linkBtn{
        background: #005598;
        border-color: #005598;
        letter-spacing: 0.07em;
        padding-left: 13px;
        }
        .pg_plan .un_sim_planbtn.un_toSubscription .el_linkBtnWrap.el_linkBtnWrap__trial .el_linkBtn:hover {
            background: #ffffff;
            color: #005598;
        }
        .pg_plan .un_sim_planbtn.un_toSubscription .el_linkBtnWrap.el_linkBtnWrap__trial .el_linkBtn:hover::after {
            border-color: #005598;
        }
        .un_sim_col3btn {
            display: block;
            margin-top: 25px;
        }
        .un_sim_col3btn_item {
            flex-shrink: 0;
            width: 100%;
            margin-bottom: 20px;
        }
        .un_sim_col3btn_item:last-child {
            margin-bottom: 0;
        }
        .un_sim_col3btn_item_link{
            padding-top: 18px;
            padding-bottom: 18px;
            padding-left: 0!important;
            text-align: center;
        }
        .un_sim_col3btn_item_link::after {
            width: 10px;
            height: 10px;
            padding: 0;
        }
        .un_sim_col3btn_item_type03 .un_sim_col3btn_item_span{margin-left: -18px;}
        .un_sim_col3btn_item_type03 .un_sim_col3btn_item_span::before {
        margin-right: 5px;
        }
        
        .un_sim_col3btn_item_span {
            font-size: 16px;
            margin-left: -8px;
        }
        .un_sim_col3btn_item_span::before {
            content: "";
            display: inline-block;
            position: static;
            vertical-align: middle;
            margin-top: -4px;
            margin-right: 4px;
            width: 32px;
            height: 24px;
            padding-bottom: 0;
        }
        .un_sim_col3btn_item_type02 .un_sim_col3btn_item_span::before {
            margin-right: 5px;
        }
        .un_sim_col3btn_item_type03 .un_sim_col3btn_item_span::before {
            margin-right: 5px;
        }
    }
}

@use "sass:math";
@import "mixin";

.pg_news {
    .el_txt p {
    margin-bottom: 20px;
  }
	.bl_breadClumb {
		
		@include mediaQ(PC) {
			$mb: 39px;
			margin-bottom: $mb;
			margin-bottom: clamp(0px, per($mb, $widthContent), $mb);
		}
		// @include mediaQ(SP) {
		// 	margin-bottom: 26px;
		// }
	}

	.el_contentTtl {
		
		@include mediaQ(PC) {
			@include pcFS(34px);
			margin-bottom: 55px;
			margin-bottom: clamp(0px, per(55px, $widthContent), 55px);
		}
		@include mediaQ(SP) {
			margin-bottom: 48px;
		}
	}
	.bl_news {
        // background-color: $_bg_blue ;
        padding-bottom: per(30px, $widthContent);
        @include mediaQ(SP) {
            padding-bottom: 17px;
        }
        &_inner {
            @include mediaQ(PC) {
                width: 100%;
                max-width: $maxWidthContent;
                padding: per(45px, $widthContent) 20px 0;
                margin: 0 auto per(15px, $widthContent);
            }
            @include mediaQ(SP) {
                padding: 39px 5% 0;
                margin: 0 auto 20px;
            }

        }
            
        &_ttl {
            text-align: center;
            font-weight: bold;
            @include pcFS(40px, $widthContent, 32px);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include mediaQ(PC) {
                margin-bottom: per(45px, $widthContent);
            }
            @include mediaQ(SP) {
                margin-bottom: 14px;
            }

            &_en {
                display: inline-block;
                font-family: 'Roboto', sans-serif;
                @include pcFS(16px, $widthContent, 13px);
                color: $white;
                font-weight: 500;
                background-color: $hv_blue;
                border-radius: 15px;
                text-align: center;
                width: 7.5%;
                padding: 5px 0;
                margin-bottom: 10px;
                @include mediaQ(SP) {
                    width: 55px;
                    padding: 1px 0;
                    margin-bottom: 6px;
                }
            }
        }

        &_contents {
            @include mediaQ(PC) {
                margin-bottom: per(45px, $widthContent);
            }

            @include mediaQ(SP) {
                margin-bottom: 39px;
            }

           &_list {
               border-bottom: 1px solid #ccc;
               margin-bottom: 13px;

               &_date {
                   font-size: 16px;
                   font-weight: 600;
                   color: $blue;
                   margin-bottom: 9px;
               }

               &_ttl {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 17px;
                    line-height: 1.6;
                    display: block;
                    transition: .3s;
                    color: $black;
                    text-decoration: none;
                    @include mediaQ(SP) {
                        margin-bottom: 15px;
                    }

                    @include mediaQ(PC) {
                        &:hover {
                            color: $hv_blue;
                        }
                    }
                    
               }
           }
        }

        &_button {
            max-width: 320px;
            padding-bottom: 60px;
            padding-bottom: clamp(0px, 4.3859649123vw, 60px);
            @include mediaQ(SP) {
                max-width: 333px;     
                height: 60px;
            }
            &_link {
                @include mediaQ(PC) {
                    &:hover {
                        background-color: $blue;
                    }
                }
            }

        }
    }
}

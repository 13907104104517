@use "sass:math";
@import "mixin";

.pg__flow {
	
    .el_sp{
        display: none!important;
    }
    .el_link_blue {
        color: #2d8edb;
    }
    .el_link_underbar {
        text-decoration: underline;
    }
    .el_link_underbar:hover {
        text-decoration: none;
    }
    .el_hover {
        transition: 0.2s;
    }
    .el_hover:hover {
        opacity: 0.7;
    }
    
    
    .un_flow{
        font-size: 16px;
        line-height: 2;
        color: #222;
        margin-bottom: 64px;
    }
    .un_flow:nth-child(2){
        margin-bottom: 68px;
    }
    .un_flow:nth-child(3){
        margin-bottom: 66px;
    }
    .un_flow:last-child{
        margin-bottom: 79px;
    }
    .un_flow_head01{
        margin-bottom: 85px;
    }
    .un_flow_step_list{
        display: flex;
        justify-content: space-between;
    }
    .un_flow_step_list_item{
        position: relative;
        flex-shrink: 0;
        position: relative;
        padding-top: 63px;
        padding-bottom: 42px;
        width: 22.273%;
        border: 1px solid #CCC;
    }
    .un_flow_step_list_item::before {
        border-color: #C3C3C3;
        border-width: 2px;
        content: "";
        display: block;
        position: absolute;
        transform: translate(-50%, -50%) rotate(45deg);
        border-right-style: solid;
        border-top-style: solid;
        height: 0;
        padding-bottom: 5.306122449%;
        right: -12.2448979592%;
        top: 50%;
        width: 6.1224489796%;
    }	
    .un_flow_step_list_item:last-child::before {
        display: none;
    } 
    .un_flow_step_num{
        position: absolute;
        top: -41px;
        left: 50%;
        margin-left: -41px;
        width: 82px;
    }
    .un_flow_step_head{
        font-size: 20px;
        font-size: clamp(1rem, 1.754vw, 20px);
        font-weight: bold;
        text-align: center;
        line-height: 1.4;
    }
    .un_flow_step_head01{
        margin-bottom: 52px;
    }
    .un_flow_step_head02{
        margin-bottom: 11px;
    }
    .un_flow_step_head03{
        margin-bottom: 41px;
    }
    .un_flow_step_head04{
        margin-bottom: 12px;
    }
    .un_flow_step_img {
        text-align: center;
    }
    .un_flow_step_img01{
        margin-bottom: 5px;
    }
    .un_flow_step_img02{
        margin-bottom: 4px;
    }
    .un_flow_step_img03{
        margin-bottom: 6px;
    }
    .un_flow_step_img04{
        margin-bottom: 16px;
    }
    .un_flow_step_img img{
        display: inline-block;
    }
    .un_flow_step_img01 img{
        width: 62.14%;
      }
      .un_flow_step_img02 img {
        width: 53.5%;
      }
      .un_flow_step_img03 img{
        width: 37.04%;
      }
      .un_flow_step_img04 img{
        width: 61.7%;
      }
    .un_flow_step_txt{
        font-size: clamp(1rem, 1.2280701754vw, 14px);
        line-height: 1.71;
        padding-right: 25px;
        padding-left: 25px;
    }
    .un_flow_step_link_wrap{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }
    .un_flow_step_link  {
        display: block;
        background-color: #e5e5e5;
        line-height: 1;
        padding-top: 7px;
        padding-bottom: 6px;
    }
    .un_flow_step_link  img {
        display: inline-block;
    }
    .un_flow_head_wrap{
        text-align: center;
        font-size: 0;
        margin-bottom: 22px;
    }
    .un_flow_head_span{
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        font-size: 17px;
        font-weight: bold;
        color: #fff;
        background-color: #005598;
        width: 95px;
        line-height: 1;
        padding-top: 6px;
        padding-bottom: 7px;
        border-radius: 15px;
    }
    .un_flow_head{
        display: inline-block;
        vertical-align: middle;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.33;
    }
    .un_flow_col2_wrap{
        padding-top: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
        padding-left: 40px;
        background-color: #F2F7FA;
    }
    .un_flow_col2{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
    }
    .un_flow_col2_05 {
        margin-top: 56px;
    }
    .un_flow_col2:first-child {
        margin-top: 0;
    }
    .un_flow_col2_rev{
        flex-direction: row-reverse;
    }
    .un_flow_col2_img{
        flex-shrink: 0;
        width: 47.1%;
    }
    .un_flow_col2_cont{
        flex-shrink: 0;
        width: 50%;
    }
    .un_flow_col2_01 .un_flow_col2_cont {
        margin-top: -6px;
    }
   
    .un_flow_col2_03 .un_flow_col2_cont {
        margin-top: -7px;
    }

    .un_flow_col2_05 .un_flow_col2_cont {
        margin-top: -6px;
    }
    .un_flow_col2_06 .un_flow_col2_cont {
        margin-top: -8px;
    }
    .un_flow_col2_07 .un_flow_col2_cont {
        margin-top: -5px;
    }

    .un_flow_col2_head{
        margin-bottom: 12px;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.56;
    }
    .un_flow_col2_req{
        margin-top: 4px;
        font-size: 14px;
        padding-left: 1em;
        text-indent: -1em;
    }
    .un_flow_col2_req_no_indent{
        padding-left: 0;
        text-indent: 0;
    }
    .un_flow_wbox{
        margin-top: 30px;
        padding-top: 13px;
        padding-right: 20px;
        padding-bottom: 13px;
        padding-left: 20px;
        background-color: #fff;
        border: 1px solid #CCC;
    }
    .un_flow_wbox.un_flow_wbox02 {
        padding-top: 11px;
        padding-bottom: 11px;
    }
    .un_flow_wbox_req{
        font-size: 14px;
        padding-left: 1em;
        text-indent: -1em;
    }
    .un_sim_blue_list{
        margin-bottom: 18px;
    }
    .un_flow_col2_list.un_sim_blue_list {
        margin-top: 2px;
        margin-bottom: 6px;
    }
    .un_sim_blue_list_item{
        position: relative;
        padding-left: 25px;
        font-size: 16px;
        line-height: 2;
        margin-bottom: 3px;
    }
    .un_sim_blue_list_item:last-child {
        margin-bottom: 0;
    }
    .un_sim_blue_list_item::before {
        content: "";
        display: block;
        position: absolute;
        top: 13px;
        left: 2px;
        width: 8px;
        height: 8px;
        background-color: #005598;
        border-radius: 4px;
    }
    .un_flow_col2_mail {margin-top: 10px;}

    .el_mail_link::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-top: -4px;
        margin-right: 10px;
        width: 22px;
        height: 15px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url(../img/service/dokodemoWifiRental/flow/icon_mail.svg);
    }
    
    .un_flow_col2_form_wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 39px;
        padding-right: 20px;
    }
    .un_flow_col2_form{
        width: 48.24%;
    }
    .el_btn_blue {
        display: block;
        position: relative;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 9px;
        font-size: clamp(1rem, 1.228vw, 14px);
        font-weight: bold;
        color: #fff;
        background-color: #005598;
        border: 1px solid #005598;
        border-radius: 4px;
        text-align: center;
        transition: 0.3s;
    }
    .el_btn_blue:hover {
        color: #005598;
        background-color: #fff;
    }
    .el_btn_blue::after {
        border-right: solid 2px #fff;
        border-top: solid 2px #fff;
        content: "";
        display: block;
        position: absolute;
        width: 4.28%;
        padding-bottom: 3.42%;
        right: 4.28%;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        transform: translateY(calc(-50% + 1px)) rotate(45deg);
        transition: 0.3s;
    }
    .el_btn_blue:hover:after {
        border-color: #005598;
    }
    .un_flow_col2_qr{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 43.5%;
    }
    .un_flow_col2_qr_img{
        flex-shrink: 0;
        width: 31.6%;
    }
    .un_flow_col2_qr_txt{
        flex-shrink: 0;
        width: 68%;
        font-size: clamp(1rem, 1.053vw, 12px);
        font-weight: 500;
        line-height: 1.83;
    }
    .un_flow_btn_wrap {
        width: 530px;
        margin-top: 61px;
        margin-right: auto;
        margin-left: auto;
    }
    .un_flow_btn {
        display: block;
        position: relative;
        padding-top: 19px;
        padding-bottom: 19px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        border: 1px solid #005598;
        background-color: #005598;
        border-radius: 4px;
        transition: 0.2s;
        font-size: 20px;
    }
    .un_flow_btn:hover {
        color: #005598;
        background-color: #fff;
    }
    .un_flow_btn::after {
        border-right: solid 2px #fff;
        border-top: solid 2px #fff;
        content: "";
        display: block;
        height: 10px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        transform: translateY(calc(-50% + 1px)) rotate(45deg);
        transition: 0.3s;
        width: 10px;
        transition: 0.2s;
    }
    .un_flow_btn:hover::after {
        border-color: #005598;
    }
    
    @media screen and (max-width: 768px) {
      
        .el_pc{
            display: none!important;
        }
        .el_sp{
            display: block!important;
        }
        
        .un_flow_col2_form_wrap {
            display: block;
        }
        .un_flow_col2_form {
            width: 236px;
            margin-bottom: 20px;
        }
        .un_flow_step_list{
            display: block;
        }
        .un_flow_step_list_item{
            margin-bottom: 58px;
            padding: 61px 6% 48px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        .un_flow_step_list_item::before {
            display: none;
        }	
        .el_flow_height { 
            height: auto;
        }
        .un_flow_step_num{
            position: absolute;
            top: -41px;
            left: 50%;
            margin-left: -41px;
        }
        .un_flow_step_head{
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            line-height: 1.4;
        }
        .un_flow_step_head01{
            margin-bottom: 14px;
        }
        .un_flow_step_head02{
            margin-bottom: 10px;
        }
        .un_flow_step_head03{
            margin-bottom: 12px;
        }
        .un_flow_step_head04{
            margin-bottom: 11px;
        }
        .un_flow_step_img {
            text-align: center;
        }
        .un_flow_step_img01{
            margin-bottom: 4px;
        }
        .un_flow_step_img02{
            margin-bottom: 4px;
        }
        .un_flow_step_img03{
            margin-bottom: 6px;
        }
        .un_flow_step_img04{
            margin-bottom: 5px;
        }
        .un_flow_step_img img{
            display: inline-block;
        }
        .un_flow_step_img01 img{
            width: 151px;
        }
        .un_flow_step_img02 img {
            width: 130px;		
        }
        .un_flow_step_img03 img{
            width: 78px;
        }
        .un_flow_step_img04 img{
            width: 150px;		
        }
        .un_flow_step_txt{
            font-size: 14px;
            line-height: 1.71;
            padding-right: 20px;
            padding-left: 20px;
        }
        .un_flow_step_link_wrap{
            margin-top: 16px;
            text-align: center;
        }
        .un_flow_step_link  {
            display: block;
            background-color: #e5e5e5;
            line-height: 1;
            padding-top: 9px;
            padding-bottom: 9px;
        }
        .un_flow_step_link  img {
            display: inline-block;
        }
        
        .un_flow{
            font-size: 16px;
            line-height: 2;
            color: #222;
            margin-bottom: 61px;
        }
        .un_flow:nth-child(2){
            margin-bottom: 42px;
        }
        .un_flow:nth-child(3){
            margin-bottom: 40px;
        }
        .un_flow:nth-child(4){
            margin-bottom: 43px;
        }
        .un_flow:last-child{
            margin-bottom: 39px;
        }
        .un_flow_head01{
            margin-bottom: 85px;
            line-height: 1.54;
        }
        .un_flow_head_wrap{
            text-align: center;
            font-size: 0;
            margin-bottom: 22px;
        }
        .un_flow_head_span{
            display: inline-block;
            vertical-align: middle;
            margin-right: 11px;
            font-size: 13px;
            color: #fff;
            background-color: #005598;
            width: 70px;
            line-height: 1;
            padding-top: 5px;
            padding-bottom: 6px;
            border-radius: 12px;
        }
        .un_flow_head{
            display: inline-block;
            vertical-align: middle;
            font-size: 24px;
            font-weight: bold;
            line-height: 1.33;
            text-align: left;
        }
        .un_flow_col2_wrap{
            padding-top: 30px;
            padding-right: 18px;
            padding-bottom: 30px;
            padding-left: 18px;
            background-color: #F2F7FA;
        }
        .un_flow_col2{
            display: block;
            margin-top: 0;
            margin-bottom: 28px;
            padding-bottom: 28px;
            border-bottom:1px solid#CCCC;
        }
        .un_flow_col2_02 {
            border:none;
            margin-bottom:0;
            padding-bottom: 0;
        }
        .un_flow_col2_03 {margin-bottom: 28px;padding-bottom: 25px;}
        .un_flow_col2_05 {
            border: none;
            margin: 0;
            padding: 0;
        }
        .un_flow_col2_06 {
            border: none;
            margin: 0;
            padding: 0;
        }
        .un_flow_col2_08 {
            border: none;
            margin: 0;
            padding: 0;
        }	
        .un_flow_col2:first-child {
            margin-top: 0;
        }
        .un_flow_col2_img{
            width: 100%;
            margin-bottom: 14px;
        }
        .un_flow_col2_cont{
            width: 100%;
        }
        .un_flow_col2_01 .un_flow_col2_cont {
            margin-top: 0;
        }
        .un_flow_col2_02 .un_flow_col2_cont {padding-left: 3px;}
        .un_flow_col2_03 .un_flow_col2_cont {
            margin-top: 0;
        }

        .un_flow_col2_05 .un_flow_col2_cont {
            margin-top: 0;
        }
        .un_flow_col2_06 .un_flow_col2_cont {
            margin-top: 0;
        }
        .un_flow_col2_07 .un_flow_col2_cont {
            margin-top: 0;
        }
        .un_flow_col2_08 .un_flow_col2_cont {padding-top: 3px;}
        .un_flow_col2_head{
            margin-bottom: 7px;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.56;
        }
        .un_flow_col2_req{
            margin-top: 6px;
            font-size: 14px;
            padding-left: 1em;
            text-indent: -1em;
        }
        .un_flow_col2_03 .un_flow_col2_req {
            display: block;
            margin-top: 1px;
        }
        .un_flow_col2_req_no_indent{
            padding-left: 1em;
            text-indent: -1em;
        }
        .un_flow_wbox{
            margin-top: 21px;
            padding-top: 18px;
            padding-right: 20px;
            padding-bottom: 21px;
            padding-left: 19px;
            background-color: #fff;
            border: 1px solid #CCC;
        }
        .un_flow_wbox.un_flow_wbox_sp02 {
            margin-top: 17px;
            padding-bottom: 17px;
        }
        .un_flow_wbox.un_flow_wbox02 {
            padding-top: 18px;
            padding-bottom: 18px;
            margin-top: 24px;
        }
        .un_flow_wbox_req{
            font-size: 13px;
            padding-left: 1em;
            text-indent: -1em;
            line-height: 1.84;
        }
        .un_flow_wbox.un_flow_wbox_sp02 .un_flow_wbox_req {
            font-size: 14px;
            line-height: 2;
        }
        .un_flow_wbox.un_flow_wbox02 .un_flow_wbox_req {
            font-size: 14px;
            line-height: 2;
        }
        .un_sim_blue_list{
            margin-bottom: 18px;
        }
        .un_flow_col2_list.un_sim_blue_list {
            margin-top: 2px;
            margin-bottom: 6px;
        }
        .un_sim_blue_list_item{
            position: relative;
            padding-left: 25px;
            font-size: 16px;
            line-height: 2;
            margin-bottom: 3px;
        }
        .un_sim_blue_list_item:last-child {
            margin-bottom: 0;
        }
        .un_sim_blue_list_item::before {
            content: "";
            display: block;
            position: absolute;
            top: 13px;
            left: 2px;
            width: 8px;
            height: 8px;
            background-color: #005598;
            border-radius: 4px;
        }
        .un_flow_col2_mail {
            margin-top: 4px;
            padding-left: 2px;
        }
        .el_mail_link::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            margin-top: -4px;
            margin-right: 10px;
            width: 22px;
            height: 15px;
        }
        .un_flow_col2_form_wrap{
            display: block;
            margin-top: 16px;
            padding-right: 0;
        }
        .un_flow_col2_form{
            width: 100%;
            margin-bottom: 27px;
        }
        .el_btn_blue {
            display: block;
            position: relative;
            width: 100%;
            padding-top: 11px;
            padding-bottom: 11px;
            padding-left: 0px;
            font-size: 18px;
            border-radius: 4px;
        }
        .un_flow_col2_qr{
            display: block;
            width: 100%;
        }
        .un_flow_col2_qr_img{
            flex-shrink: 0;
            width: 110px;
            margin-right: auto;
            margin-bottom: 16px;
            margin-left: auto;
        }
        .un_flow_col2_qr_img img {
            width: 100%;		
        }
        .un_flow_col2_qr_txt{
            width: 100%;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.875;
            text-align: center;
        }
        .un_flow_btn_wrap {
            width: 100%;
            margin-top: 40px;
        }
        .un_flow_btn {
            display: block;
            position: relative;
            padding-top: 18px;
            padding-bottom: 18px;
            border-radius: 4px;
            transition: 0.2s;
            font-size: 16px;
        }
    }
    
}

@charset "utf-8";
@use "sass:math";
@import "mixin";

/* =========================================================
　◆Link Style
========================================================= */
a {
	color: $black;
	text-decoration: none;
	// color: $hv_blue;
	// text-decoration: underline;
	&:hover {
		// text-decoration: none;
		img { opacity: 0.8; }
	}

	@include mediaQ(PC) {
		&[href*="tel:"] {
			pointer-events: none;
			cursor: default;
			text-decoration: none;
		}
	}
}

p a {
	color: $hv_blue;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
		img { opacity: 0.8; }
	}

	@include mediaQ(PC) {
		&[href*="tel:"] {
			pointer-events: none;
			cursor: default;
			text-decoration: none;
		}
	}
}

/* =========================================================
　◆Basic
========================================================= */
* { box-sizing: border-box;
	&:before, &:after { box-sizing: border-box; }
}
html { font-size: 10px; -webkit-text-size-adjust: none; }
body { font: 13px / 1.4 'Noto Sans JP', sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
main, aside { display: block; }
html.is-fixed,
html.is-fixed body {
	@include mediaQ(SP) {
		height: 100%;
		overflow: hidden;
	}
}

img { max-width: 100%; height: auto; width: auto; display: block; }
figure { border: 0; outline: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

/* pcBlock,pcInline */
.pcBlock { display: block !important; }
.pcInline { display: inline-block !important; }
.spBlock, .spInline { display: none !important; }

.fw-b {
	font-weight: bold;
}
.ta-c {
	text-align: center;
}
.ta-r {
	text-align: right;
}



.ly {
	&_inner {
		@include mediaQ(PC) {
			width: 100%;
			max-width: $maxWidthContent;
			padding: 0 20px;
			margin: 0 auto;
		}
		@include mediaQ(SP) {
			width: per(335px, 375px);
			margin: 0 auto;
		}
		&__page {
			@include mediaQ(PC) {
				margin-bottom: per(80px, $widthContent);
			}
			@include mediaQ(SP) {
				margin-bottom: 40px;
			}
		}
	}

	&_contents {
		@include mediaQ(PC) {
			width: 72%;
		}
	}

	&_head {

		&_main {
			position: fixed;
			top: 0;
			z-index: 1;
			width: 100%;
			background-color: $white;
			@include mediaQ(PC) {
				height: $heightHeader;
			}
			@include mediaQ(SP) {
				height: $heightHeaderSP;
			}
		}

		&_inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: 1%;
			padding-right: 1.5%;
			@include mediaQ(SP) {
				padding: 11px 10px 5px 15px;
			}

			&_logo {

				@include mediaQ(PC) {
					// width: 12.5%;
					width: 188px;
					padding-top: 18px;
					img {
						width: 100%;
					}
				}
				@include mediaQ(SP) {
					// width: 36%;
					width: 126px;
					max-width: 161px;
				}

			}
		}
	}
	&_main {
	}
	&_underpage {
		position: relative;

		@include mediaQ(PC) {
			background: url("/assets/img/bg_underPage_top_pc.png") no-repeat center top;
			$bgSize: 1920px;
			background-size: per($bgSize, $maxWidthContent) auto;
			background-size: clamp(751px, per($bgSize, $maxWidthContent), $bgSize) auto;
			margin-top: $heightHeader;
		}
		@include mediaQ(PC_O) {
			background-size: contain;
			background-position : center -1%;
		}

		@include mediaQ(SP) {
			background: url("/assets/img/bg_underPage_top_sp.png") no-repeat -94px -40px;
			margin-top: 60px;
		}
		&_bottomBg {
			position: relative;
			&::before,
			&::after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				z-index: -1;
				width: 100%;
				height: 0;
			}
			&::before {
				@include mediaQ(PC) {
					$bgSize: 1920px;
					$top: -350px;
					top: $top;
					top: clamp($top, convVW($top, $maxWidthContent), 0px);
					padding-top: 523px;
					padding-top: clamp(0px, convVW(523px, $maxWidthContent), 523px);
					background: url("/assets/img/bg_underPage_bottom_pc.png") no-repeat center bottom;
					background-size: per($bgSize, $maxWidthContent) auto;
					background-size: clamp(751px, per($bgSize, $maxWidthContent), $bgSize) auto;
				}
				@include mediaQ(SP) {
					top: convVW(-300px, 375px);
					background: url("/assets/img/bg_underPage_bottom_sp.png") no-repeat center top / contain;
					padding-top: per(584px, 375px);
				}
			}
			&::after {
				bottom: 0;
				background-color: #F2F7FA;
				@include mediaQ(PC) {
					height: calc(100% - 70px);
					height: calc(100% - clamp(0px, convVW(70px, $maxWidthContent), 70px));
				}
				@include mediaQ(SP) {
					height: calc(100% - clamp(0px, convVW(130px, 375px), 130px));
				}
			}
		}
	}

	&_foot {
		&_inner {
			background-color: $ft_gray;
		}

		&_contents {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			a {
				color: $black;
				text-decoration: none;
			}
			@include mediaQ(PC) {
				a {
					transition: .3s;
					&:hover {
						color: $hv_blue;
					}

				}

				@include pcPAD_P((35px 20px 10px));
			}

			@include mediaQ(SP) {
				flex-direction: column;
				padding: 28px 0 12px;
			}
			&_service {
				width: 50%;
				@include mediaQ2(769, 1000) {
					width: 60%;
				}
				display: flex;
				flex-wrap: wrap;
				@include mediaQ(SP) {
					width: 100%;
					flex-direction: column;
					margin-bottom: 23px;
					border-bottom: 1px solid #ccc;
					padding-bottom: 18px;
				}

				&_parents {
					width: 100%;
					@include mediaQ(PC) {
						margin-bottom: per(45px, $widthContent);
					}
					@include mediaQ(SP) {
						margin-bottom: 15px;
					}
					&_link {
						font-weight: bold;
						@include pcFS(16px, $widthContent, 15px);
					}
				}

				&_child {
					width: 50%;
					@include mediaQ(SP) {
						width: 100%;
					}
					&_list {
						font-size: 14px;
						font-weight: 500;
						margin-bottom: 13px;
						@include mediaQ(SP) {
							margin-bottom: 12px;
						}


						&_ul {
							$second: '.ly_foot_contents_service_child_list_ul__second';
							@include mediaQ(PC) {
								@include pcMGN_P((15px 0 80px));
							}
							&#{$second} {
								@include mediaQ(PC) {
									@include pcMGN_P((15px 0 60px));
								}
							}


							&_li {
								// margin-right: 47px;
								&_link {
									$cloud: '.ly_foot_contents_service_child_list_ul_li_link__cloud';
									font-size: 14px;
									font-weight: 400;
									display: flex;
									align-items: center;
									padding: 3px 0;
									@include mediaQ(SP) {
										padding: 5px 0;

										&#{$cloud} {
											padding: 11px 0;
										}
									}

									&::before {
										border-top: 2px solid $blue;
										content: "";
										margin-right: 0.6em;
    									width: 0.6em;
										@include mediaQ(SP) {
											margin-right: 0.7em;
    										width: 0.7em;
										}
									  }
								}
							}
						}

					}
				}
			}

			&_menu {
				$first: '.ly_foot_contents_menu__first';
				@include mediaQ(PC) {
					width: 25%;
				}

				@include mediaQ2(769, 1000) {
					width: 22%;
				}


				@include mediaQ(SP) {
					width: 100%;
				}

				&#{$first} {

					@include mediaQ2(769, 1000) {
						width: 18%;
					}
					@include mediaQ(SP) {
						padding-bottom: 12px;
						margin-bottom: 22px;
						border-bottom: 1px solid #ccc;
					}
				}

				&_list {
   					margin-bottom: 4px;
					@include mediaQ(SP) {
						margin-bottom: 11px;
					}
					&_link {
						font-size: 15px;
						font-weight: bold;
						padding: 5px 0px;
    					display: block;
					}
				}
			}
		}

		&_bottom {
			display: flex;
			@include mediaQ(PC) {
				width: 38%;
				max-width: 800px;
				min-width: 737px;
				@include mediaQ(PC) {
					min-width: 737px;
					@include pcPAD_P((30px 0 34px));
				}
				margin: 0 auto;
				justify-content: space-between;
				align-items: center;
			}
			@include mediaQ(SP) {
				width: per(335px, 375px);
				margin: 0 auto;
				display: block;
			}

			&_logo {

				@include mediaQ(PC) {
					position: relative;
					left: 3px;
				}
				@include mediaQ(SP) {
					margin-bottom: 8px;

					img {
						margin: auto;
					}
				}
			}

			&_info {
				@include mediaQ(SP) {
					padding-bottom: 30px;
				}
				&_company {
					font-size: 24px;
					font-weight: bold;
					@include mediaQ(PC) {
						margin-bottom: 4px;
					}
					@include mediaQ(SP) {
						margin-bottom: 8px;
					}
				}

				&_address {
					font-size: 16px;
					line-height: 1.5;
					@include mediaQ(PC) {
						margin-bottom: 2px;
					}
					@include mediaQ(SP) {
						font-size: 14px;
						line-height: 1.8;
					}
				}

				&_tool {
					font-size: 16px;
					@include mediaQ(SP) {
						font-size: 14px;
					}

					&_mail {
						margin-left: 28px;
						@include mediaQ(SP) {
							margin-left: 20px;
						}
					}
				}
			}

		}

		&_copy {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			border-top: 1px solid #CCCCCC;
			font-family: 'Roboto', sans-serif;
			font-size: 14px;
			color: #A2A2A2;
			@include mediaQ(SP) {
				font-size: 12px;
			}
		}
	}
}

.el{

	&_input {
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		border: 1px solid #ccc;
		border-radius: 0;
		color: $black;
		font-size: 14px;
		height: 36px;
		padding: 0.4em 0.8em;
		@include mediaQ(SP) {
			font-size: 13x;
			padding: 0.4em 0.5em;
		}

		&:focus {
			outline: none;
		}
	}

	&_internal_mark {
		display: block;
		position: relative;
		width: 45px;
		cursor: pointer;
		&::before {
			content: "";
			display: block;
			position: absolute;
			width: 10px;
			height: 10px;
			border: 0px;
			border-top: solid 2px $white;
			border-right: solid 2px $white;
			right: 9%;
    		top: 56%;
			@include mediaQ(PC) {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
			@include mediaQ(SP) {
				top: 49%;
				transform: translate(-50%, -50%) rotate(135deg);
			}
		}
		// &::before {
		// 	width: 16px;
		// 	height: 2px;
		// }

	}



	&_pageTtl {
		font-weight: bold;
		@include mediaQ(PC) {
			@include pcFS(40px);
		}
		@include mediaQ(SP) {
			font-size: 30px;
		}
		&_en {
			display: block;
			color: #2D8EDB;
			font-family: 'Roboto', sans-serif;
			font-weight: 500;
			@include mediaQ(PC) {
				@include pcFS(18px);
			}
			@include mediaQ(SP) {
				font-size: 14px;
			}
		}
	}
	&_contentTtl {
		position: relative;
		font-weight: bold;
		text-align: center;
		@include mediaQ(PC) {
			@include pcFS(34px);
			margin-bottom: 50px;
			margin-bottom: clamp(0px, per(50px, $widthContent), 50px);
		}
		@include mediaQ(SP) {
			font-size: 26px;
			margin-bottom: 43px;
		}
		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 50%;
			display: block;
			height: 3px;
			@include mediaQ(PC) {
				bottom: -13px;
			}
			@include mediaQ(SP) {
				bottom: -17px;
			}
		}
		&::before {
			width: 40px;
			background: #2D8EDB;
			transform: translateX(-50%);
		}
		&::after {
			width: 20px;
			background: #005598;
		}
	}
	&_contentSubTtl {
		position: relative;
		font-weight: bold;
		@include mediaQ(PC){
			@include pcFS(26px);
			padding-left: per(41px, $widthContent);
		}
		@include mediaQ(SP){
			font-size: 22px;
			padding: 0 0 2px 30px;
		}
		&::before,
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			top: calc(50% + 1px);
			left: 0;
			@include mediaQ(PC) {
				width: per(28px, $widthContent);
			}
			@include mediaQ(SP) {
				width: 20px;
			}
		}
		&::before {
			background: $hv_blue;
			transform: translateY(-50%);
			@include mediaQ(PC) {
				padding-bottom: per(28px, $widthContent);
			}
			@include mediaQ(SP) {
				height: 20px;
			}
		}
		&::after {
			background: $blue;
			@include mediaQ(PC) {
				padding-bottom: per(14px, $widthContent);
			}
			@include mediaQ(SP) {
				height: 10px;
			}
		}
	}
	&_txt {
		line-height: 2;
		font-size: 16px;
		// @include mediaQ(PC){
		// 	@include pcFS(16px);
		// }
		// @include mediaQ(SP){
		// 	font-size: 16px;
		// }
	}
	&_noteTxt {
		line-height: math.div(24px, 13px);
		@include mediaQ(PC){
			@include pcFS(13px);
		}
		@include mediaQ(SP){
			font-size: 13px;
		}
	}
	&_link {
		&Txt {
			color: $hv_blue;
			text-decoration: underline;
			@include mediaQ(PC){
				transition: .3s;
				&:hover {
					opacity: 0.6;
				}
			}
		}
		&Btn {
			$linkBtn: &;
			&Wrap {
				position: relative;
				margin: 0 auto;
				height: 0;
				@include mediaQ(PC){
					display: block;
					max-width: 250px;
					width: convVW(250px, $maxWidthContent);
					padding-bottom: 50px;
					padding-bottom: clamp(0px, convVW(50px, $maxWidthContent), 50px);
				}
				@include mediaQ(SP){
					height: 50px;
				}
				&#{&}__noSize {
					@include mediaQ(PC){
						max-width: none;
						width: 100%;
						padding-bottom: 0;
						margin: 0;
					}
					@include mediaQ(SP){
						height: auto;
					}
				}
				&#{&}__trial {
					@include mediaQ(PC){
						max-width: 400px;
						width: convVW(400px, $maxWidthContent);
						padding-bottom: 70px;
						padding-bottom: clamp(0px, convVW(70px, $maxWidthContent), 70px);
						}
				}
			}
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			color: $white;
			font-weight: bold;
			background: $blue;
			border: solid 1px $blue;
			border-radius: 4px;
			margin: 0 auto;
			transition: .3s;
			text-decoration: none;
			@include mediaQ(PC){
				@include pcFS(16px);
			}
			@include mediaQ(SP){
				font-size: 16px;
			}
			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				right: 17px;
				width: 10px;
				height: 10px;
				border-top: solid 2px $white;
				border-right: solid 2px $white;
				transform: translateY(-50%) rotate(45deg);
				transform: translateY(calc(-50% + 1px)) rotate(45deg);
				transition: .3s;
			}
			&:hover {
				color: $blue;
				background: $white;
				&::after {
					border-color: $blue;
				}
			}
			&Wrap#{&}Wrap__trial {
				#{$linkBtn} {
					color: $white;
					background: $orange;
					border-color: $orange;
					text-align: center;
					&::after {
						border-color: $white;
					}
					@include mediaQ(PC){
						&:hover {
							color: $orange;
							background: $white;
							&::after {
								border-color: $orange;
							}
						}
					}
				}
			}
		}
	}
}

.un_sp_cart {
	width: 44px;
	height: 44px;
    z-index: 12;
	position: absolute;
    top: 8px;
    right: 70px;
	a {
		width: 100%;
	}
}

.bl {

	&_nav {
		@include mediaQ(PC) {
			// width: 41%;
			width: 760px;
			min-width: 670px;
		}

		@include mediaQ(SP) {
			overflow: auto;
			width: 100%;
			// max-height: 100vh;
			opacity: 0;
			transition: .3s;
			position: fixed;
			top: $heightHeaderSP;
			left: 0;
			z-index:11;
			background: $white;
			pointer-events: none;
			display: flex;
			// flex-wrap: wrap;
			flex-direction: column;
			overflow-y: auto;
   			height: calc(100vh - $heightHeaderSP);
		}

		&_form {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			@include mediaQ(PC) {
				margin-bottom: 17px;
			}
			@include mediaQ(SP) {
				order: 1;
				flex-direction: column;
				padding: 27px 0;
			}

			&_list {
				@include mediaQ(SP) {
					width: 90%;
					margin: auto;
				}

				&:first-child, &:nth-child(2) {
					margin-right: 1.4%;
					@include mediaQ(SP) {
						margin-right: auto;
					}
				}
				&:not(:last-of-type) {
					@include mediaQ(SP) {
						margin-bottom: 20px;
					}
				}
				&_link {

					@include mediaQ(PC) {
						font-size:13px;
						@include btn_part01 (120px, 40px, $white, $blue);
						border-radius: 0 0 4px 4px;
					}
					@include mediaQ(SP) {
						font-size: 18px;
						@include btn_part01 (100%, 60px, $white, $blue);
					}

					&__apply {
						@include mediaQ(PC) {
							@include btn_part01 (140px, 40px, $white, $green);
						}

						@include mediaQ(SP) {
							@include btn_part01 (100%, 60px, $white, $green);
						}
					}

					&__login {
						@include mediaQ(PC) {
							@include btn_part01 (150px, 40px, $white, $green);
							border-radius: 0 0 4px 4px;
						}
						@include mediaQ(SP) {
							@include btn_part01 (100%, 60px, $white, $black);
						}

						&:before {
							content: "";
							width: 21px;
							height: 21px;
							background: url("/assets/img/icon_cart_green_01.svg") no-repeat center center;
							background-size: 21px 21px;
							margin-right: 6px;
						}

						&:hover {
							&:before {
								content: "";
								width: 21px;
								height: 21px;
								background: url("/assets/img/icon_cart_green_02.svg") no-repeat center center;
								background-size: 21px 21px;
								margin-right: 6px;
							}
						}
					}

				}

			}
		}
		&_menu {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include mediaQ(SP) {
				flex-direction: column;
				width: 100%;
			}

			&_list {
				@include mediaQ(SP) {
					width: 100%;
					background: $black;
					border-bottom: 1px solid $gray;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;

				}
				&_link {
					font-weight: bold;
					padding-bottom: 23px;
					position: relative;
					transition: .3s;
					color: $black;

					@include mediaQ(PC) {
						font-size:16px;
						cursor: pointer;
					}
					@include mediaQ(SP) {
						font-size: 18px;
						color: $white;
						padding: 20px;
						width: 85%;
						display: block;
					}

					&:hover {
						color: $hv_blue;
					}
				}

				&:first-child {
					@include mediaQ(PC) {
						width: auto;
						margin-right: 20px;

						&:hover {
							.bl_nav_menu_list_child__service {
								opacity: 1;
								pointer-events: all;
							}
						}
					}
				}
				&:nth-child(2) {
					@include mediaQ(PC) {
						width: auto;
						margin-right: 20px;

						&:hover {
							.bl_nav_menu_list_child__plan {
								opacity: 1;
								pointer-events: all;
							}
						}
					}
				}

				&:first-child &_link::before , &:nth-child(2) &_link::before{
					@include mediaQ(PC) {
						position: absolute;
						content: "";
						transition: all .4s;
						width: 10px;
						height: 10px;
						border: 0px;
						border-top: solid 2px $black;
						border-right: solid 2px $black;
						top: 22%;
						right: -24%;
						transform: translate(-50%, -50%) rotate(135deg);
					}
					// @include mediaQ(SP) {
					// 	border-color: $white;
					// 	right: 4%;
    				// 	top: 45%;
					// }
				}

				&.is_open {
					.el_internal_mark {
						&::before {
							@include mediaQ(PC) {
								transform: translate(-50%, -50%) rotate(135deg);
							}
							@include mediaQ(SP) {
								transform: translate(-50%, -50%) rotate(-45deg);
							}
						}
					}
				}

				&_child {
					// position: absolute;
					// left: 0;
					// top: $heightHeader;
					width: 100%;
					padding: 30px 0;
					// background: rgba(34, 34, 34, 0.9);
					color: $white;

					@include mediaQ(PC) {
						pointer-events: none;
						opacity: 0;
						position: absolute;
						left: 0;
						top: $heightHeader;
						background: rgba(34, 34, 34, 0.9);
						transition: .3s;
					}

					@include mediaQ(SP) {
						display: none;
						background: $nav_gray;
						padding: 6% 5.5%;
					}

					$plan: ".bl_nav_menu_list_child__plan";



					&__plan {
						&:hover {
							opacity: 1;
						}
					}

					&_inner {
						@include mediaQ(PC) {
							width: $widthContent;
							margin: auto;
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;

							#{$plan} & {
								justify-content: flex-start;

							}
						}

						.un_service_left {
							@include mediaQ(PC) {
								width: 31%;
							}

							.bl_nav_menu_list_child_list {
								@include mediaQ(PC) {
									width: 100%;
								}
							}

							.bl_nav_menu_list_child_list_dd_list_second {
								@include mediaQ(PC) {
									width: 100%;
								}
							}
						}
					}

					&_linkWrap {
						width: 100%;
						margin-bottom: 30px;
						#{$plan} & {
							@include pcFS(24px);
							color: $white;
							font-weight: bold;
							border: 1px solid $white;
							text-align: center;
							padding: 1.1% 0;
							margin-bottom: 25px;

						}
						&_link {
							display: block;
							@include pcFS(24px);
							color: $white;
							font-weight: bold;
							border: 1px solid $white;
							text-align: center;
							padding: 1.1% 0;
							transition: .2s;

							&_txt {
								position: relative;
								transition: .3s;
								display: inline-block;
								left: -2%;

								&::before, &:after{
									content: "";
									position: absolute;
									top: 3px;
									bottom: 0;
									right: -47px;
									margin: auto;
									vertical-align: middle;
									transition: .3s;
								}

								&::before{
									box-sizing: border-box;
									width: 25px;
									height: 25px;
									border: 1px solid $white;
									-webkit-border-radius: 50%;
									border-radius: 50%;
								}
								&::after{
									right: -37px;
									width: 6px;
									height: 6px;
									border-top: 1px solid $white;
									border-right: 1px solid $white;
									-webkit-transform: rotate(45deg);
									transform: rotate(45deg);
								}
							}



							&:hover {
								color: $black;
								background-color: $white;

								& .bl_nav_menu_list_child_linkWrap_link_txt::before {
									border: 1px solid $black;
								}

								& .bl_nav_menu_list_child_linkWrap_link_txt::after {
									border-color: $black;
								}
							}
						}
					}

					&_list {
						width: 31%;
						#{$plan} & {
							margin-right: 6%;
   							margin-left: -0.5%;
						}

						@include mediaQ(SP) {
							width: 100%;
						}
						&_dt {
							font-size: 16px;
							font-weight: bold;
							border-bottom: 1px solid $white;
							padding-bottom: 6px;
							margin-bottom: 4px;
						}

						&_dd {
							&_list {
								margin-bottom: 20px;
								&_item {
									&_link {
										position: relative;
										color: $white;
										font-size: 16px;
										display: block;
										padding: 7px 0 7px 21px;
										transition: .3s;

										&::before{
											position: absolute;
											top: 0;
											bottom: 0;
											margin: auto;
											content: "";
											vertical-align: middle;
											left: 2px;
											width: 8px;
											height: 8px;
											border-top: 1px solid $white;
											border-right: 1px solid $white;
											-webkit-transform: rotate(45deg);
											transform: rotate(45deg);
										}

										&:hover {
											color: $hv_lightblue;
										}
									}
								}

								&_second {
									width: 31.5%;
									@include mediaQ(SP) {
										width: 100%;
									}

									.bl_nav_menu_list_child_list_dd_list_item_link {
										padding: 0 0 6px 21px;
									}
								}

								&_child {
									padding-left: 20px;
									margin-bottom: 10px;
									.bl_nav_menu_list_child_list_dd_list_item_link {
										padding-top: 4px;
									}
								}
							}


							// &_list.second {
							// 	width: 31.5%;
							// 	@include mediaQ(SP) {
							// 		width: 100%;
							// 	}

							// 	.bl_nav_menu_list_child_list_dd_list_item_link {
							// 		padding: 0 0 6px 21px;
							// 	}
							// }
							// &_list.child {
							// 	padding-left: 20px;
							// 	margin-bottom: 10px;
							// 	.bl_nav_menu_list_child_list_dd_list_item_link {
							// 		padding-top: 4px;
							// 	}
							// }

							&_calc {
								background-color: $white;
								width: 31%;
								padding: 13px 2%;
								text-align: center;
								max-height: 238px;


								@include mediaQ(SP) {
									width: 100%;
									max-height: none;
									// padding: 19px 7%;
									@include pcPAD_P((38px 46px), 768px);
								}
								@include mediaQ2(400, 768) {
									width: 89%;
								}

								&_date {
									background-color: $blue;
									color: $white;
									font-size: 13px;
									font-weight: 500;
									padding: 3px 0;
									width: 81%;
									margin: 0 auto 8px;

									span {
										font-size: 21px;
										@include mediaQ(SP) {
											@include pcFS(34px, 768px, 21px);
										}
									}
									@include mediaQ(SP) {
										@include pcFS(20px, 768px, 13px);
										padding: 5px 0;
										min-width: 258px;
										max-width: 400px;
    									width: 88%;
										margin: 0 auto per(8px, 375px);
									}
								}

								&_st {
									@include pcFS(24px);
									color: $black;
									line-height: 1.3;
									margin-bottom: 9px;
									@include mediaQ(SP) {
										// font-size: 24px;
										@include pcFS(40px, 768px, 24px);
										margin-bottom: per(9px, 375px);
									}
								}

								&_txt {
									font-size: 12px;
									color: $black;
									text-align: left;
									margin-bottom: 13px;
									@include mediaQ(SP) {
										// font-size: 24px;
										@include pcFS(20px, 768px, 12px);
										margin-bottom: per(13px, 375px);
									}
								}

								&_linkwrap {
									&_link {
										position: relative;
										font-size: 16px;
										color: $orange;
										border: 1px solid $orange;
										margin: auto;
										@include btn_part02 (288px, 50px, $white, $orange);

										@include mediaQ(SP) {
											@include pcFS(23px, 768px, 16px);
											@include btn_part02 (100%, math.div(50px, 375px) * 100 + vw, $white, $orange);
										}
										text-decoration: none;

										&::before {
											@include mediaQ(SP) {
												height: 9vw;
												width: 10%;
											}
										}
										&::after {
											@include mediaQ(SP) {
												right: 5%;
											}
										}

									}
								}

							}
						}
					}
				}
			}


		}
	}


	&_btn {
		width: 38px;
		height: 21px;
		position: relative;
		z-index: 12;

		&:hover {
			cursor : pointer;
		}

		&_top {
			@include btn_item(-10px);
			transition: .3s;

		}
		&_middle {
			@include btn_item(0);
			transition: .3s;

		}
		&_bottom {
			@include btn_item(10px);
			transition: .3s;
		}
	}

	&_bg {
		position: fixed;
		left: 0;
		top: $heightHeaderSP;
		width: 100vw;
		height: 100vh;
		z-index: 10;
		background-color: #000;
		opacity: 0;
		transition: all .3s;
		cursor: pointer;
		pointer-events: none;
	}

	&_pageTtl {
		&_inner {
			@include mediaQ(PC) {
				padding: calc(5.3% - 20px) 0 0.9%;
				margin-bottom: per(20px, $widthContent);
			}
			@include mediaQ(SP) {
				padding: 34px 0 48px;
				margin-bottom: 12px;
			}
		}
	}
	&_breadClumb {
		display: flex;
		flex-wrap: wrap;
		@include mediaQ(PC) {
			@include pcFS(14px);
			$mb: 48px;
			margin-bottom: $mb;
			margin-bottom: clamp(0px, per($mb, $widthContent), $mb);
		}
		@include mediaQ(SP) {
			font-size: 12px;
			margin-bottom: 26px;
		}
		&_item {
			&:not(:last-of-type) {
				&::after {
					content: ">";
					display: inline-block;
					color: #6F6F6F;
					@include mediaQ(PC) {
						margin: 0 5px;
					}
					@include mediaQ(SP) {
						margin: 0 2px 0 4px;
					}
				}
			}
		}
		$bci: #{&}_item;
		&_link {
			color: #6F6F6F;
			#{$bci}:last-of-type & {
				pointer-events: none;
			}
		}
	}
	&_flow {
		text-align: center;
		&_inner {
			display: inline-flex;
			justify-content: center;
			@include mediaQ(SP) {
				flex-direction: column;
				width: 100%;
			}
		}
		&_item {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			color: #999;
			font-weight: bold;
			background: #fff;
			border: solid 2px #999;
			@include mediaQ(PC) {
				@include pcFS(18px);
				border-radius: 32px;
				height: clamp(48px, convVW(64px, $widthContent), 64px);
			}
			@include mediaQ(SP) {
				height: 44px;
				font-size: 16px;
				border-radius: 32px;
			}
			&:not(:last-of-type)::after{
				content: "";
				display: block;
				position: absolute;
				background: #999;
				@include mediaQ(PC) {
					top: 50%;
					left: 100%;
					height: 3px;
					width: 100%;
				}
				@include mediaQ(SP) {
					top: 100%;
					left: 50%;
					width: 3px;
					height: 100%;
				}
			}
			&.is_current {
				color: $white;
				background: $hv_blue;
				border-color: $hv_blue;
				&::after {
					background: $hv_blue;
				}
			}
			&.is_completed {
				color: $hv_blue;
				border-color: $hv_blue;
				&::after {
					background: $hv_blue;
				}
			}
		}
	}
	&_follow {
		opacity: 0;
		transition: .3s;
		position: fixed;
		bottom: 63px;
		right: 27px;
		z-index: 9;
		width: 297px;
		display: none;

		@include mediaQ(SP) {
			width: 100%;
			bottom: 0;
			right: 0;
		}

		&_btn {
			margin: 0 0px 5px auto;
			width: 6%;
			cursor: pointer;
		}

		&_link {
			width: 100%;
			text-align: center;
			background: $white;
			padding: 15px 20px;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
			@include mediaQ(SP) {
				display: flex;
				justify-content: space-between;
			}
			&_txt {
				font-size: 15px;
				font-weight: bold;
				margin-bottom: 5px;
			}

			&_item {
				@include mediaQ(SP) {
					width: 48%;
				}
				&_link {
					@include btn_part01 (250px, 45px, $white, $green);
					@include mediaQ(PC) {
						margin-bottom: 5px;
						@include btn_part01 (250px, 45px, $white, $green);
					}
					@include mediaQ(SP) {
						@include btn_part01 (100%, 45px, $white, $green);
					}

					&__contact {
						@include mediaQ(PC) {
							@include btn_part01 (250px, 45px, $white, $blue);
						}
						@include mediaQ(SP) {
							@include btn_part01 (100%, 45px, $white, $blue);
						}
					}
				}
			}
		}

	}
	&_linkCols {
		@include mediaQ(PC) {
			display: flex;
			justify-content: space-between;
		}
	}
	&_noteList {
		&_item {
			padding-left: 1em;
			text-indent: -1em;
			@include mediaQ(SP) {
				&:not(:last-of-type) {
					margin-bottom: 5px;
				}
			}
		}
	}
	&_contact {
		@include mediaQ(PC) {
			background: url("/assets/img/bg_contactArea_pc.jpg") no-repeat center center / cover;
			@include pcPAD_P((65px 0 75px));
		}
		@include mediaQ(SP) {
			background: url("/assets/img/bg_contactArea_sp.jpg") no-repeat center center / cover;
			padding: 45px 0 40px;
		}
		&_ttl {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-weight: bold;
			color: $white;
			@include mediaQ(PC) {
				font-size: 38px;
				margin-bottom: per(35px, $widthContent);
			}
			@include mediaQ(SP) {
				font-size: 38px;
				margin-bottom: 25px;
			}
			&_en {
				color: #3670a4;
				font-family: 'Roboto', sans-serif;
				font-weight: 500;
				background: $white;
				border-radius: 1000px;
				@include mediaQ(PC) {
					@include pcFS(16px);
					margin-bottom: per(10px, $widthContent);
					padding: math.div(4px, 16px)+em math.div(18px, 16px)+em;
				}
				@include mediaQ(SP) {
					font-size: 13px;
					padding: 0 12px;
					margin: 0 0 7px per(4px, 335px);
				}
			}
		}
		&_links {
			&_item {
				@include mediaQ(PC) {
					position: relative;
					width: per(531px, $widthContent);
					height: 0;
					padding-bottom: per(80px, $widthContent);
				}
				@include mediaQ(SP) {
					width: 100%;
					height: 60px;
				}
				&:not(:last-of-type) {
					@include mediaQ(SP) {
						margin-bottom: 20px;
					}
				}
			}
			&_link {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				font-weight: bold;
				border-radius: 4px;
				@include mediaQ(PC) {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					@include pcFS(20px);
					transition: .3s;
				}
				@include mediaQ(SP) {
					position: relative;
					font-size: 18px;
				}
				$qa: #{&}#{&}__qa;
				$form: #{&}#{&}__form;
				&#{&}__qa {
					color: $blue;
					background: $white;
					@include mediaQ(PC){
						&:hover {
							color: $white;
							background: transparent;
							border: solid 1px $white;
						}
					}
				}
				&#{&}__form {
					color: $white;
					background: $orange;
					@include mediaQ(PC){
						&:hover {
							color: $orange;
							background: $white;
						}
					}
				}
				&::after{
					content: "";
					position: absolute;
					top: 50%;
					transform: rotate(45deg) translateY(-50%);
					@include mediaQ(PC) {
						width: 12px;
						height: 12px;
						right: per(24px, 531px);
					}
					@include mediaQ(SP) {
						width: 10px;
						height: 10px;
						right: 25px;
					}
					@at-root {
						#{$qa}::after {
							border-top: solid 2px $blue;
							border-right: solid 2px $blue;
						}
						#{$qa}:hover::after {
							border-top: solid 2px $white;
							border-right: solid 2px $white;
						}
						#{$form}::after {
							border-top: solid 2px $white;
							border-right: solid 2px $white;
						}
						#{$form}:hover::after {
							border-top: solid 2px $orange;
							border-right: solid 2px $orange;
						}
					}
				}

				&_txt{
					display: flex;
					align-items: center;
					#{$form} & {
						@include mediaQ(SP){
							padding-top: 3px;
						}
					}
					&::before {
						display: inline-block;
						height: 0;
						@include mediaQ(PC){
							transition: .3s;
						}
						@at-root {
							#{$qa} & {
								content: "？";
								color: $white;
								text-align: center;
								background: $blue;
								border-radius: 50%;
								$w: 29px;
								$h: $w;
								width: $w;
								@include mediaQ(PC){
									$pb: $w;
									$mr: 13px;
									width: clamp(0px, convVW($w, $maxWidthContent), $w);
									@include pcFS(20px);
									padding-bottom: $pb;
									padding-bottom: clamp(0px, convVW($pb, $maxWidthContent), $pb);
									margin-right: $mr;
									margin-right: clamp(0px, convVW($mr, $maxWidthContent), $mr);
								}
								@include mediaQ(SP){
									display: flex;
									justify-content: center;
									align-items: center;
									height: $h;
									margin: 0 14px 0 -9px;
								}
							}
							#{$form} & {
								content: "";
								background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220.465%22%20height%3D%2226.67%22%20viewBox%3D%220%200%2020.465%2026.67%22%3E%20%3Cpath%20id%3D%22ifn0315%22%20d%3D%22M15.126%2C259.644a1.087%2C1.087%2C0%2C0%2C0-1.09%2C1.09v24.491a1.087%2C1.087%2C0%2C0%2C0%2C1.09%2C1.09H33.411a1.087%2C1.087%2C0%2C0%2C0%2C1.09-1.09V260.734a1.087%2C1.087%2C0%2C0%2C0-1.09-1.09Zm3.7%2C5.279h1.148v1.257H18.825Zm3.111%2C0h7.775v1.257H21.936Zm-3.111%2C4.649h1.148v1.257H18.825Zm3.111%2C0h7.775v1.257H21.936Zm-3.111%2C4.649h1.148v1.257H18.825Zm3.111%2C0h7.775v1.257H21.936Zm-3.111%2C4.649h1.148v1.257H18.825Zm3.111%2C0h7.775v1.257H21.936Z%22%20transform%3D%22translate(-14.036%20-259.644)%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E') no-repeat 0 0 / contain;
								@include mediaQ(PC){
									$w: 21px;
									$pb: 27px;
									$mr: 19px;
									width: $w;
									width: clamp(0px, convVW($w, $maxWidthContent), $w);
									padding-bottom: 27px;
									padding-bottom: clamp(0px, convVW($pb, $maxWidthContent), $pb);
									margin-right: 19px;
									margin-right: clamp(0px, convVW($mr, $maxWidthContent), $mr);
								}
								@include mediaQ(SP){
									width: 16px;
									height: 21px;
									margin: 3px 13px 0 -14px;
								}
							}
							@include mediaQ(PC){
								#{$qa}:hover &{
									color: $blue;
									background: $white;
								}
								#{$form}:hover &{
									background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220.465%22%20height%3D%2226.67%22%20viewBox%3D%220%200%2020.465%2026.67%22%3E%20%3Cpath%20id%3D%22ifn0315%22%20d%3D%22M15.126%2C259.644a1.087%2C1.087%2C0%2C0%2C0-1.09%2C1.09v24.491a1.087%2C1.087%2C0%2C0%2C0%2C1.09%2C1.09H33.411a1.087%2C1.087%2C0%2C0%2C0%2C1.09-1.09V260.734a1.087%2C1.087%2C0%2C0%2C0-1.09-1.09Zm3.7%2C5.279h1.148v1.257H18.825Zm3.111%2C0h7.775v1.257H21.936Zm-3.111%2C4.649h1.148v1.257H18.825Zm3.111%2C0h7.775v1.257H21.936Zm-3.111%2C4.649h1.148v1.257H18.825Zm3.111%2C0h7.775v1.257H21.936Zm-3.111%2C4.649h1.148v1.257H18.825Zm3.111%2C0h7.775v1.257H21.936Z%22%20transform%3D%22translate(-14.036%20-259.644)%22%20fill%3D%22%23FDA10D%22%2F%3E%3C%2Fsvg%3E') no-repeat 0 0 / contain;
								}
							}
						}
					}
				}
			}
		}
	}

	&_simulation {
		display: none;
		background: #fff;
		margin: 0 auto;
		padding: 2.8% 2%;
		text-align: center;

		@include mediaQ(PC) {
			width: 41.6%;
			min-width: 700px;
			max-width: 800px;
		}

		&_inner {
			padding: 19px 4.9%;
			padding-left: 30px;
			// overflow-y: auto;
			// height: 600px;
			@include mediaQ(SP) {
				padding: 19px 4.9%;
				// overflow-y: auto;
			// height: 600px;
			}

		}

		&_date {
			background-color: $blue;
			color: $white;
			font-size: 13px;
			font-weight: 500;
			min-width: 241px;
			padding: 8px 0;
			width: 33%;
			margin: 0 auto 8px;
			text-align: center;
			letter-spacing: 0.04rem;

			span {
				font-size: 20px;
			}
			@include mediaQ(SP) {
				padding: 8px 0px;
				width: 239px;
				min-width: auto;
			}
		}
		&_txt {
			text-align: center;
			@include pcFS(30px, $widthContent, 24px);
			@include mediaQ(PC) {
                margin-bottom: per(30px, $widthContent);
            }
			@include mediaQ(SP) {
				margin-bottom: per(20px, 375px);


			}
		}

		&_item {
			@include mediaQ(PC) {
				position: relative;
				left: -4%;
				margin-bottom: per(45px, $widthContent);
			}
			@include mediaQ(SP) {
				width: 78%;
				margin-bottom: 20px;
			}
			&_dt {
				@include pcFS(18px);
				font-weight: 500;
				position: relative;
    			padding: 0 0 .4em 38px;
				counter-increment: title;
				text-align: left;
				@include mediaQ(PC) {
					margin-bottom: per(15px, $widthContent);
				}
				@include mediaQ(SP) {
					font-size: 16px;
					padding: 0 0 0.5em 2.2em;
					line-height: 1.5;
					letter-spacing: 0.02rem;
				}

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: counter(title);
					width: 28px;
					height: 28px;
					line-height: 28px;
					background-color: $blue;
					border-radius: 100%;
					color: $white;
					font-family: 'Roboto', sans-serif;
					font-weight: bold;
					display: flex;
					justify-content: center;
					@include pcFS(18px);
					text-align: center;
					@include mediaQ(SP) {
						font-size: 16px;
						width: 25px;
						height: 25px;
						line-height: 25px;
					}
				}
			}

			&_dd {
				$calender: '.bl_simulation_item_dd__calender';
				text-align: left;
				padding-left: 3em;
				position: relative;
				@include mediaQ(SP) {
					padding-left: 2.8em;
				}


				&:not(:last-child) {
					@include mediaQ(PC) {
						margin-bottom: per(55px, $widthContent);
					}
					@include mediaQ(SP) {
						margin-bottom: 21px;
					}
				}


				.ui-datepicker-trigger:first-of-type {
					position: absolute;

					@include mediaQ(PC) {
						top: 8px;
						left: 245px;
					}
					@include mediaQ(SP) {
						top: 7px;
						left: 243px;
					}
				 }
				 .ui-datepicker-trigger:last-of-type {
					 position: absolute;

					@include mediaQ(PC) {
						top: 8px;
						left: 493px;
					}
					@include mediaQ(SP) {
						top: 55px;
						left: 243px;
					}
				 }

				&_date {
					$end : '.bl_simulation_item_dd_date__end';
					width: 232px;
					margin-right: 20px;
					background-color: $white;
					@include mediaQ(SP) {
						margin-right: 0;
						margin-bottom: 10px;
					}

					&#{$end} {
						margin-right: 0;
						@include mediaQ(SP) {
							margin-bottom: 0;
						}
					}


				}



				&_inp {
					width: 104px;
				}

				&_span {
					color: #777;
					margin-left: 1em;
					@include mediaQ(SP) {
						margin-left: 0.5em;
					}
				}

				&_return, &_giga {
					cursor: pointer;
					// padding: 0.4em 0.8em;
					background: transparent url("/assets/img/icon_arrow.svg") no-repeat center right 5px/12px 12px;
					width: 229px;
					// @include mediaQ(SP) {
					// 	width: 97%;
					// }
				}

				&#{$calender} {
					display: flex;
					flex-flow: column;
					@include mediaQ(SP) {
						flex-direction: column;
					}
				}

			}
		}

		&_button {
			max-width: 172px;

			@include mediaQ(PC) {
				padding-bottom: 44px;
				padding-bottom: clamp(0px, convVW(44px, $maxWidthContent), 44px);
			}

			@include mediaQ(SP) {
				max-width: 250px;
				height: 44px;
			}

			&_calc {
				background: $orange;
				border: solid 1px $orange;
				color: $white;
				font-size: 14px;

				&:hover {
					@include mediaQ(PC) {
						color: $orange;

						&::after {
							border-color: $orange;
						}
					}
				}

				&::after {
					right: 9px;
					width: 8.6px;
					height: 8.6px;
					top: 48%;
					border-right: solid 2px $white;
					border-top: solid 2px $white;

					@include mediaQ(SP) {
						right: 14px;
						width: 6px;
						height: 6px;
					}
				}
			}
		}
	}

	&_result {
		display: none;
		background: #fff;
		margin: 0 auto;
		padding: 2.8% 2%;
		text-align: center;


		@include mediaQ(PC) {
			width: 41.6%;
			min-width: 700px;
			max-width: 800px;
		}

		&_inner {
			padding: 5.5% 0;

			@include mediaQ(PC) {
				padding: 2.9% 0;
			}

			@include mediaQ(SP) {
				padding: 26px 0;
				overflow-y: auto;
				height: 550px;
			}
		}

		&_txt {
			text-align: center;
			@include pcFS(30px, $widthContent, 24px);
			@include mediaQ(PC) {
                margin-bottom: 0;
            }
			@include mediaQ(SP) {
				margin-bottom: 16px;
				// position: relative;
    			// left: 5%;
			}
		}

		&_wrap {
			// border: 1px solid #ccc;
			margin-bottom: per(85px, $widthContent);
			@include mediaQ(PC) {
                margin-bottom: 0;
            }
			@include mediaQ(SP) {
				width: 90%;
				margin: 0 auto 30px;
			}
			&_item {
				display: flex;
				flex-wrap: wrap;
				padding: 7.9% 0 0px;
				width: 63%;
				margin: 0 auto per(32px, $widthContent);;
				// border-bottom: 1px solid #ccc;
				// @include mediaQ(PC) {
				// 	margin-bottom: per(35px, $widthContent);
				// }

				@include mediaQ(PC) {
					margin: 0 auto 15px;
					padding: 35px 0 0;
					width: 61%;
				}

				@include mediaQ(SP) {
					margin-bottom: 15px;
					padding: 0;
					width: 93%;
				}

				&_dt {
					@include pcFS(16px);
					font-weight: 500;

					// text-align: left;
					@include mediaQ(PC) {
						width: 66%;
						// height: 45px;
						padding-left: 0.5em;
						margin-bottom: 10px;
					}
					@include mediaQ(SP) {
						width: 62%;
						font-size: 14px;
						font-weight: 400;
						padding: 0 ;
						line-height: 1.5;
						letter-spacing: 0.02rem;
						margin-bottom: 9px;
					}
					&:last-of-type {
						@include mediaQ(PC) {
							margin-bottom: 15px;
						}
					}

					&.un_usePeriod_title, &.un_subtotal_title {
						width: auto;
					}
				}

				&_dd {
					@include pcFS(16px);
					font-weight: 500;
					// text-align: left;
					// padding-left: 3em;
					width: 26%;
					white-space: nowrap;
					@include mediaQ(PC) {
						text-align: right;
					}
					@include mediaQ(SP) {
						width: 38%;
						font-size: 14px;
						text-align: right;
						// padding-left: 2.8em;
					}


					// &:not(:last-child) {
					// 	@include mediaQ(PC) {
					// 		margin-bottom: per(40px, $widthContent);
					// 	}
					// 	@include mediaQ(SP) {
					// 		margin-bottom: 20px;
					// 	}
					// }

					&.un_usePeriod, &.un_subtotal {
						.hp_mb0 {
							margin-bottom: 0;
						}

						@include mediaQ(PC) {
							width: 94%;
							text-align: right;
							font-size: 16px;
							font-weight: bold;
							line-height: 2.68;
							border-top: dotted 1px #CCCCCC;
							border-bottom: solid 1px #CCCCCC;
							margin-top: 8px;
							margin-bottom: 8px;
							padding-bottom: 5px;
						}

						@include mediaQ(SP) {
							width: 100%;
							text-align: right;
							font-size: 14px;
							font-weight: bold;
							line-height: 2.8;
							border-top: dotted 1px #CCCCCC;
							border-bottom: solid 1px #CCCCCC;
							margin-bottom: 19px;
						}
					}
				}
			}

			&_resultTitle {
				@include mediaQ(PC) {
					text-align: right;
					font-size: 16px;
					font-weight: bold;
					line-height: 2.68;
					width: 57.5%;
					margin: 0 auto;
					border-bottom: solid 2px #222222;
					margin-bottom: 15px;
					margin-left: 19%;
				}

				@include mediaQ(SP) {
					text-align: right;
					font-size: 14px;
					font-weight: bold;
					line-height: 2.8;
					width: 95%;
					margin: 0 auto;
					border-bottom: solid 1px #ccc;
					margin-bottom: 10px;
				}
			}

			&_sum {
				font-weight: bold;
				display: flex;
				width: 64%;
				margin: auto;
				@include mediaQ(PC) {
					@include pcFS(20px);
					margin-bottom: 10px;
				}
				@include mediaQ(SP) {
					font-size: 16px;
					width: 94%;
					margin-bottom: 28px;
				}
				&_dt {

					padding-left: 0.5em;
					width: 66%;
					@include mediaQ(PC) {

						margin-bottom: per(25px, $widthContent);
					}
					@include mediaQ(SP) {
						width: 56%;
						padding: 0;
						line-height: 1.5;
						letter-spacing: 0.02rem;
					}

				}

				&_dd {
					// text-align: left;
					// padding-left: 3em;
					width: 26%;
					text-align: right;
					@include mediaQ(SP) {
						width: 44%;
					}

				}
			}
		}


		&_error {
			@include mediaQ(PC) {
				margin-top: 15px;
				margin-bottom: 15px;
			}

			@include mediaQ(SP) {
				width: 85%;
				margin: 15px auto;
			}

			&_text {
				@include mediaQ(PC) {
					font-size: 14px;
					line-height: 1.6;
					color: #FF6565;
					text-align: center;
				}

				@include mediaQ(SP) {
					font-size: 14px;
					line-height: 1.6;
					color: #FF6565;
				}
			}
		}


		&_button {

			max-width: 187px;
			padding-bottom: 40px;
			padding-bottom: clamp(0px, convVW(50px, $maxWidthContent), 40px);
			@include mediaQ(SP) {
				max-width: 250px;
				height: 44px;
				width: 100%;
				margin-bottom: 20px;
			}

			&_wrap {
				display: flex;
				width: 47%;
    			margin: auto;
				@include mediaQ(SP) {
					flex-direction: column;
					width: 100%;
				}
			}

			&_back {
				max-width: 119px;
				padding-bottom: 40px;
				padding-bottom: clamp(0px, convVW(50px, $maxWidthContent), 40px);
				@include mediaQ(SP) {
					order: 1;
					max-width: 250px;
					height: 44px;
					width: 100%;
				}

				&_link {
					background: #9D9D9D;
					border: solid 1px #9D9D9D;
					color: $white;
					font-size: 14px;
					padding-top: 2px;

					&:hover {
						@include mediaQ(PC) {
							color: #9D9D9D;

							&::after {
								border-color: #9D9D9D;
							}
						}
					}

					&::after {
						border-right: solid 2px $white;
						border-top: solid 2px $white;
						width: 8px;
						height: 8px;
						right: 12px;
						transform: translateY(calc(-50%)) rotate(45deg);
					}
				}

			}

			&_error {
				@include mediaQ(PC) {
					margin-top: 15px;
					max-width: 190px;
					padding-bottom: 40px;
					padding-bottom: clamp(0px, 4.3859649123vw, 40px);
				}

				@include mediaQ(SP) {
					margin-top: 17px;
					max-width: 88%;
					padding-bottom: 40px;
					height: auto;
				}

				&_link {
					background-color: #00B160;
					border: solid 1px #00B160;
					font-size: 14px;

					&:hover {
						background-color: #ffffff;
						color: #00B160;
					}

					&:before {
						content: "";
						width: 21px;
						height: 21px;
						background: url("/assets/img/icon_cart_green_01.svg") no-repeat center center;
						background-size: 21px 21px;
						margin-right: 6px;
					}

					&:after {
						@include mediaQ(PC) {
							width: 6px;
							height: 6px;
							top: 48%;
						}

						@include mediaQ(SP) {
							width: 8px;
							height: 8px;
						}
					}

					&:hover {
						&::before {
							content: "";
							width: 21px;
							height: 21px;
							background: url("/assets/img/icon_cart_green_02.svg") no-repeat center center;
							background-size: 21px 21px;
							margin-right: 6px;
						}
						&::after {
							border-color: #00B160;
						}
					}
				}
			}

			&_calc {
				background: $orange;
				border: solid 1px $orange;
				color: $white;
				font-size: 14px;
				padding-top: 2px;
				@include mediaQ(SP) {
					order: 0;
					max-width: 250px;
					height: 44px;
				}

				&:hover {
					@include mediaQ(PC) {
						color: $orange;

						&::after {
							border-color: $orange;
						}
					}
				}

				&::after {
					border-right: solid 2px $white;
					border-top: solid 2px $white;
					width: 8px;
					height: 8px;
					right: 12px;
					transform: translateY(calc(-50%)) rotate(45deg);
				}
			}
		}
	}

	&_search {
		background-color: $_bg_blue;
		&_container {
			width: 620px;
			margin: auto;
			padding: 40px 0;
			display: flex;
			justify-content: center;
			@include mediaQ(SP) {
				width: per(335px, 375px);
				padding: 30px 0;
			}
			&_input {
				border: 1px solid #ccc;
				border-radius: 4px;
				// max-width: 550px;
				width: 550px;
				height: 40px;
				padding: 3px 24px;
				// color: #ccc;
				font-size: 16px;
				@include mediaQ(PC) {
					width: 550px;
				}
				@include mediaQ(SP) {
					width: per(265px, 335px);
					font-size: 12px;
					padding: 3px 9px;
				}
				&:focus {
					outline:0;
				}
				&::placeholder {
					color: #ccc;
				}
			}

			&_btn {
				width: 60px;
				height: 40px;
				background-color: $hv_blue;
				cursor: pointer;
				border: none;
				border-radius: 4px;
				outline : none;
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				img {
					margin: auto;
				}
			}
		}
	}
}

/*=============================================
=            pager            	          =
=============================================*/
.md_pager {
    margin: 46px auto 50px;
	@include mediaQ(SP) {
		margin: 46px auto 39px;
	}

    ul {
        align-items: center;
        display: flex;
        // flex-wrap: wrap;
        justify-content: center;
		max-width: 340px;
		margin: auto;

        li {
            width: 31px;
            height: 29px;
            margin: 0 10px;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}

            &.prev, &.next {
                width: 33px;
                a::after {
                    background-image: url(/assets/img/icon_prev.svg);
                    background-repeat: no-repeat;
					background-color:#B4B4B4;
					background-position: center center;
                    content: "";
                    display: block;
                    height: 29px;
                    width: 33px;
                    margin: auto;
                    opacity: 1;
                }
            }

            &.prev a::after{
                position: absolute;
                left: 0px;
                bottom: 0px;
                top: 0;
            }

            &.next {
                // width: 33px;
                a::after {
                    transform: rotate(-180deg);
                    -webkit-transform: rotate(-180deg);
                    position: absolute;
                    bottom: 0;
                    right: 0px;
                    top: 0;
                }
            }

			.page-numbers {
				position: relative;
				top: 13px;
			}
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                // border-radius: 50%;
                font-size: 16px;
                font-weight: bold;
                height: 100%;
                padding-top: 2px;
                position: relative;
                transition: 0.3s;
                width: 100%;

                &:hover {
                    // background-color: $hv_blue;
                    // border: solid 1px $hv_blue;
                    // color: $white;
                    opacity: .7;
                }
                &.is_active {
                    background-color: $blue;
                    border: solid 1px $blue;
                    color: $white;
                    opacity: 1;
                }
            }

            &.prev a, &.next a {
                border: none;
                border-radius: 0;

                &:hover {
                    background: none; border: none;
                }
            }

            &.prev a.is_active, &.next a.is_active {
                background: none; border: none;
            }
        }
    }

}

.md_pager2 {
	@include mediaQ(PC) {
		display: flex;
		justify-content: space-between;

		&.md_pager2__news {
			width: 70%;
			margin: auto;
		}
	}

	&_link {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include mediaQ(PC) {
			width: 50%;
		}

		&:first-child {
			@include mediaQ(PC) {
				padding: 10px 20px 10px 0;
				padding-right: 20px;
				border-right: 1px solid #ccc;
			}
			@include mediaQ(SP) {
				padding-bottom: 25px;
				border-bottom: 1px solid #ccc;
			}

		}
		&:nth-child(2) {
			flex-direction: row-reverse;
			@include mediaQ(PC) {
				padding: 10px 0 10px 20px;
			}
			@include mediaQ(SP) {
				padding-top: 25px;
			}

		}

		&_arrow {
			width: 4%;
			img {
				width: 100%;
			}

			&__reverse {
				transform: scale(-1, 1);
			}
		}

		&_thumb {
			width: 37.2%;
			height: 92px;

			@include mediaQ(SP) {
				width: 40.2%;
				max-width: 140px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border: 1px solid #E8E8E8;
			}
		}

		&_ttl {
			width: 49.4%;
			font-size: 14px;
			font-weight: 500;
			line-height: 1.7;
			@include mediaQ(SP) {
				width: 47%;
			}

			&#{&}__news {
				width: 86.6%;

			}
		}
	}

}


//modaal
body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
.modaal-wrapper .modaal-close {

	right: 27%;
	position: absolute;
    right: -9px;
    top: -53px;
	@include mediaQ(SP) {
		height: 22px;
		top: -43px;
		width: 22px;
		right: 11px;
	}

	&::before, &::after  {
		height: 33px;
		// top: -8px;
		width: 2px;
		@include mediaQ(SP) {
			height: 25px;
		}
	}

}
.modaal-close:focus, .modaal-close:hover {
	background: transparent;
	opacity: .6;
}
.modaal-wrapper .modaal-close:hover:before, .modaal-wrapper .modaal-close:hover:after {
	background: #fff;
}
.modaal-outer-wrapper {
	background: rgba(0, 0, 0,0.7);;
}

.modaal-inner-wrapper {
	@include mediaQ(SP) {
		padding: 106px 20px;
	}
}

.modaal-container {
	max-width: 800px;
	padding: 20px 36px;
	@include mediaQ(SP) {
		max-width: 700px;
		padding: 0;
	}


}

.modaal-content-container {
	padding: 0;
}


@include mediaQ(SP) {

	/* =========================================================
	◆Basic
	========================================================= */
	/* spBlock,spInline */
	.spBlock { display: block !important;}
	.spInline { display: inline-block !important;}
	.pcBlock, .pcInline { display: none !important;}

}


.js_rotate_top {
    transform: rotate(-45deg);
}

.js_rotate_bottom {
    transform: rotate(45deg);
}

.js_show {
    opacity: 1;
    pointer-events: auto;
}
.js_hide {
    opacity: 0!important;
    pointer-events: none;
}
.js_show_nav {
    opacity: 1;
    pointer-events: auto;
}
.js_show_menubg {
    opacity: 0.8;
    height: 100%;
    pointer-events: auto;
}

.js_form_error {
	outline:solid 2px red;
}

.js_date_error_message,.js_qt_error_message{
	margin-top: 15px;
	color: red;
}
